import React from 'react';
import { Outlet } from 'react-router';
import BackgroundWithOverlay from 'components/common/BackgroundWithOverlay';
import { BASE_STATIC_URL } from 'utils/constants';


export default function CommonLayout() {
    return (
        <div
            className='after:-bottom-32 sm:after:-bottom-0 flex after:absolute after:inset-0 justify-center items-center h-screen bg-center bg-no-repeat bg-cover after:bg-gray-950/80 after:bg-gradient-radial after:from-indigo-900/80 after:via-gray-950/80'
            style={{
                backgroundImage: `url(${BASE_STATIC_URL + '/assets/siteui/NRB_Corco-Titles_BLKWHT.png'})`

            }}
        >

            <div
                className="relative z-10"
            >

                <Outlet />
            </div>
        </div>

    );
}

import React from 'react';
import cn from 'classnames';

const Input = ({
    ariaLabel,
    name,
    type,
    placeholder,
    disabled,
    field,
    className,
}) => (
    <input
        {...field}
        aria-label={ariaLabel}
        name={name}
        type={type}
        placeholder={placeholder}
        disabled={disabled}
        className={cn(
            `pl-4 mb-2 h-12 block w-full rounded-lg bg-gray-100 text-gray-500 border-gray-400 focus:border-indigo-700 focus:text-gray-800 focus:ring-0 placeholder:text-sm placeholder:text-gray-400`,
            className
        )}
    />
);

export default Input;

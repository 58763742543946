import React, { Fragment, useContext, useEffect, useState } from 'react';
import { AuthContext } from 'context/AuthContext';
import { useNavigate } from 'react-router-dom';
import DetailMeta from './DetailMeta';
import { Spinner } from './common';
import PublicVideoPlayer from './PublicVideoPlayer';
import { ButtonRound } from './common/Button';
// import { FetchContext } from "context/FetchContext";
// import parseUrl from 'url-parse';

export default function DetailBlock({
  children,
  data,
  isFetching,
  isError,
  error,
  playUrl,
  setShowVideo,
  page,
}) {
  const authContext = useContext(AuthContext);
  const { user_info } = authContext.authState;
  const user = user_info ? JSON.parse(user_info) : {};
  const navigate = useNavigate();

  if (isFetching)
    return (
      <div className="flex justify-center items-center w-full h-screen ">
        <Spinner size={8} />
      </div>
    );
  if (isError)
    return (
      <div className="flex justify-center items-center w-full h-screen text-gray-500 ">
        <p>Error: {error.message}</p>
      </div>
    );

  return (
    <div className="flex relative mx-auto -mt-20 mb-0 w-full">
      <div className="flex relative after:absolute after:inset-0 z-10 after:-z-10 flex-col justify-center pt-20 w-[90%] max-w-[874px] after:bg-gradient-to-r after:from-gray-950 after:via-gray-950/95 md:min-w-[500px]">
        <div className="Logo" />
        <div className="Details">
          <h1 className="mb-4 text-5xl font-bold tracking-tight text-gray-200">
            {data.title}
          </h1>
          <div className="my-4">
            <DetailMeta
              data={data}
              isFetching={isFetching}
              isError={isError}
              error={error}
              page={page}
            />
          </div>
          <div className="flex flex-col flex-wrap justify-evenly items-start my-[5px] max-w-full">
            <p className="max-w-full text-xl leading-relaxed font-normal text-gray-300">
              {data.description}
            </p>

            {page === 'movie' || page === 'show' ? (
              <div className="pt-4">
                <div className="">
                  {data.production_team.lead_roles.count >
                    0 ? (
                    <span className="text-gray-500">
                      Starring:{' '}
                    </span>
                  ) : null}

                  <span className="text-gray-400">
                    {data.production_team.lead_roles.count > 0
                      ? data.production_team.lead_roles.results.map(
                        (role, index) => (
                          <Fragment
                            key={role.pk}
                          >
                            {role.title}
                            {index !== data.production_team.lead_roles.count - 1 && ', '}
                          </Fragment>
                        )
                      )
                      : null}

                    {data.production_team.guest_roles.count > 0 && data.production_team.lead_roles.count > 0 ? ', ' : ''}
                    {data.production_team.guest_roles.count > 0
                      ? data.production_team.guest_roles.results.map(
                        (role, index) => (
                          <Fragment
                            key={role.pk}
                          >
                            {role.title}
                            {index !== data.production_team.guest_roles.count - 1 && ', '}
                          </Fragment>
                        )
                      )
                      : null}
                  </span>
                </div>
                <div className="">
                  {data.production_team.directors ? (
                    <span className="text-gray-500">
                      Director:{' '}
                      <span className="text-gray-400">
                        {data.production_team.directors}
                      </span>{' '}
                    </span>
                  ) : null}
                </div>
              </div>
            ) : null}

            {page === 'trailer' && playUrl ? (
              <div className=" mt-6 ButtonBlock">
                <ButtonRound
                  onClick={() => setShowVideo(true)}
                  className="self-start space-x-2"
                  size="rnd"
                  text="Trailer"
                  varient="plain"
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <div className="flex z-0 flex-1 pb-[33%] h-0">
        <div
          className="absolute after:absolute right-[-3vw] after:left-0 w-full after:w-full h-full after:h-full bg-origin-padding bg-left-top bg-no-repeat bg-cover after:bg-gradient-to-t after:from-gray-950 after:via-gray-950/0"
          style={{
            right: `${page !== 'trailer' ? '-3vw' : '-5vw'}`,
            backgroundImage: `url(${data.images.feature})`,
            backgroundOrigin: 'padding-box',
          }}
        />
      </div>
    </div>
  );
}

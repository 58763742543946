import React, { useEffect, useState, useRef, Fragment } from 'react';
import ReactPlayer from 'react-player/file';
import { Dialog, Transition } from '@headlessui/react';
import { Icon } from './icons/icon.export';

export default function PublicVideoPlayer({
  playUrl,
  onDismiss,
  onStart,
  className,
  title,
  ...props
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [url, setUrl] = useState(null);
  const playerRef = useRef(null);

  useEffect(() => {
    if (onStart && playUrl) {
      setUrl(playUrl);
      setIsOpen(onStart);
    }
  }, [onStart, playUrl]);

  // On Hide - Closes the player and gets fires off the onDismiss() functions passed from the parent.
  const closeModal = () => {
    onDismiss();
    setUrl(null);
    setIsOpen(false);
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        onClose={closeModal}
        className="overflow-y-auto fixed inset-0 z-10"
      >
        <div className="px-4 min-h-screen text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black/80" />
          </Transition.Child>
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block overflow-hidden my-8 w-full max-w-2xl text-right align-middle rounded shadow-xl transition-all aspect-video">
              <button className="" onClick={closeModal}>
                <Icon
                  name="close"
                  className="text-gray-200"
                  width={1}
                  size={32}
                />
              </button>
              <ReactPlayer
                ref={playerRef}
                url={url}
                width="100%"
                height="100%"
                controls
                playing
                onEnded={() => {
                  setIsOpen(false);
                  setUrl(null);
                  onDismiss();
                }}
                config={{
                  forceHLS: true,
                }}
              />
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}

import React, { useContext } from 'react';
import { Icon } from './icons/icon.export';
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';
import * as Popover from '@radix-ui/react-popover';
import { useSaveWatchlist, useDeleteWatchlist } from 'api/mutations/watchlist';
import { PlayerContext } from 'context/PlayerContext';

interface EpisodeProps {
    pk: number;
    image: string;
    episodeNumber: string;
    title: string;
    captions: boolean;
    show: string;
    videoSrc: string,
    length: string;
    progress: number;
    detailLink: string;
    contentType: string;
    saveActivity: number | null;
    description: string;
    associatedMeta: string;
    associatedTitle: string;
    associatedShowLink: string;
    associatedSeasonLink: string;
    associatedEpisodeLink: string;
}

// TODO: Add Icon component into the Popover.
export default function Episode({
  pk,
  image,
  episodeNumber,
  title,
  show,
  videoSrc,
  captions,
  description,
  length,
  progress,
  detailLink,
  contentType,
  saveActivity,
  associatedTitle,
  associatedMeta,
  associatedShowLink,
  associatedSeasonLink,
  associatedEpisodeLink,
  ...props
}: EpisodeProps) {

  const navigate = useNavigate();
  function parseUrl(url: string) {
      return new URL(url).pathname.split('/v2/content').pop() || '/#'
  };

  const { mutate: mutateSave } = useSaveWatchlist(pk);
  const { mutate: mutateDelete } = useDeleteWatchlist(pk);

  function addRemoveWatchlist(url: any): void {
    if(saveActivity === null) {
      mutateSave(url);
    } else {
      mutateDelete(url);
    }
  }

  const playerContext = useContext(PlayerContext);

  function handleEpisodeClick() {
    playerContext.setTitle(title);
    playerContext.setVideoSrc(videoSrc);
    //TODO set the full metadata on video
  }


  return (
      <div className="flex py-2 px-2 rounded-lg hover:bg-gray-900 mb-1 cursor-pointer">
        <div className="relative flex flex-col mr-2 rounded-md w-[160px] h-[90px] md:w-[240px] md:h-[135px]  shrink-0  overflow-clip">
          <img
            src={image}
            alt={title}
            className="object-cover object-center w-full h-full"
          />
          <div
            tabIndex={0}
            role="button"
            onClick={handleEpisodeClick}
            onKeyDown={(e) =>  {
              if(e.key === 'Enter') {
                handleEpisodeClick();
              }
            }}
            className="flex group absolute inset-0 justify-center items-center md:hover:bg-gray-950 md:hover:bg-opacity-50 cursor-pointer">
              <Icon
                name="play"
                weight="fill"
                alt="play"
                className="absolute z-10 md:w-[32px] md:h-[32px] rounded-full text-white/80 md:hover:text-white md:hover:shadow-white/80 md:opacity-0 md:group-hover:opacity-100 md:transition-transform md:duration-200 md:ease-in-out md:hover:scale-150"
              />
              {captions && (
                <div className="absolute bottom-1 left-1 text-gray-200 outline outline-1 outline-white/10 text-xs p-1 bg-black/70 rounded-md">CC</div>
              )}
              <div className="absolute bottom-1 right-1 text-gray-200 outline outline-1 outline-white/10 text-xs p-1 bg-black/70 rounded-md">{length}</div>
          </div>

          <div className="absolute inset-x-0 bottom-0 h-1 bg-indigo-700"
            style={{
              width: `calc(${progress}% * 100)`,
            }}
          />
        </div>
        <div className="flex flex-col  sm:px-8 w-full max-w-[75ch] text-gray-200">
          <div className='font-semibold text-sm md:text-base mb-2'>
            <h3 className='flex' >
                {contentType === 'topic' ? title : episodeNumber + '.' + ' ' + title }
            </h3>
            {contentType === 'topic' &&
              <p className="mt-auto text-gray-600 text-sm">
                {show + ' ' + episodeNumber.toUpperCase()}
              </p>
            }
          </div>
          <p className="mb-4 hidden md:block text-gray-400 md:line-clamp-4">
            {description}
          </p>

        </div>
        <Popover.Root>
          <Popover.Trigger className='flex ml-auto justify-center items-center w-[40px] h-[40px] p-2 text-gray-400 hover:text-white hover:bg-gray-800 rounded-full'>
            <Icon name="dotsThreeVertical" weight="bold" size={24}/>
          </Popover.Trigger>
          <Popover.Portal>
            <Popover.Content className='rounded-md py-2  text-sm bg-gray-800'>
              <div className="flex flex-col text-gray-300 ">
                {contentType === 'topic' &&
                <>
                  <Link
                    to={parseUrl(associatedShowLink)}
                    className="px-4 py-2 hover:bg-gray-600 hover:text-gray-100">
                      <div className="flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mr-2">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 9l-3 3m0 0l3 3m-3-3h7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                        Go to show
                      </div>
                  </Link>
                  <Link
                  to={parseUrl(associatedEpisodeLink)}
                  className="px-4 py-2 hover:bg-gray-600 hover:text-gray-100">
                      <div className="flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mr-2">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 9l-3 3m0 0l3 3m-3-3h7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                        Go to {associatedMeta}
                      </div>
                  </Link>
                </>
              }
                <button
                onClick={() => addRemoveWatchlist(detailLink)}
                className="px-4 py-2 hover:bg-gray-600 hover:text-gray-100">
                  <div className="flex items-center" >
                  { saveActivity ?
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mr-2">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                  :
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mr-2">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                  }
                    {saveActivity ? 'Remove from Watchlist' : 'Add to Watchlist'}
                  </div>
                </button>
              </div>
              <Popover.Arrow className="fill-gray-800"/>
            </Popover.Content>
          </Popover.Portal>
        </Popover.Root>
      </div>
  )
}




import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { PAGE_SIZE_ROW, STALE_TIME } from 'utils/constants';
import { FetchContext } from '../context/FetchContext';

export function useSaveActivity(pk) {
    const fetchContext = React.useContext(FetchContext);
    return useQuery(
        ['watchlist', pk],
        ({ pageParam = 1, ordering = 'position', pageSize = PAGE_SIZE_ROW }) =>
            fetchContext
                .authAxios(
                    `/users/${pk}/activity/saveactivity/?page=${pageParam}&page_size=${pageSize}&ordering=${ordering}`
                )
                .then((res) => res.data),
            {
                keepPreviousData: true,
                staleTime: STALE_TIME
            }
    );
}
// TODO: Move to Make infinite Query
// Watchlist Row and Watchlist Page

// export function useSaveActivity(pk) {
//   const fetchContext = React.useContext(FetchContext);
//   return useInfiniteQuery(
//     "Watchlist",
//     ({ pageParam = 1, ordering = "save_date", pageSize = PAGE_SIZE_ROW }) =>
//       fetchContext.authAxios(
//         `/users/${pk}/activity/saveactivity/?page=${pageParam}&page_size=${pageSize}&ordering=${ordering}`
//       ),
//     {
//       getPreviousPageParam: (firstPage, page) => {
//         if (firstPage.data._previous === null) return undefined;
//         const { query } = parseUrl(firstPage.data._previous, true);
//         return query.page;
//       },
//       getNextPageParam: (lastPage, page) => {
//         if (lastPage.data._next === null) return undefined;
//         const { query } = parseUrl(lastPage.data._next, true);
//         return query.page;
//       },
//       keepPreviousData: true,
//     }
//   );
// }

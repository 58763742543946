import React from 'react';
import { PropTypes } from 'prop-types';
import { useSwipeable } from 'react-swipeable';
import { NUM_SLIDES } from 'utils/constants';
import { Icon } from './icons/icon.export';

export const CarouselItem = ({
  children,
  width,
  index,
  data,
  growOnHover,
  slideNumber,
}) => (
  <div
    data-index={index}
    className={`carousel-item inline-flex items-center justify-center min-h-[1px] text-white ${growOnHover === true
        ? 'transition duration-300 hover:scale-105'
        : ' '
      } p-2`}
    style={{ width: `calc(100% / ${slideNumber})` }}
  >
    {children}
  </div>
);

export const Carousel = ({
  children,
  slideNumber,
  growOnHover,
  pauseOnHover,
  count,
}) => {
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [paused, setPaused] = React.useState(false);
  const [width, setWidth] = React.useState(0);
  const carousel = React.useRef();

  const numItems = React.Children.count(children);
  const activeItems = numItems / slideNumber;
  const { scrollWidth } = carousel;
  const { offsetWidth } = carousel;
  const percentage = scrollWidth / offsetWidth;
  // const scrollAmount = carousel.current.offsetWidth / carousel.current.scrollWidth;

  const updateIndex = (i) => {
    let newIndex = i;
    if (newIndex < 0) {
      newIndex = 0;
    } else if (newIndex >= numItems / slideNumber) {
      newIndex = numItems / slideNumber - 1;
      // newIndex = percentage;
    }
    setActiveIndex(newIndex);
  };

  React.useEffect(() => {
    setWidth(carousel.current.offsetWidth);
  });

  // TODO: Get swipable handlers working properly
  const handlers = useSwipeable({
    onSwipedLeft: () => updateIndex(activeIndex + 1),
    onSwipedRight: () => updateIndex(activeIndex - 1),
    preventScrollOnSwipe: true,
    trackMouse: true,
  });

  return (
    <div
      {...handlers}
      className="overflow-hidden relative mx-[3vw] carousel"
      ref={carousel}
    >
      <div
        className="whitespace-nowrap transition-transform duration-300 inner-carousel"
        // style={{ transform: `translateX(-${activeIndex * 100}%)` }}
        style={{ transform: `translateX(-${width * activeIndex}px)` }}
      >
        {React.Children.map(children, (child, index) =>
          React.cloneElement(child)
        )}
      </div>

      {numItems > slideNumber ? (
        <>
          <button
            type="button"
            className={`
            ${activeIndex === 0 ? 'hidden' : 'inline'}
            absolute top-0 bottom-0 left-0 m-auto z-10 w-8 h-8 rounded-full bg-white/70 outline outline-1 outline-white flex items-center justify-center hover:scale-105 transition-transform duration-100 ease-out`}
            onClick={() => {
              updateIndex(activeIndex - 1);
            }}
          >
            <Icon
              name='chevronLeft'
              size={28}
              className="-ml-0.5 text-indigo-700"
            />
          </button>
          <button
            type="button"
            className={`${activeIndex === numItems / slideNumber - 1
                ? 'hidden'
                : 'inline'
              } absolute top-0 bottom-0 right-0 m-auto z-10 w-8 h-8 rounded-full bg-white/70 outline outline-1 outline-white flex items-center justify-center hover:scale-105 transition-transform duration-100 ease-out`}
            onClick={() => {
              updateIndex(activeIndex + 1);
              if (activeIndex + 1 > numItems / slideNumber - 1) {
                updateIndex(numItems / slideNumber - 1);
              }
            }}
          >
            <Icon
              name='chevronRight'
              size={28}
              className="ml-0.5 text-indigo-700"
            />
          </button>
        </>
      ) : null}
    </div>
  );
};

CarouselItem.propTypes = {
  children: PropTypes.object,
  width: PropTypes.string,
  index: PropTypes.number,
  data: PropTypes.array,
  growOnHover: PropTypes.bool,
  slideNumber: PropTypes.number,
};

Carousel.propTypes = {
  children: PropTypes.any,
  slideNumber: PropTypes.number,
  growOnHover: PropTypes.bool,
  pauseOnHover: PropTypes.bool,
  count: PropTypes.number,
};

import { createContext, useEffect, useState } from 'react';

export const PlayerContext = createContext({} as any)


export const PlayerProvider = ({ children }: any) => {
	const [videoSrc, setVideoSrc] = useState<string>('');
	const [isLive, setIsLive] = useState<boolean>(false);
	const [title, setTitle] = useState<string>('');

	function handleVideoSrc(src: string) {
		alert(videoSrc)
	};

	function destroyPlayer() {
		setVideoSrc('')
		setIsLive(false)
		setTitle('')
	}


	return (
		<PlayerContext.Provider
			value={{
				title,
				setTitle,
				isLive,
				setIsLive,
				videoSrc,
				setVideoSrc,
				handleVideoSrc,
				destroyPlayer
			}}>
			{children}
		</PlayerContext.Provider>
	)
};

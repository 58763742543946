import React from 'react';
import { useInfiniteQuery } from '@tanstack/react-query';
import parseUrl from 'url-parse';
import { PAGE_SIZE, STALE_TIME } from 'utils/constants';
import { FetchContext } from 'context/FetchContext';

export function useCollections(options = null, is_kids = null) {
    const fetchContext = React.useContext(FetchContext);
    return useInfiniteQuery(
        ['collections'],
        ({
            pageParam = 1,
            isKids = false,
            ordering = 'position',
            pageSize = PAGE_SIZE,
        }) =>
            fetchContext.authAxios(
                `/content/collections/?page=${pageParam}&page_size=${pageSize}&ordering=${ordering}&is_kids=${isKids}&is_featured=false&is_nested=false`
            ),
        {
            getPreviousPageParam: (firstPage, page) => {
                if (firstPage.data._previous === null) return undefined;
                const { query } = parseUrl(firstPage.data._previous, true);
                return query.page;
            },
            getNextPageParam: (lastPage, page) => {
                if (lastPage.data._next === null) return undefined;
                const { query } = parseUrl(lastPage.data._next, true);
                return query.page;
            },
            keepPreviousData: true,
            staleTime: STALE_TIME,
        }
    );
}

type ProgressType = {
    progress?: number;
}

export default function Progress({ progress }:ProgressType) {

    return progress !== undefined ? (
        <div className="mt-2  w-full h-1 bg-opacity-10 bg-indigo-50 rounded-full">
            <div className={`h-full rounded-full bg-indigo-700`} style={{ width: `calc(${progress}% * 100)` }} />
        </div>
    ) : null;
}

import React, { useState, useEffect, useContext } from "react";

import { Form, Formik } from "formik";
import * as Yup from "yup";

import { useTranslation } from "react-i18next";
import { Spinner } from "components/common";

import { FetchContext } from "context/FetchContext";
import { AuthContext } from "context/AuthContext";
import { useQuery } from "@tanstack/react-query";

import { Icon } from "components/icons/icon.export";
import Label from "components/common/Label";
import { SecureTextInput, SelectInput, CheckInput } from "components/TextInput";
import { ButtonRound } from "components/common/Button";
import FormError from "components/FormError";
import FormSuccess from "components/FormSuccess";
import qs from "qs";
import { APP_NAME } from "utils/constants";
import { CommonModal } from "components/Modal";
import { useNavigate } from 'react-router-dom';
import { useDocumentTitle } from '@uidotdev/usehooks'

function Account() {
  const [submitLoading, setSubmitLoading] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [submitError, setSubmitError] = useState(false);

  const authContext = useContext(AuthContext);
  const fetchContext = useContext(FetchContext);
  const { user_info } = authContext.authState;
  const user = user_info ? JSON.parse(user_info) : {};

  const { t, i18n } = useTranslation();

  function useAccountByPk(pk) {
    const fetchContext = useContext(FetchContext);
    return useQuery(["account", pk], () =>
      fetchContext.authAxios(`/users/${pk}/`).then((res) => res.data)
    );
  }

  const { data, isLoading, isError, error } = useAccountByPk(user.pk); // get account data with Query
  const account = data;

  const NewPasswordSchema = Yup.object().shape({
    old_password: Yup.string().required("Current password is required"),
    new_password: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .required("New password is required"),
  });
  useDocumentTitle(`Account | ${APP_NAME}`);

  const submitNewPassword = async (credentials) => {
    try {
      setSubmitLoading(true);
      const pwData = await fetchContext.authAxios.put(
        `users/${user.pk}/password/`,
        qs.stringify(credentials)
      );

      if (pwData.status === 200) {
        setSubmitSuccess("New password has been saved");
      }
      setSubmitError(false);
      setSubmitLoading(false);
    } catch (error) {
      setSubmitLoading(false);
      const { status } = error.response;
      if (status === 400) {
        setSubmitError("There was an issue with one of the fields");
      }
      setSubmitSuccess(false);
    }
  };
  // 'PUT' -> /users/<pk>/password/
  // -> success: new passord has been saved
  const [revealDetail, setRevealDetail] = React.useState(false);


  if (isLoading)
    return (
      <div className="w-full h-screen flex items-center justify-center ">
        <Spinner size={8} />
      </div>
    );

  if (isError)
    return (
      <div className="w-full h-screen text-gray-500 flex items-center justify-center ">
        <p>Error: {error.message}</p>
      </div>
    );

  return (
    <div className="_account overflow-y-scroll px-[3vw] pt-20 h-full">
      <div className="_account-header text-4xl font-bold text-gray-100 mb-5">
        <h1>{t("setting-account.account")}</h1>
      </div>

      <div className="_account-content text-gray-300 w-full max-w-sm space-y-6 ">
        <div className="">
          <div className="_item-account flex mt-4 text-lg font-semibold border-b border-gray-500 border-opacity-25 mb-4">
            <span className="mb-1">Personal Information</span>
          </div>
          <div className="_item-account flex mt-4">
            <span>{t("setting-account.name")}</span>
            <p className="_user-name ml-16">
              {account && account.first_name} {account && account.last_name}
            </p>
          </div>
          <div className="_item-account flex mt-4">
            <span>{t("setting-account.email")}</span>
            <p className="_user-name ml-16">{account && account.email}</p>
          </div>
        </div>

        <div className="flex flex-col ">
          <div className="_item-account flex mt-4 text-lg font-semibold border-b border-gray-500 border-opacity-25 mb-4">
            <span className="mb-1">Change password</span>
          </div>
          <Formik
            initialValues={{
              old_password: "",
              new_password: "",
            }}
            onSubmit={(values) => submitNewPassword(values)}
            validationSchema={NewPasswordSchema}
          >
            {() => (
              <Form>
                {submitSuccess && <FormSuccess text={submitSuccess} />}
                {submitError && <FormError text={submitError} />}
                <div className="">
                  <div className="">
                    <div className="">
                      <Label className="" text="Current Password" />
                      <SecureTextInput
                        ariaLabel="Current Password"
                        name="old_password"
                        type="password"
                        autocomplete="current-password"
                        placeholder="Enter your current password"
                      />
                    </div>

                    <div className="">
                      <div className="flex items-center">
                        <Label className="" text="New Password" />
                        <div
                          className="ml-1 text-gray-500 cursor-pointer rounded-full h-4 w-4 flex items-center justify-center text-xs font-bold"
                          onClick={() => setRevealDetail(!revealDetail)}
                        >
                          <Icon name="info" size={12} title="Password Requirements" />{" "}
                        </div>
                      </div>

                      <SecureTextInput
                        ariaLabel="New Password"
                        name="new_password"
                        type="password"
                        autocomplete="new-password"
                        placeholder="Enter your new password"
                        details={revealDetail}
                      />
                    </div>
                  </div>

                  <div className="mt-6">
                    <ButtonRound
                      type="submit"
                      className="self-start space-x-2 w-full"
                      size="rnd"
                      text="Update password"
                      loading={submitLoading}
                    />
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        <DeleteAccount account={account} />
      </div>
    </div>
  );
}

export default Account;



const DeleteAccount = ({ account }) => {
  const [toggleModal, setToggleModal] = React.useState(false);
  const authContext = useContext(AuthContext);
  const fetchContext = useContext(FetchContext);
  const { user_info } = authContext.authState;
  const user = user_info ? JSON.parse(user_info) : {};

  const handleConfirmDelete = () => {
    fetchContext.authAxios.delete(
      `users/${user.pk}`
    ).then(() => {
      authContext.logout();
    });
  }

  function useUserProfile(pk) {
    return useQuery(["profile", pk], () =>
      fetchContext.authAxios.get(
        `users/${user.pk}`
      ).then((res) => {
        return res.data.profile;
      })
    );
  }

  const { data, isLoading, isError, error } = useUserProfile(user.pk);
  const profile = data;

  if (isLoading)
    return (
      <div className="w-full h-screen flex items-center justify-center ">
        <Spinner size={8} />
      </div>
    );

  if (isError)
    return (
      <div className="w-full h-screen text-gray-500 flex items-center justify-center ">
        <p>Error: {error.message}</p>
      </div>
    );

  const isLinked = profile.is_linked
  const isValid = !user.email.includes("pac+");
  if (isLinked && isValid) {
    return (
      <div className="flex flex-col ">
        <div className="_item-account flex mt-4 text-lg font-semibold border-b border-gray-500 border-opacity-25 mb-4">
          <span className="mb-1 text-red-700">Delete Account</span>
        </div>
        <p className="text-gray-300">Your account is currently linked with an Organization. You cannot delete your account. To delete your account please contact <a className="text-indigo-400" href={`mailto:support@corcoplus.com?subject=Delete%20Account%20Request&body=I%20would%20like%20to%20delete%20my%20account%20for%20the%20${APP_NAME}%20platform%20please%20let%20me%20know%20more%20about%20my%20account.%20My%20email%20is%20${account.email}`}>support@corcoplus.com</a></p>
        <ButtonRound varient='danger' className="opacity-40 cursor-not-allowed mt-5" text="Delete Account" onClick={() => alert('Open Modal')} disabled />
      </div>
    )
  }

  //Return if user can be deleted
  return (
    <>
      <CommonModal
        modalState={toggleModal}
        toggle={setToggleModal}
        title="Confirm Account Deletion"
        description={`Are you sure you want to delete your account? ${account.email} This action cannot be undone.`}
        cancelText={"Cancel"}
        cancelAction={() => setToggleModal(!toggleModal)}
        actionText="Delete Account"
        actionAction={() => handleConfirmDelete()}
        actionButtonVarient="danger"
      />
      <div className="flex flex-col ">
        <div className="_item-account flex mt-4 text-lg font-semibold border-b border-gray-500 border-opacity-25 mb-4">
          <span className="mb-1 text-red-600">Delete Account</span>
        </div>
        <p className="text-gray-300">By clicking this button, you will initiate the account deletion process.</p>
        <p className="text-gray-400 text-sm ">Your account will be deactivated and all data will be permanently deleted in 30 days. This action cannot be undone.</p>
        <ButtonRound className="mt-5" varient='danger' text="Delete Account" onClick={() => setToggleModal(!toggleModal)}
        />
      </div>
    </>
  )
}

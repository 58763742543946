import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useNavigate } from 'react-router-dom';
import { APP_NAME } from 'utils/constants';
import logo from '../../images/logo.svg';
import { ButtonRound } from '../../components/common/Button';
import Card from '../../components/common/Card';
import { useDocumentTitle } from '@uidotdev/usehooks';


const ResetSuccess = () => {
  const [submitLoading, setSubmitLoading] = useState(false);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation('translation');

  useDocumentTitle(`Reset Password Success | ${APP_NAME}`);

  function handleClick() {
    setSubmitLoading(true);
    navigate('/signin');
    setSubmitLoading(false);
  }

  return (
    <>
      <section
        className="flex relative flex-col justify-center items-center p-8 w-full h-screen sm:pt-10">
        <Card>
          <div className="text-gray-400">
            <div className="m-auto mb-10 w-32">
              <img src={logo} alt="Logo" />
            </div>
            <h2 className="mb-2 text-2xl font-bold leading-9 text-center text-gray-100">Success</h2>
            <p className="text-center max-w-sm">Your new password has been created, you can now go sign in.</p>
          </div>
          <div className="mt-6">
            <ButtonRound
              onClick={handleClick}
              className="m-auto space-x-2"
              size="rnd"
              text={`Sign in to ${APP_NAME}`}
              loading={submitLoading}
              />
          </div>
        </Card>
      </section>
    </>
  );
};

export default ResetSuccess;

import React from 'react';
import {
    useNavigate,
    Link,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Icon } from 'components/icons/icon.export';
import { AuthContext } from 'context/AuthContext';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

// Custom link component to navigate via react-routers LINk or via html
// <a> element based on whether an element is external link
const CustomLink = ({ isExternal, to, children, ...props }) => (
    isExternal ? (
        <a href={to} target="_blank" rel="noopener noreferrer" {...props} >{children}</a>
    ) : (
        <Link to={to} {...props}>{children}</Link>
    )
);

const UserDropdown = () => {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const auth = React.useContext(AuthContext);
    const { user_info } = auth.authState;
    const user = user_info ? JSON.parse(user_info) : {};

    // Get pathname to highlight current location in dropdown.
    const pathname  = window.location.pathname;

    // Dropdown Item
    const dropdownItems = [
        {
            id: 1,
            title: t('menu.settings.account'),
            icon: 'settings',
            location: '/settings/account',
            isExternal: false,
            isPrivate: true,
        },
        {
            id: 3,
            title: 'Help Desk',
            icon: 'info',
            location: 'https://help.corco.com/',
            isExternal: true,
            isPublic: true,
            isPrivate: true,
        },
        {
            id: 4,
            title: t('menu.settings.signout'),
            icon: 'signout',
            location: '',
            isExternal: false,
            onClick: auth.logout,
            isPrivate: true,
        },
        {
            id: 5,
            title: t('menu.settings.signin'),
            icon: 'signin',
            location: '/signin',
            isExternal: false,
            isPublic: true,
            isPrivate: false,
        },
        {
            id: 6,
            title: 'Donate',
            icon: 'gift',
            location: 'https://miraclechannel.kindful.com/?campaign=1118917',
            isExternal: true,
            isLast: true,
            isPublic: true,
            isPrivate: true,
        }
];


    return (
        <DropdownMenu.Root>
        <DropdownMenu.Trigger asChild>
            <button className="flex items-center py-1 mb-1 text-sm focus:outline-none cursor-pointer group text-gray-300 hover:text-gray-100 focus:text-gray-100  " aria-label="user dropdown">
                <div className="p-1 mr-2 bg-gray-900 rounded-full border border-gray-800 group-hover:bg-gray-800 group-focus:bg-gray-800" >
                    <Icon name="user" size={20} />
                </div>
                <div className="">
                 <Icon name="chevronDown" size={20} />
                </div>
            </button>
        </DropdownMenu.Trigger>

        <DropdownMenu.Portal>
            <DropdownMenu.Content
                align='center'
                className="flex overflow-hidden absolute right-0 z-10 flex-col bg-gray-900 rounded-lg outline outline-gray-800 focus:outline-none ring-1 ring-black ring-opacity-5 shadow origin-top-right">

                {auth.isAuthenticated ? (
                    <div key={user && user.email} className="flex flex-col p-3 w-full text-xs text-gray-500">
                        Signed in as:
                        <div className="text-xs">
                            {user && user.email ? user.email : null}
                        </div>
                        <div className="text-[10px]">v{APP_VERSION}</div>
                    </div>
                    ) : (null)
                }
                {!auth.isAuthenticated() ? (
                    dropdownItems.map((item) => (
                        item.isPublic ? (
                            <DropdownMenu.Item className="text-gray-400 hover:bg-gray-800 hover:text-gray-200 hover:outline-none focus:outline-none focut:ring-1 focus:ring-black focus:ring-opacity-5" key={item.id}>
                                <CustomLink isExternal={item.isExternal} to={item.location} onClick={item.onClick}
                                className={`
                                flex w-full items-center p-3 text-sm  focus:outline-none focus:text-gray-100 focus:bg-gray-700
                                ${item.isLast && 'bg-indigo-700 text-gray-100' }
                                ${pathname === item.location ? 'text-gray-100' : ''}
                                `}
                                >
                                <Icon name={item.icon} size={20} />
                                <p className="mb-0 ml-2">{item.title}</p>
                                </CustomLink>
                            </DropdownMenu.Item>
                        ) : null)
                    )) : (
                    dropdownItems.map((item) => (
                        item.isPrivate ? (
                            <DropdownMenu.Item className="text-gray-400 hover:bg-gray-800 hover:text-gray-200 hover:outline-none focus:outline-none focut:ring-1 focus:ring-black focus:ring-opacity-5" key={item.id}>
                                <CustomLink isExternal={item.isExternal} to={item.location} onClick={item.onClick}
                                className={`
                                flex w-full items-center p-3 text-sm  focus:outline-none focus:text-gray-100 focus:bg-gray-700
                                ${item.isLast && 'bg-indigo-700 text-gray-100' }
                                ${pathname === item.location ? 'text-gray-100' : ''}
                                `}
                                >
                                <Icon name={item.icon} size={20} />
                                <p className="mb-0 ml-2">{item.title}</p>
                                </CustomLink>
                            </DropdownMenu.Item>
                        ) : null)

                    ))
                }
                <DropdownMenu.Arrow className="fill-white"/>
            </DropdownMenu.Content>
        </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};

export default UserDropdown;
export const APP_NAME = 'Corco+';
export const TAGLINE_MAIN = 'Believe in what you stream.';
export const PAGE_SIZE_ROW = 60;
export const PAGE_SIZE = 20;
export const NUM_SLIDES = 6;
export const MC_CAMPAIGN_URL = 'https://miraclechannel.kindful.com/?campaign=1118917';
export const BASE_IMG_URL = 'https://images.corcoplus.com';
export const BASE_STATIC_URL = 'https://static.corcoplus.com';
export const POLLING_INTERVAL = 600000; // 10 minutes

// (milli * sec)* mins = hrs
// (1000 * 60)*60 = 3,600,000 (1hr)
export const STALE_TIME = 3600000;

export const IMG_URI = 'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wCEAAoKCgoKCgsMDAsPEA4QDxYUExMUFiIYGhgaGCIzICUgICUgMy03LCksNy1RQDg4QFFeT0pPXnFlZXGPiI+7u/sBCgoKCgoKCwwMCw8QDhAPFhQTExQWIhgaGBoYIjMgJSAgJSAzLTcsKSw3LVFAODhAUV5PSk9ecWVlcY+Ij7u7+//CABEIAZoBDgMBIgACEQEDEQH/xAAvAAEBAQEBAQEAAAAAAAAAAAAABAMCBQEHAQEBAQEAAAAAAAAAAAAAAAAAAQID/9oADAMBAAIQAxAAAAD8yHbAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAqJtLPtkfNw81fHHAUAAAAAAAAAAAam27nWekHMvovO+nofMKLIOLoc0FKukjCgAAAAAAAK5LU1y1z1IW3OdZg0uhusef6EJn9opMe+1nnfPSmlmEoAAAAAACyPdKxqAQcaZ51pdJXYgsgO9MEXdZ96k+fDOgAAAAAAAALtPPss0FnDsHyU+YmdAdOQAAAAAAAAAABrrKSvicdcigAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAf/8QALBAAAgECAwYGAgMAAAAAAAAAAQIDABEQElEgMTNAQUIEEyEyYXGBkBRSkf/aAAgBAQABPwD9WgUtuFCBzXkPqKMTjt55IOrf5QAGw0avvFPGUPxrzcMeUZjv2yARY1IhRrdOnMxLmf4GDHKpNGR27jWZtTWZtTWdh3GoZCxynCVcyHUbESKUBKinjQIxCjdykAspOpwm4Z2YuIuLizMPnCx0qH2fmn9jfVWOnJw8MYSKXWwryH1FNE69MYuIuM3EavD9/wCNnxHZ+eSgN0todmQWdhhFxFxkN3b7pJCl7W9a899BUbF1uaY2UnQV576CnkL2uB6clA1mtrszcRsIFJbN0GDtlUnYh4dSexvrlN1RuHX52CiE3Kisif1GMz5jYbhsB2AsCaLuRYseVVipuKSRX+9NkkD1NSS5vRd3OrM6/NDxA6qa/kJoaPiD0Wmdm3n9bn//xAAbEQADAAMBAQAAAAAAAAAAAAAAAREQIDBwQP/aAAgBAgEBPwDzCEJ3pcPE5LRDJicVoh/fS+af/8QAGhEAAgMBAQAAAAAAAAAAAAAAAREAQHAgMP/aAAgBAwEBPwDMHRUXseDBRGqf/9k='
// Component that displays to let the new sign up know they have to go verify their account.

import React, { useState, useEffect } from 'react';

import logo from 'images/logo.svg';

import { useTranslation } from 'react-i18next';
import Card from 'components/common/Card';
import { Icon } from 'components/icons/icon.export';
import { APP_NAME } from 'utils/constants';
import { useDocumentTitle } from '@uidotdev/usehooks'


export default function Verify({ user }) {
  const { t, i18n } = useTranslation('translation');

  useDocumentTitle(`Verify Your Account - ${APP_NAME}`);

  return (
    <Card className="m-auto">
      <div className="flex justify-center items-center py-12 px-4 w-full sm:px-6 lg:px-8">
        <div className="w-full max-w-sm">
          <div className="flex flex-col items-center">
            <div className="mb-10 text-gray-100">
              <Icon name="send" />
            </div>
            <h2 className="mb-2 text-xl font-bold leading-9 text-center text-gray-100">
              Check your email!
            </h2>
            <p className="text-center text-gray-500">
              To confirm your email address, tap the button in the
              email we sent to{' '}
              <span className="text-lg font-semibold text-gray-400 ">
                {user}
              </span>
            </p>
          </div>
        </div>
      </div>
    </Card>
  );
}

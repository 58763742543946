import React from 'react';
import { Field } from 'formik';

export default function Radio({ name, disabled, value, ...props }) {
    return (
        <Field
            name={name}
            value={value}
            type="radio"
            disabled={disabled}
            className=""
            {...props}
        />
    );
}

import React, { useContext, useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ButtonRound } from 'components/common/Button';
import { AuthContext } from 'context/AuthContext';
import TrailerDetail from 'page/Trailer';
import logo from '../../images/logo.svg';

export default function PublicLayout({ props }) {
    const auth = useContext(AuthContext);
    const navigate = useNavigate();
    const [isScrolled, setIsScrolled] = useState(false);
    const [isDonor, setIsDonor] = useState(false);
    const [paramNext, setParamNext] = useState();

    useEffect(() => {
        const updateScroll = () => {
            const scrY = window.scrollY;
            setIsScrolled(scrY > 25);
        };

        const onScroll = () => {
            window.requestAnimationFrame(updateScroll);
        };

        window.addEventListener('scroll', onScroll);

        return () => window.removeEventListener('scroll', onScroll);
    }, [isScrolled]);

    useEffect(() => {
        const donorView = window.location.search.includes('access=donor');
        if (donorView) {
            setIsDonor(true);
        }
    }, []);

    return (
        <>
            <div className="TitleWrapper FixedHeader">
                <div
                    className={`Header w-full fixed top-0 inset-x-0 z-10 px-[5vw] h-[72px] md:-[52px] flex transition duration-300 ${
                        isScrolled
                            ? 'bg-gray-950/80 border-b border-gray-900 backdrop-blur'
                            : 'bg-transparent'
                    }`}
                >
                    <nav className="flex grow justify-between items-center">
                        <img
                            className={`h-full ${
                                isScrolled ? 'w-24' : 'w-32'
                            } transition-[width] duration-200`}
                            src={logo}
                            alt="Logo"
                        />
                        <div className="flex items-center">
                            {!isDonor ? (
                                // <Link to={{
                                //   pathname: '/signup?next=',
                                //   state: {from: localStorage.getItem('tref')}
                                // }}
                                <Link
                                    to="/signup"
                                    className="mr-6 text-gray-200 hover:text-gray-400"
                                >
                                    Sign Up
                                </Link>
                            ) : null}
                            <ButtonRound
                                to={
                                    auth.isAuthenticated()
                                        ? '/discover'
                                        : '/signin'
                                }
                                text="Sign In"
                                onClick={() =>
                                    navigate('/signin', {
                                        state: { from: paramNext },
                                    })
                                }
                            />
                        </div>
                    </nav>
                </div>

                <TrailerDetail
                    setParamNext={setParamNext}
                    paramNext={paramNext}
                />
            </div>

            <div className="flex relative flex-col justify-center items-center py-8 px-[5vw] mt-20 space-y-6 w-full text-xs text-gray-500 FooterWrapper">
                <img className="w-24 h-full" src={logo} alt="Corco+ Logo" />
                <div className="flex flex-wrap justify-center items-start space-x-3 ">
                    <Link to="" className="p-2 hover:text-gray-400">
                        Privacy Policy
                    </Link>
                    <Link to="" className="p-2 hover:text-gray-400">
                        Subscriber Agreement
                    </Link>
                    <Link to="" className="p-2 hover:text-gray-400">
                        Help
                    </Link>
                    <Link to="" className="p-2 hover:text-gray-400">
                        Supported Devices
                    </Link>
                    <Link to="" className="p-2 hover:text-gray-400">
                        About Us
                    </Link>
                </div>
                <p>&copy; Miracle Channel. All Rights Reserved</p>
            </div>
        </>
    );
}

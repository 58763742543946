import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { FetchContext } from '../context/FetchContext';

export function useTrailers(searchParams) {
    const fetchContext = React.useContext(FetchContext);

    function parseUrlQueryParams(paramKey) {
        return new URLSearchParams(searchParams).get(paramKey);
    }

    return useQuery(
        ['trailers'],
        ({ isKids = parseUrlQueryParams('kids') || '', ordering = 'recent' }) =>
            fetchContext
                .authAxios(
                    `/content/trailers/?ordering=${ordering}&is_kids=${isKids}`
                )
                .then((res) => res.data),
        {
            enabled: searchParams !== null,
            refetchOnWindowFocus: true,
            staleTime: 3600000,
        }
    );
}

import React, { createContext, useContext } from 'react';
import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { AuthContext } from './AuthContext';

const FetchContext = createContext();
const { Provider } = FetchContext;

const FetchProvider = ({ children }) => {
    const authContext = useContext(AuthContext);

    const authAxios = axios.create({
        baseURL: import.meta.env.VITE_APP_API_URL,
    });

    const searchAxios = axios.create({
        baseURL: import.meta.env.VITE_APP_SEARCH_URL,
    });

    authAxios.interceptors.request.use(
        (config) => {
            config.headers.Authorization = `Bearer ${authContext.getAccessToken()}`;
            return config;
        },
        (error) => Promise.reject(error)
    );

    searchAxios.interceptors.request.use(
        (config) => {
            config.headers['x-api-key'] = import.meta.env.VITE_APP_SEARCH_KEY;
            config.headers['Version'] = '2'
            return config;
        },
        (error) => Promise.reject(error)
    );

    createAuthRefreshInterceptor(authAxios, authContext.getNewTokenForRequest, {
        pauseInstanceWhileRefreshing: false,
    });

    return (
        <Provider
            value={{
                authAxios,
                searchAxios
            }}
        >
            {children}
        </Provider>
    );
};

export { FetchContext, FetchProvider };

import React, { useContext } from 'react';
import { Spinner } from 'components/common';
import { APP_NAME } from 'utils/constants';
import Thumbnail from 'components/Thumbnail';
import { ContentGrid } from 'components/layouts';
import useIntersectionObserver from 'hooks/useIntersectionObserver';
import { useMovies } from 'api/useMovies';
import { useLocation } from 'react-router-dom';
import { useDocumentTitle } from "@uidotdev/usehooks";

export default function Movies() {
  const location = useLocation();
  const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage, isError, error, refetch } = useMovies(
    location.search ? location.search : '?ordering=recent');

  useDocumentTitle(`All Movies | ${APP_NAME}`);

  React.useEffect(() => {
    refetch();
  }, [location.search]);

  const loadMoreButtonRef = React.useRef();

  useIntersectionObserver({
    target: loadMoreButtonRef,
    onIntersect: fetchNextPage,
    enabled: hasNextPage,
  });

  if (isLoading)
    return (
      <div className="flex justify-center items-center w-full h-screen">
        <Spinner size={6} />
      </div>
    );
  if (isError)
    return (
      <div className="flex justify-center items-center w-full h-screen text-gray-500">
        <p>Error: {error.message}</p>
      </div>
    );

  return (
    <>
      <ContentGrid
        pageType="Movies">
        <div className="grid grid-cols-3 gap-4 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-6 xl:grid-cols-8">
          {data.pages.map((group, i) => (
            <React.Fragment key={i}>
              {group.data.results.map((item) => (
                <Thumbnail key={item.pk} {...item} image={item.images.thumbnail} growOnHover />
              ))}
            </React.Fragment>
          ))}
        </div>
      </ContentGrid>
      <div className="flex justify-center items-center py-10 text-gray-500">
        <button
          ref={loadMoreButtonRef}
          type="button"
          onKeyDown={() => fetchNextPage()}
          disabled={!hasNextPage || isFetchingNextPage}
          onClick={() => fetchNextPage()}
        >
          {isFetchingNextPage ? (
            <div className="flex justify-center items-center w-full">
              <Spinner size={8} />
            </div>
          ) : hasNextPage ? (
            'Load more'
          ) : (
            ''
          )}
        </button>
      </div>
    </>
  );
}

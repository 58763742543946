import React from 'react';
import {
    Link,
    useMatch,
    useSearchParams
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Icon } from 'components/icons/icon.export';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { useGenres } from 'api/useGenres';

// Custom link component to navigate via react-routers LINk or via html
// <a> element based on whether an element is external link
const CustomLink = ({ isExternal, to, children, ...props }) => (
    (
        <Link to={to} {...props}>{children}</Link>
    )
);

const FilterDropdown = (props) => {
    const { t, i18n } = useTranslation();
    const { data: genreAPIData, isFetching } = useGenres();
    // Get pathname to highlight current location in dropdown.
    const { pathname } = useMatch(window.location.pathname);
    const [filterOpen, setFilterOpen] = React.useState(false);
    const [title, setTitle] = React.useState(null);
    const [searchParams, setSearchParams] = useSearchParams();
    // Dropdown Item

    const sortByItems = [
        {
            id: 1,
            title: t('menu.sortBy.recentAdded'),
            key: 'recent',
            checked: true
        },
        {
            id: 2,
            title: t('menu.sortBy.popular'),
            key: 'popular',
            checked: false
        },
        {
            id: 3,
            title: t('menu.sortBy.featured'),
            key: 'is_featured',
            checked: false
        },
        {
            id: 4,
            title: t('menu.sortBy.aToZ'),
            key: 'title',
            checked: false
        },
        {
            id: 5,
            title: t('menu.sortBy.zToA'),
            key: '-title',
            checked: false
        }
    ];

    if(pathname=== "/kids"){
        sortByItems.splice(0, 1)
        const popularIndex  = sortByItems.findIndex(item => item.key === 'popular');
        const popular = sortByItems[popularIndex];
        popular.checked = true;
        sortByItems[popularIndex] = popular;
    }

    const filterByItems = [
        {
            id: 1,
            title: 'All',
            key: 'all',
            checked: true
        },
        {
            id: 2,
            title: t('menu.filterBy.movies'),
            key: 'movie',
            checked: false
        },
        {
            id: 3,
            title: t('menu.filterBy.shows'),
            key: 'show',
            checked: false
        },
    ]
    /**
     * Popular on kids page is checked change the key of All
     * to be content_model=movie&content_model=show
     * */
    if (pathname === "/kids") {
        const popularIndex = sortByItems.findIndex(item => item.key  === 'popular');
        if(popularIndex !== -1 && sortByItems[popularIndex].checked) {
            filterByItems[0].key = ['movie', 'show'];
        }
    }


    const handleFilterOpenAndClose = event => {
        let selectItem = null;
        if (filterOpen) {
            const queryArray = {}
            sorterChecked.forEach(sort => {
                if (sort.checked && sort.key === 'is_featured') {
                    queryArray["is_featured"] = true;
                    selectItem = sort.title;
                } else if (sort.checked) {
                    queryArray["ordering"] = sort.key;
                    selectItem = sort.title;
                }
            });
            availableGenres.forEach(item => {
                if (item.checked) {
                    queryArray[item.content_model ? item.content_model : 'content_model'] = pathname === '/kids' ? item.key : item.title;
                }
            });
            setSearchParams(queryArray);
        }
        setFilterOpen(!filterOpen)
        setTitle(selectItem)
    }

    const getAvailableGenres = (content_model) => {
        const sorter = document.location.search?.split('ordering')[1]
        const isFeature = document.location.search?.includes('is_featured') ? true : false;
        if (sorter || isFeature) {
            let searchTitle = sorter?.replace('=', '').split('&')[0];
            if (isFeature) {
                searchTitle = 'is_featured'
            }
            sortByItems.forEach(element => {
                if (element.key === searchTitle) {
                    element.checked = true;
                    searchTitle = element.title
                } else {
                    element.checked = false;
                }
            });
            setTitle(searchTitle);
        }

        switch (content_model) {
            case 'movies':
                if (genreAPIData) {
                    let id = 0;
                    setAvailableGenres(
                    genreAPIData.pages[0].data.results
                        .filter((val) => {
                            if (val[content_model].count > 0) {
                                val.id = id;
                                id = id + 1;
                                val.checked = false;
                                return val['title']
                            }
                        }),
                    );
                }
                break;
            case 'shows':
                if (genreAPIData) {
                    let id = 0;
                    setAvailableGenres(
                        genreAPIData.pages[0].data.results
                        .filter((val) => {
                            if (val[content_model].count > 0) {
                                val.id = id;
                                id = id + 1;
                                val.checked = false;
                                return  val['title']
                            }
                        }),
                    );
                }
                break;
            case "kids":
                if (genreAPIData) {
                    setAvailableGenres(
                        filterByItems
                    );
                }
                break;
            default:
              break;
          }
    };

    React.useEffect(() => {
        const content_model = pathname.replace('/', '');
        getAvailableGenres(content_model);
    }, [genreAPIData]);

    const [sorterChecked, setSorterChecked] = React.useState(sortByItems);
    const handleSorterClick = (sorterId) => {
        sortByItems.forEach(sorter => {
            if (sorter.id === sorterId) {
                sorter.checked = true
            } else {
                sorter.checked = false
            }
        });
        setSorterChecked(sortByItems);
    }

    const [availableGenres, setAvailableGenres] = React.useState([]);
    const handleFilterClick = async (filter) => {
        availableGenres.forEach(item => {
            if (filter.id === item.id) {
                item.checked = !item.checked;
            } else {
                item.checked = false;
            }
        });
        setAvailableGenres(availableGenres);
    }

    // Style Sheet
    const dropdownLabelTitle = {
        fontFamily: 'Nunito',
        fontWeight: '600',
        fontSize: 13,
        color: '#A0AEC0',
        paddingTop: 10,
        paddingLeft: 5
    }
    const dropdownPopover = {
        width: "250px"
    }
    const applyBtn = {
        height: "50px",
        backgroundColor: "#9370db"
    }
    const IS_KIDS = pathname === "/kids";

    return (
        <DropdownMenu.Root
            open={filterOpen}>
        <DropdownMenu.Trigger asChild>
            <button className="flex items-center py-1 mb-1 text-sm focus:outline-none cursor-pointer group text-gray-300 hover:text-gray-100 focus:text-gray-100  " onClick={handleFilterOpenAndClose} aria-label="Filter dropdown">
                <div className="rounded-full px-4 pb-1 ml-5 bg-gray-900 border border-gray-800 group-hover:bg-gray-800 group-focus:bg-gray-800" >
                    <div className='flex justify-center items-center'>
                        <h5 className='mt-1 mr-1'>{(title ? title : t('menu.sortBy.title'))}</h5>
                            {!filterOpen
                            ? <Icon name='chevronDown' size={16} onClick={handleFilterOpenAndClose} />
                            : <Icon name='chevronUp' size={16} onClick={handleFilterOpenAndClose} />
                            }
                    </div>
                </div>
            </button>
        </DropdownMenu.Trigger>

        <DropdownMenu.Portal>
            <DropdownMenu.Content
                align='center'
                onInteractOutside={handleFilterOpenAndClose}
                className="w-64 flex overflow-hidden mt-1 z-10 flex-col bg-gray-900 rounded-lg outline outline-gray-800 focus:outline-none ring-1 ring-black ring-opacity-5 shadow origin-top-right">
                <DropdownMenu.Label
                    className="DropdownMenuLabel focus:ring-black font-semibold text-sm text-gray-50 pt-3 pl-2"
                >
                        {t('menu.sortBy.sorterTitle')}
                </DropdownMenu.Label>
                {(
                    sorterChecked.map((item) => (
                        (
                            <DropdownMenu.CheckboxItem
                                checked={item.checked}
                                className="text-gray-400 hover:bg-gray-800 hover:text-gray-200 hover:outline-none focus:outline-none focut:ring-1 focus:ring-black focus:ring-opacity-5" key={item.id}>
                                <CustomLink
                                    to={window.location?.search}
                                    onClick={() => handleSorterClick(item.id)}
                                    className="flex w-full items-center p-3 text-sm  focus:outline-none focus:text-gray-100 focus:bg-gray-700"
                                    >
                                    <DropdownMenu.ItemIndicator className="DropdownMenuItemIndicator">
                                         <Icon name="check" size={12} weight='bold'  />
                                    </DropdownMenu.ItemIndicator>
                                    <p className="mb-0 ml-2">{item.title}</p>
                                </CustomLink>
                            </DropdownMenu.CheckboxItem>
                        ))
                    )
                )}
                {pathname.replace('/', '') === ("movies") || pathname.replace('/', '') === ("shows") || pathname.replace('/', '') === ("kids") ? (

                    <DropdownMenu.Label
                        className="DropdownMenuLabel focus:ring-black font-semibold text-sm text-gray-50 pt-3 pl-2">

                    {t('menu.sortBy.filterTitle')}
                    </DropdownMenu.Label>
                    ) : ("")}
                {(
                    availableGenres.map((item) => (
                        (
                            <DropdownMenu.CheckboxItem
                                checked={item.checked}
                                className="text-gray-400 hover:bg-gray-800 hover:text-gray-200 hover:outline-none focus:outline-none focut:ring-1 focus:ring-black focus:ring-opacity-5" key={item.id}>
                                <CustomLink
                                    onClick={() => handleFilterClick(item)}
                                    to={window.location?.search}
                                    className="flex w-full items-center p-3 text-sm  focus:outline-none focus:text-gray-100 focus:bg-gray-700"
                                    >
                                    <DropdownMenu.ItemIndicator className="DropdownMenuItemIndicator">
                                        <Icon name="check" size={12} weight='bold'  />
                                    </DropdownMenu.ItemIndicator>
                                    <p className="mb-0 ml-2">{item.title}</p>
                                </CustomLink>
                            </DropdownMenu.CheckboxItem>
                        ))
                    )
                )}

                <button
                    type="button"
                    onClick={handleFilterOpenAndClose}
                    className='h-12 bg-indigo-700 text-indigo-100 focus:bg-gray-700 focus:text-gray-100'
                    >
                    Apply Filter
                </button>
            </DropdownMenu.Content>
        </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};

export default FilterDropdown;
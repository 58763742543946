import{ useState, useContext, useEffect } from 'react';
import SearchBar from 'components/common/Search/SearchBar';
import { AuthContext } from '../context/AuthContext';
import UserDropdown from './common/UserDropdown';
import { HeaderBanner } from './notifications/BannerNotification';

export default function Header() {
    const [bannerActive, setBannerActive] = useState(false);
    const [bannerHidden, setBannerHidden] = useState(false);

    const authContext = useContext(AuthContext);
    const { user_info } = authContext.authState;
    const user = user_info ? JSON.parse(user_info) : {};

    useEffect(() => {
        if (user && user.state === '110') {
            setBannerActive(true);
        }
        return () => {
            console.log('Header cleanup')
            setBannerHidden(true);
        };
    }, [user]);

    const onHideBanner = () => setBannerHidden(true);

    return (
        <>
            <div className={`${bannerHidden ? 'hidden' : 'fixed z-10 inset-x-0 top-0'}`}>
                <HeaderBanner
                    active={bannerActive}
                    onHideBanner={onHideBanner}
                />
            </div>
            <header className="w-full sticky top-0 md:right-0  z-50 bg-gradient-to-b from-gray-950 to-transparent flex justify-between items-center py-2 px-4 h-20">
                <div className="flex ml-auto">
                    <SearchBar />
                    <div className="mr-8" />
                    <UserDropdown />
                </div>
            </header>
        </>
    );
}

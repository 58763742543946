/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { useWindowSize } from 'hooks/useWindowSize';
import { Spinner } from './common';
import { useCollectionRowByPk } from '../api/useCollectionRowByPk';

import Thumbnail from './Thumbnail';

import { CarouselItem, Carousel } from './CollectionCarousel';

export default function CollectionRow({ title, pk, onClick }) {
    const size = useWindowSize();
    const { data, isLoading, isError, error } = useCollectionRowByPk(pk);

    // Conditionally change the number of slides based on the window size.
    let NUM_SLIDES;
    if (size.width < 640) NUM_SLIDES = 3;
    if (size.width > 768) NUM_SLIDES = 4;
    if (size.width > 1024) NUM_SLIDES = 6;
    if (size.width > 1280) NUM_SLIDES = 8;

    if (isLoading)
        return (
            <div className="flex justify-center items-center w-full h-screen ">
                <Spinner size={8} />
            </div>
        );

    if (isError)
        return (
            <div className="flex justify-center items-center w-full h-screen text-gray-500 ">
                <p>{`Error: ${error.message}`}</p>
            </div>
        );

    return (
        <div className="mb-10 w-full">
            {title && (
                <h3 className="mb-4 ml-[3vw] font-semibold text-gray-100">
                    {title}
                </h3>
            )}
            <Carousel slideNumber={NUM_SLIDES}>
                {data.results.map((item, i) => (
                    <CarouselItem
                        key={item.pk}
                        index={i}
                        growOnHover
                        slideNumber={NUM_SLIDES}
                    >
                        <Thumbnail
                            {...item.content_object}
                            image={item.content_object.images.thumbnail}
                        />
                    </CarouselItem>
                ))}
            </Carousel>
        </div>
    );
}

CollectionRow.propTypes = {
    title: PropTypes.string,
    pk: PropTypes.number,
    onClick: PropTypes.func,
};

import React, { useState, useEffect } from 'react';
import { useQueryClient, useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { ButtonRound } from 'components/common/Button';
import { Icon } from 'components/icons/icon.export';

// Query Hooks
import { useLivestreamByPk } from 'api/useLivestreamsByPk';

// Icons
import { Spinner } from 'components/common';

import VideoPlayer from 'components/VideoPlayer';
import { APP_NAME } from 'utils/constants';
import { useDocumentTitle } from "@uidotdev/usehooks";
import DetailBlock from 'components/DetailBlock';




function LivestreamDetail({ props }) {
  const { pk: livestream_pk } = useParams();
  const [playUrl, setPlayUrl] = useState('');
  const { data, isFetching, isLoading, isError, error } =
    useLivestreamByPk(livestream_pk);
  const queryClient = useQueryClient();

  const bggray950 = '#030712';

  useDocumentTitle(`Watch ${data?.title} | ${APP_NAME}`);

  function handleLivestreamPlayClick() {
    playerContext.setVideoSrc(data._play);
  }

  if (isLoading)
    return (
      <div className="flex justify-center items-center w-full h-screen ">
        <Spinner size={8} />
      </div>
    );
  if (isError)
    return (
      <div className="flex justify-center items-center w-full h-screen text-gray-500 ">
        <p>Error: {error.message}</p>
      </div>
    );

  return (
    <>
      <VideoPlayer
        title={data.title}
        playUrl={playUrl}
        isLive={true}
        livestream
        onDismiss={() => {
          queryClient.invalidateQueries([
            'livestream',
            livestream_pk,
          ]);
          setPlayUrl(undefined);
        }}
      />
      <div className="overflow-y-scroll px-[3vw] -mt-20 pt-20 h-full">
        <div className="flex flex-col w-full">
          <DetailBlock data={data} playUrl={data._play} page="livestream" />
          {/* <h2 className="mb-6 text-6xl font-bold text-gray-100">
                        {data.title}
                    </h2> */}
          <div className="flex py-1 space-x-4">
            <div>

              <ButtonRound
                onClick={handleLivestreamPlayClick}
                className="self-start space-x-2 py-4 px-4 text-xl"
                size="rnd"
                varient="primary"
                IconStart={<Icon name="play" size={24} weight='fill' />}
                text="Watch Livestream"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LivestreamDetail;

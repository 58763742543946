import { Spinner } from "components/common"

function Home() {
  return (
    <div className="flex flex-col justify-center items-center w-full h-screen text-gray-500">
      <Spinner size={8} />
    </div>
  )
}

export default Home
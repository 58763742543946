import React from 'react';
import { useTranslation } from 'react-i18next';
import { Hyperlink } from './common/Hyperlink';

const Footer = () => {
    const { t, i18n } = useTranslation('translation');

    return (
        <footer className="z-0 mt-auto flex flex-col justify-center items-center">
            <p className="text-xs text-gray-500">
                {t('global.footer.message')}
                <Hyperlink
                    to="https://help.corco.com/article/41-terms-of-service"
                    target="_blank"
                    text={t('global.footer.terms')}
                />
                {t('global.footer.and')}
                <Hyperlink
                    to="https://help.corco.com/article/42-privacy-policy"
                    target="_blank"
                    text={t('global.footer.privacy')}
                />
                .
            </p>
            <p className="text-xs text-gray-500">
                <Hyperlink
                    to="https://miraclechannel.ca/accessibility"
                    target="_blank"
                    text={t('global.footer.a11y')}
                />
            </p>

        </footer>
    );
};

export default Footer;

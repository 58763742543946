import React from 'react';
import { useField } from 'formik';
import cn from 'classnames';
import FormError from './common/FormError';
import Input from './common/Input';
import { Checkbox, Radio } from './common';
import { Icon } from './icons/icon.export';

// Text Fields
const TextInput = ({
  ariaLabel,
  name,
  type,
  placeholder,
  disabled,
  className,
}) => {
  const [field, meta] = useField(name);
  return (
    <>
      <Input
        field={field}
        ariaLabel={ariaLabel}
        name={field.name}
        type={type}
        placeholder={placeholder}
        disabled={disabled}
        className={cn(className)}
      />
      {meta.touched && meta.error ? (
        <FormError text={meta.error} />
      ) : null}
    </>
  );
};
// Password Fields
export const SecureTextInput = ({
  ariaLabel,
  name,
  placeholder,
  disabled,
  className,
  details = false,
  iconColor = 'text-gray-500',
}) => {
  const [field, meta] = useField(name);
  const [showPassword, setShowPassword] = React.useState(false);
  return (
    <>
      <div className="relative">
        <Input
          field={field}
          ariaLabel={ariaLabel}
          name={field.name}
          type={!showPassword ? 'password' : 'text'}
          placeholder={placeholder}
          disabled={disabled}
          className={cn(`pl-4`, className)}
        />
        <div
          className={cn(
            `absolute top-0 bottom-0 right-4 cursor-pointer flex items-center`,
            iconColor
          )}
          onClick={() => setShowPassword(!showPassword)}
        >
          {!showPassword ? (
            <Icon name="show" size={20} title="Show Password" />
          ) : (
            <Icon name="hide" size={20} title="Hide Password" />
          )}
        </div>
      </div>
      {meta.touched && meta.error ? (
        <FormError text={meta.error} />
      ) : null}
      {details ? (
        <div className="text-xs text-gray-500">
          <ul>
            <li>
              Your password must contain at least 8 characters.
            </li>
            <li>Your password can't be entirely numeric.</li>
          </ul>
        </div>
      ) : null}
    </>
  );
};

// Radio Buttons
const SelectInput = ({ name, type, value, disabled }) => {
  const [field, meta] = useField(name);
  return (
    <>
      <Radio
        field={field}
        name={field.name}
        value={value}
        type={type}
        disabled={disabled}
      />
      {meta.touched && meta.error ? (
        <FormError text={meta.error} />
      ) : null}
    </>
  );
};

// Check Buttons
const CheckInput = ({ name, disabled }) => {
  const [field, meta] = useField(name);
  return (
    <>
      <Checkbox field={field} name={field.name} disabled={disabled} />
      {meta.touched && meta.error ? (
        <FormError text={meta.error} />
      ) : null}
    </>
  );
};

export { TextInput, SelectInput, CheckInput };

import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { FetchContext } from 'context/FetchContext';
import { STALE_TIME } from 'utils/constants';

export function useShowByPk(pk) {
    const fetchContext = React.useContext(FetchContext);

    return useQuery(['show', pk], () =>
        fetchContext.authAxios(`/content/shows/${pk}/`).then((res) => res.data),
        {
            keepPreviousData: true,
            staleTime: STALE_TIME
        }
    );
}

import React, { useEffect, useContext, useState } from 'react';
import { AuthContext } from 'context/AuthContext';
import { UserContext } from 'context/UserContext';
import { Link } from 'react-router-dom';
import { APP_NAME, MC_CAMPAIGN_URL } from 'utils/constants';

import { useWindowSize } from 'hooks/useWindowSize';
import { Icon } from 'components/icons/icon.export';

export const StatusBanner = ({ active }) => (
  <>
    {active ? (
      <div className="overflow-hidden py-2 bg-gradient-to-r from-indigo-600 to-blue-500">
        <div className="relative px-4 mx-auto sm:px-6 lg:px-8">
          <div className="flex relative justify-center items-center">
            <div className="flex items-center space-x-2 text-sm font-medium leading-tight text-white">
              <div>
                <Icon name="info" />{' '}
              </div>
              <div>
                {' '}
                Some of our users are having trouble signing in. We are working to fix this issue.{' '}
                <span className="font-bold">Thank you for your patience.</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : null}
  </>
);

const HeaderBanner = ({ active, onHideBanner }) => {
  if (active) {
    return (
      <div className="bg-indigo-600">
        <div className="py-2 px-3 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="flex flex-wrap justify-between items-center">
            {/* w-0 flex-1 flex items-cente */}
            <div className="flex flex-1 items-center ">
              <span className="flex p-2 bg-indigo-800 rounded-full">
                {/* Icon name: speakerphone */}
                <svg
                  className="w-6 h-6 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M11 5.882V19.24a1.76 1.76 0 01-3.417.592l-2.147-6.15M18 13a3 3 0 100-6M5.436 13.683A4.001 4.001 0 017 6h1.832c4.1 0 7.625-1.234 9.168-3v14c-1.543-1.766-5.067-3-9.168-3H7a3.988 3.988 0 01-1.564-.317z"
                  />
                </svg>
              </span>
              {/* TODO: [COR-7] Bootstrap Global adds margin to the <p> tag.   */}
              <p className="mb-0 ml-3 font-medium text-white truncate">
                {/* For Small Screens */}
                <span className="md:hidden">We announced a new feature!</span>
                {/* For Large Screens */}
                <span className="hidden md:inline">Big news! We're excited to announce a brand new feature.</span>
              </p>
            </div>
            <div className=" shrink-0 w-1/2 sm:order-2 sm:mt-0 sm:w-auto">
              <a
                href="https://miraclechannel.kindful.com/?campaign=1118917"
                target="_blank"
                className="flex justify-center items-center py-2 px-4 text-sm font-medium text-indigo-600 bg-white hover:bg-indigo-50 rounded-md border border-transparent shadow-sm"
                rel="noreferrer"
              >
                Learn more
              </a>
            </div>
            <div className="shrink-0 sm:order-3 sm:ml-3">
              <button
                type="button"
                onClick={onHideBanner}
                className="flex p-2 -mr-1 hover:bg-indigo-500 rounded-md focus:outline-none focus:ring-2 focus:ring-white sm:-mr-2"
              >
                <span className="sr-only">Dismiss</span>
                {/* Icon name: x  */}
                <svg
                  className="w-6 h-6 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return null;
};

// SIDEBAR BANNER
const SideBarBanner = () => {
  const authContext = useContext(AuthContext);
  const { user_info } = authContext.authState;
  const user = user_info ? JSON.parse(user_info) : {};

  // Show same banner as "Free side" on Public
  if ((user && user.state === '110') || authContext.authState.access_token === null) {
    return (
      <div className="hidden overflow-hidden p-3 text-gray-100  bg-gray-950 rounded-lg border border-gray-700 border-opacity-25 md:block ">
        <div className="mb-4">
          <div className="mb-1 font-semibold text-md">Did you know!</div>
          <p className="text-xs leading-relaxed text-gray-400">
            {APP_NAME} is made possible by donors who want to support entertainment made by Christians.
          </p>
        </div>
        <div className="flex">
          <div className="shadow-sm">
            <a
              href={MC_CAMPAIGN_URL}
              target="_blank"
              className="flex justify-center items-center py-2 px-4 text-sm font-medium leading-5 text-gray-100 hover:text-indigo-200 bg-indigo-700 rounded border border-transparent focus:outline-none transition duration-150 ease-in-out cursor-pointer focus:shadow-outline"
              rel="noreferrer"
            >
              Learn more
            </a>
          </div>
        </div>
      </div>
    );
  }
  return null;
};
//BOTTOM BANNER
const BottomBanner = ({ state }) => {
  const authContext = useContext(AuthContext);
  const { user_info } = authContext.authState;
  const user = user_info ? JSON.parse(user_info) : {};

  if (user && user.state === '110') {
    return (
      <div
        className="hidden fixed inset-x-0 bottom-24 z-10 max-w-screen-md md:block md:right-0 md:bottom-6 md:mx-auto"
        style={{ width: 'calc(100% - 12rem)', left: '12rem' }}
      >
        <div className="p-6 bg-gradient-to-tr  from-indigo-900 via-indigo-800 to-indigo-700 rounded-xl border border-indigo-200 border-opacity-25 shadow-lg">
          <div className="flex flex-wrap justify-between items-center">
            <div className="flex flex-1 items-center w-full sm:w-0">
              <p className="text-gray-200 truncate">
                <strong className="font-medium text-white md:hidden">Donate to Miracle Channel,</strong>
                <strong className="hidden text-white md:inline font-sm">Donate to Miracle Channel,</strong>
                <span className="lg:hidden"> and get unlimited access</span>
                <span className="hidden md:inline lg:hidden"> to thousands of titles</span>
                <span className="hidden lg:inline"> and get unlimited access to thousands of titles</span>
              </p>
            </div>
            <div className="shrink-0">
              <div className="shadow-sm">
                <a
                  href="https://miraclechannel.kindful.com/?campaign=1118917"
                  target="_blank"
                  className="flex justify-center items-center py-2 px-4 text-sm font-medium leading-5 text-gray-900 hover:bg-gray-200 bg-gray-100 rounded border border-transparent focus:outline-none transition duration-150 ease-in-out cursor-pointer focus:shadow-outline"
                  rel="noreferrer"
                >
                  Get Access
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return null;
};

const DevBanner = (props) => {
  const size = useWindowSize();
  const [hidden, setHidden] = React.useState(true);
  const authContext = React.useContext(AuthContext);
  const userContext = React.useContext(UserContext);
  const { user_info, access_token, expires_in, expires_at, refresh_token } = authContext.authState;

  // Show dev banner only in development environment.
  if (!props.env) {
    return null;
  }
  const expiryInt = parseInt(expires_at);
  const expiryTime = new Date(expiryInt).toLocaleTimeString('en-US');
  const expiryDate = new Date(expiryInt).toLocaleDateString('en-US');
  // const userState = JSON.parse(user_info)

  return (
    <React.Fragment>
      <div
        className={
          !hidden
            ? 'bg-gray-900 p-12 outline outline-gray-400 text-white rounded-lg fixed bottom-12 top-12 right-2  word-wrap  z-[9999]'
            : 'hidden'
        }
      >
        <div className="mb-6">
          <p>Environment: <strong>{import.meta.env.DEV ? "Development" : "Production"}</strong> </p>
          <p>API Endpoint: <strong>{import.meta.env.VITE_APP_API_URL}</strong></p>
          <p>Token: <strong>{access_token}</strong></p>
          <p>Expires in: <strong>{expires_in}</strong></p>
          <p>Expires at: <strong>{expiryDate}, {expiryTime}</strong></p>
          <p>Refresh: <strong>{refresh_token}</strong></p>
          <p>User: <strong>{user_info}</strong></p>
          <p>User data:<strong>{JSON.stringify(userContext.userData)}</strong></p>
          <p>LocalStorage Tier: <strong>{localStorage.getItem('tier')}</strong></p>
          <p>LocalStorage Country: <strong>{localStorage.getItem('country')}</strong></p>
        </div>
        <div className="w-full mb-10 text-white outline-dotted outline-pink-600 sm:outline-yellow-600 md:outline-blue-600 lg:outline-orange-600 xl:outline-green-600 2xl:outline-red-600">
          <p className="text-center">Window Size: <strong>{size.width}</strong></p>
        </div>
        <div className="flex flex-col space-y-2">
          <button className="py-2 px-3 rounded text-white bg-brand-700" type="button" onClick={authContext.requestNewToken}>
            Get Refresh Token
          </button>
          <button className="py-2 px-3 rounded text-white bg-gray-800" type="button" onClick={authContext.logout}>
            Logout
          </button>
        </div>
      </div>
      <div className={!hidden ? 'fixed bottom-2 right-2 z-50' : 'fixed bottom-2 right-2 z-50'}>
        <button
          className="p-2 text-white bg-black rounded"
          type="button"
          onClick={() => {
            hidden ? setHidden(false) : setHidden(true);
          }}
        >
          {hidden ? <Icon name="arrowLineRight" size={24} /> : <Icon name="arrowLineLeft" size={24} />}
        </button>
      </div>
    </React.Fragment>
  );
};

export { HeaderBanner, DevBanner, SideBarBanner, BottomBanner };

import React, { useState, useEffect } from 'react';
import { publicFetch } from 'utils/fetch';
import { Link, useParams, useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import { ButtonRound } from 'components/common/Button';
import Card from 'components/common/Card';
import Footer from 'components/Footer';
import { Icon } from 'components/icons/icon.export';
import { Spinner } from 'components/common';
import { APP_NAME } from 'utils/constants';
import { useDocumentTitle } from '@uidotdev/usehooks';

export default function VerifySuccess() {
  const [submitLoading, setSubmitLoading] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState();
  const [submitError, setSubmitError] = useState();
  const [redirectOnSubmit, setRedirectOnSubmit] = useState(false);

  const { t, i18n } = useTranslation('translation');
  const navigate = useNavigate();
  const { uid, token } = useParams();


  const incomingValues = {
    uid: uid,
    token: token,
  };
  useDocumentTitle(`Verify Success | ${APP_NAME}`);
  useEffect(() => {
    submitCredentials(incomingValues);
  }, []);

  // Submit Credentials
  const submitCredentials = async (credentials) => {
    try {
      setSubmitLoading(true);

      const { data } = await publicFetch.post('users/verify_confirm/', credentials);

      setSubmitSuccess(data.message);
      setSubmitError('');
      setTimeout(() => {
        setRedirectOnSubmit(true);
        setSubmitLoading(false);
      }, 1000);
    } catch (error) {
      setSubmitLoading(false);
      const { status } = error.response;
      if (status === 400) {
        setSubmitError('There is an issue verifying your account.');
      } else {
        setSubmitError(error.message);
      }
      // if (status === 400 && data.token) {
      //     setSubmitError('There has been an issue verifying your account.')
      // } else if (error.response.status === 400 && data.uid) {
      //     setSubmitError("There is an issue with the UID value")
      // } else if (error.response.status === 400 && data.uid && data.token) {
      //     setSubmitError("There is an issue with the UID and Token value")
      // }
      setSubmitSuccess(null);
    }
  };

  function onClick() {
    navigate('/signin');
  }

  if (submitLoading) {
    return (
      <div className="flex justify-center items-center w-full h-screen ">
        <Spinner size={8} />
      </div>
    );
  }
  if (submitError) {
    return (
      <div className="flex justify-center items-center w-full h-screen ">
        <div className="mb-2 text-center text-gray-400">
          <p>{submitError}</p>
          <Link to="/verify-request" className="text-xs text-indigo-300 hover:underline">
            Request a new verification email
          </Link>
        </div>
      </div>
    );
  }

  return (
    <>
      <section className="flex relative flex-col justify-center items-center p-8 w-full h-screen bg-center bg-no-repeat bg-cover sm:pt-10 overlay-gradient">
        <Card>
          <div className="flex flex-col justify-center items-center text-gray-400">
            <div className="mb-10 text-gray-100">
              <Icon name="check" />
            </div>
            <h2 className="mb-2 text-2xl font-bold leading-9 text-center text-gray-100">Success</h2>
            <p className="text-center">Your email address has been successfully verified.</p>
            <div className="mt-6">
              <ButtonRound
                text="Sign in to start watching"
                onClick={onClick}
                className="self-start space-x-2"
                size="rnd"
              />
            </div>
          </div>
        </Card>
        <Footer />
      </section>
    </>
  );
}

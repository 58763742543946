import { IconProps } from 'phosphor-react';
import { Compass } from 'phosphor-react';
import { FilmSlate } from 'phosphor-react';
import { TelevisionSimple } from 'phosphor-react';
import { Baby } from 'phosphor-react';
import { BookOpen } from 'phosphor-react';
import { User } from 'phosphor-react';
import { Info } from 'phosphor-react';
import { GearSix } from 'phosphor-react';
import { CaretLeft } from 'phosphor-react';
import { CaretRight } from 'phosphor-react';
import { CaretUp } from 'phosphor-react';
import { CaretDown } from 'phosphor-react';
import { ArrowLineLeft } from 'phosphor-react';
import { ArrowLineRight } from 'phosphor-react';
import { ArrowLineUp } from 'phosphor-react';
import { ArrowLineDown } from 'phosphor-react';
import { BookmarksSimple } from 'phosphor-react';
import { Gift } from 'phosphor-react';
import { Check } from 'phosphor-react';
import { Play } from 'phosphor-react';
import { PlayCircle } from 'phosphor-react';
import { Pause } from 'phosphor-react';
import { SkipForward } from 'phosphor-react';
import { SkipBack } from 'phosphor-react';
import { FastForward } from 'phosphor-react';
import { Rewind } from 'phosphor-react';
import { Broadcast } from 'phosphor-react';
import { ClosedCaptioning } from 'phosphor-react';
import { SpeakerSlash } from 'phosphor-react';
import { SpeakerNone } from 'phosphor-react';
import { SpeakerLow } from 'phosphor-react';
import { SpeakerHigh } from 'phosphor-react';
import { DotsThreeVertical } from 'phosphor-react';
import { SignOut } from 'phosphor-react';
import { SignIn } from 'phosphor-react';
import { XCircle } from 'phosphor-react';
import { X } from 'phosphor-react';
import { SmileySad } from 'phosphor-react';
import { MagnifyingGlass } from 'phosphor-react';
import { Plus } from 'phosphor-react';
import { List } from 'phosphor-react';
import { PaperPlaneTilt } from 'phosphor-react';
import { Eye } from 'phosphor-react';
import { EyeSlash } from 'phosphor-react';




interface MyIconProps extends IconProps {
  alt?: string;
  className?: string;
  name:
  "discover" |
  "movie" |
  "series" |
  "kid" |
  "topic" |
  "user" |
  "settings" |
  "chevronLeft" |
  "chevronRight" |
  "chevronUp" |
  "chevronDown" |
  "info" |
  "gift" |
  "bookmarks" |
  "check" |
  "arrowLineLeft" |
  "arrowLineRight" |
  "arrowLineUp" |
  "arrowLineDown" |
  "play" |
  "playCircle" |
  "pause" |
  "skipForward" |
  "skipBack" |
  "fastForward" |
  "rewind" |
  "boadcast" |
  "closedCaptioning" |
  "volumeMute" |
  "volumeNone" |
  "volumeLow" |
  "volumeHigh" |
  "dotsThreeVertical" |
  "signout" |
  "signin" |
  "close" |
  "closeCircle" |
  "frown" |
  "search" |
  "plus" |
  "list" |
  "send" |
  "show" |
  "hide";
}

export const Icon = (props: MyIconProps) => {
  const { name, ...restProps } = props;
  const Icons: Record<MyIconProps["name"], any> = {
    discover: <Compass {...restProps} />,
    movie: <FilmSlate {...restProps} />,
    series: <TelevisionSimple {...restProps} />,
    kid: <Baby {...restProps} />,
    topic: <BookOpen {...restProps} />,
    user: <User {...restProps} />,
    settings: <GearSix {...restProps} />,
    info: <Info {...restProps} />,
    bookmarks: <BookmarksSimple {...restProps} />,
    gift: <Gift {...restProps} />,
    check: <Check {...restProps} />,
    chevronLeft: <CaretLeft {...restProps} />,
    chevronRight: <CaretRight {...restProps} />,
    chevronDown: <CaretDown {...restProps} />,
    chevronUp: <CaretUp {...restProps} />,
    arrowLineLeft: <ArrowLineLeft {...restProps} />,
    arrowLineRight: <ArrowLineRight {...restProps} />,
    arrowLineUp: <ArrowLineUp {...restProps} />,
    arrowLineDown: <ArrowLineDown {...restProps} />,
    play: <Play {...restProps} />,
    playCircle: <PlayCircle {...restProps} />,
    pause: <Pause {...restProps} />,
    skipForward: <SkipForward {...restProps} />,
    skipBack: <SkipBack {...restProps} />,
    fastForward: <FastForward {...restProps} />,
    rewind: <Rewind {...restProps} />,
    boadcast: <Broadcast {...restProps} />,
    closedCaptioning: <ClosedCaptioning {...restProps} />,
    volumeMute: <SpeakerSlash {...restProps} />,
    volumeNone: <SpeakerNone {...restProps} />,
    volumeLow: <SpeakerLow {...restProps} />,
    volumeHigh: <SpeakerHigh {...restProps} />,
    dotsThreeVertical: <DotsThreeVertical {...restProps} />,
    signout: <SignOut {...restProps} />,
    signin: <SignIn {...restProps} />,
    close: <X {...restProps} />,
    closeCircle: <XCircle {...restProps} />,
    frown: <SmileySad {...restProps} />,
    search: <MagnifyingGlass {...restProps} />,
    plus: <Plus {...restProps} />,
    list: <List {...restProps} />,
    send: <PaperPlaneTilt {...restProps} />,
    show: <Eye {...restProps} />,
    hide: <EyeSlash {...restProps} />
  };
  return Icons[name];
};

import React from 'react';

const FormSuccess = ({ text }) => (
    <section className="p-2 mb-2 text-center ">
        <p className="font-bold text-green-700">
            <span>Success</span>
            <span className="ml-1">{text}</span>
        </p>
    </section>
);

export default FormSuccess;

import React, { useState, useEffect, useContext } from 'react';

// Query Hooks
import { useLivestreams } from 'api/useLivestreams';
import { useCollections } from 'api/useCollections';
import { usePlayActivity } from 'api/usePlayActivity';
import { useSaveActivity } from 'api/useSaveActivity';
import { useFeaturedContent } from 'api/useFeaturedContent';
// Components
import Collections from 'components/Collections';
import FeaturedCarousel from 'components/FeaturedCarousel';
import LiveStreamsRow from 'components/LivestreamsRow';
import ContinueWatchingRow from 'components/ContinueWatchingRow';
import WatchlistRow from 'components/WatchlistRow';

import Thumbnails from 'components/Thumbnails';

import { APP_NAME } from 'utils/constants';
import VideoPlayer from 'components/VideoPlayer';
import { useQueryClient } from '@tanstack/react-query';
import { Spinner } from '../../components/common';
import { AuthContext } from '../../context/AuthContext';
import { PlayerContext } from 'context/PlayerContext';
import { useDocumentTitle } from "@uidotdev/usehooks";
import { useFeature } from "flagged";


function Discover() {
  const queryClient = useQueryClient();
  const playerContext = useContext(PlayerContext);
  const [playUrl, setPlayUrl] = React.useState(null);
  const [title, setTitle] = React.useState('');
  const [type, setType] = React.useState(null);

  const sideBarWatchlistFlag = useFeature('sideBarWatchlistFlag')

  useDocumentTitle(`Discover | ${APP_NAME}`)

  const auth = useContext(AuthContext);
  // eslint-disable-next-line camelcase
  const { user_info } = auth.authState;
  const user = user_info ? JSON.parse(user_info) : {};

  const { data: featuredData, isLoading: featuredLoading, isSuccess: featuredSuccess } = useFeaturedContent();

  const {
    data: livestreamsData,
    isFetching: livestreamsFetching,
    isLoading: livestreamsLoading,
    isError: livestreamsIsError,
    error: livestreamsError,
  } = useLivestreams();

  const {
    data: collectionsData,
    isFetching: collectionsFetching,
    fetchNextPage: collectionsFetchNextPage,
    hasNextPage: collectionsHasNextPage,
    isFetchingNextPage: collectionsFetchingNextPage,
  } = useCollections();

  const {
    data: playActivityData,
    isFetching: playActivityFetching,
    isLoading: playActivityLoading,
    isError: playActivityisError,
    error: playActivityError,
    fetchNextPage: playActivityFetchNextPage,
    hasNextPage: playActivityHasNextPage,
    isFetchingNextPage: playActivityFetchingNextPage,
  } = usePlayActivity(user.pk);

  const {
    data: saveActivityData,
    isLoading: saveActivityLoading,
    isError: saveActivityisError,
    error: saveActivityError,
    isFetching: saveActivityFetching,
    fetchNextPage: saveActivityFetchNextPage,
    hasNextPage: saveActivityHasNextPage,
    isFetchingNextPage: saveActivityFetchingNextPage,
  } = useSaveActivity(user.pk);


  return (
    <>
      {/* {!auth.isVerified() && <Verify/>} */}

      <VideoPlayer
        title={title}
        playUrl={playerContext.videoSrc}
        isLive={playerContext.isLive}
        type={type}
        onDismiss={() => {
          setPlayUrl(playerContext.setVideoSrc(null));
          queryClient.refetchQueries(['playactivity']);
        }}
      />

      <div className="-mt-20" data-testid="discover" >
        <FeaturedCarousel items={featuredData} loading={featuredLoading} success={featuredSuccess} />
        <button type='button' onClick={() => {
          throw new Error('Sentry Test Error');
        }}
        > SEND TEST ERROR </button>

        <LiveStreamsRow
          livestreams={livestreamsData}
          loading={livestreamsLoading}
          error={livestreamsError}
          isError={livestreamsIsError}
        />
        {playActivityData?.count > 0 ?
          <ContinueWatchingRow
            title="Continue Watching"
            data={playActivityData}
            loading={playActivityLoading}
            isError={playActivityisError}
            error={playActivityError}
            fetchNextPage={playActivityFetchNextPage}
            hasNextPage={playActivityHasNextPage}
            fetchingNextPage={playActivityFetchingNextPage}

          />
        : null }
        {!sideBarWatchlistFlag &&
          saveActivityData?.count > 0 ?
            <WatchlistRow
            title="Watchlist"
            data={saveActivityData}
            loading={saveActivityLoading}
            isError={saveActivityisError}
            error={saveActivityError}
            fetchNextPage={saveActivityFetchNextPage}
            hasNextPage={saveActivityHasNextPage}
            fetchingNextPage={saveActivityFetchingNextPage}
            />
        : null }

        <Collections
          collections={collectionsData}
          loading={collectionsFetching}
          fetchNextPage={collectionsFetchNextPage}
          hasNextPage={collectionsHasNextPage}
          fetchingNextPage={collectionsFetchingNextPage}
        />
      </div>
    </>
  );
}

export default Discover;

import React, { useState, useEffect, useContext } from 'react';
import { useQueryClient, useQuery, useMutation } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { ButtonRound } from 'components/common/Button';

// Custom Hooks
import { useMovieByPk } from 'api/useMovieByPk';
import { useSaveWatchlist, useDeleteWatchlist } from 'api/mutations/watchlist';

// Icons
import { Spinner } from 'components/common';

import VideoPlayer from 'components/VideoPlayer';
import Progress from 'components/Progress';
import { APP_NAME } from 'utils/constants';
import DetailBlock from 'components/DetailBlock';
import { PlayerContext } from 'context/PlayerContext';
import { Icon } from 'components/icons/icon.export';
import { useDocumentTitle } from "@uidotdev/usehooks";

export default function MovieDetail({ content }) {
  const { pk: moviePk } = useParams();

  const [playUrl, setPlayUrl] = useState('');
  const { data, isFetching, isLoading, isError, error } = useMovieByPk(moviePk);
  const queryClient = useQueryClient();

  // for new player component
  const playerContext = useContext(PlayerContext);
  const [title, setTitle] = React.useState('');
  const [type, setType] = React.useState(null);

  useDocumentTitle(`Watch ${data?.title} | ${APP_NAME}`);

  const saveWatchlistMutation = useSaveWatchlist(data?.pk);
  const deleteWatchlistMutation = useDeleteWatchlist(data?.pk);
  const _detail = data?._save.split('save/').reverse().pop();

  function clickWatchlistButton() {
    if (data.activity.save_activity === null) {
      saveWatchlistMutation.mutate(_detail);
    } else {
      deleteWatchlistMutation.mutate(_detail);
    }
  }

  function handleMoviePlayClick() {
    setTitle(data.title)
    playerContext.setVideoSrc(data._play);
  }

  if (isLoading)
    return (
      <div className="flex justify-center items-center w-full h-screen ">
        <Spinner size={8} />
      </div>
    );
  if (isError)
    return (
      <div className="flex justify-center items-center w-full h-screen text-gray-500 ">
        <p>Error: {error.message}</p>
      </div>
    );

  return (
    <>
      <VideoPlayer
        title={title}
        playUrl={playerContext.videoSrc}
        type={type}
        onDismiss={() => {
          setPlayUrl(playerContext.setVideoSrc(null));
          queryClient.refetchQueries(['playactivity']);
          //Refetch to update the playactivity
          queryClient.refetchQueries(['movie', moviePk]);
        }}
      />

      <div className="overflow-y-scroll px-[3vw] -mt-20 pt-20 h-full ">
        <div className="flex flex-col w-full">
          <DetailBlock data={data} playUrl={data._play} page="movie" />
          <div className="flex py-1 space-x-4">
            <div>
              <ButtonRound
                onClick={handleMoviePlayClick}
                className="self-start space-x-2 py-4 px-4 text-xl"
                size="rnd"
                varient="primary"
                IconStart={<Icon name="play" size={24} weight='fill' />}
                text="Play"
              />
              <Progress progress={data.activity.play_activity?.play_end_point_percentage} />
            </div>
            {/* Progress bar Component here */}
            {/* TODO ADD MUTATION */}
            <ButtonRound
              onClick={() => clickWatchlistButton()}
              //   data.activity.save_activity === null
              //     ? saveWatchlistMutation.mutate(movie_pk)
              //     : deleteWatchlistMutation.mutate(movie_pk);
              // }}

              className="self-start text-xl py-4 px-4 rounded-full"
              size="rnd"
              varient="plain"
              IconStart={
                saveWatchlistMutation.isLoading || deleteWatchlistMutation.isLoading ? (
                  <Spinner size={6} />
                ) : data.activity.save_activity === null ? (
                  <Icon name="plus" size={24} weight='bold' />
                ) : (
                  <Icon name="check" size={24} weight="bold" />
                )
              }
            />
          </div>
        </div>
      </div>
    </>
  );
}

import React, { useState, useEffect, Fragment, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useTranslation } from 'react-i18next';
import { Icon } from 'components/icons/icon.export';
import { publicFetch } from 'utils/fetch';
import { Spinner } from './common';

export function Success({ toggle, modalState, user, text }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const focusButtonRef = useRef(null);
  const { t, i18n } = useTranslation('translation');
  return (
    <Transition appear show={modalState} as={Fragment}>
      <Dialog
        initialFocus={focusButtonRef}
        as="div"
        className="overflow-y-auto fixed inset-0 z-10"
        open={modalState}
        onClose={() => toggle(false)}
      >
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-50" />
        <div className="px-4 min-h-screen text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block overflow-hidden p-6 my-8 w-full max-w-md text-left align-middle bg-white rounded-2xl shadow-xl transition-all">
              <div className="mt-2">
                <div className="flex flex-col justify-center items-center ">
                  <div className="p-4 mb-3 bg-green-100 rounded-full">
                    <Check
                      size="32"
                      className="text-green-600"
                    />
                  </div>
                  <p className="text-sm text-center text-gray-500">
                    A verification email has been sent to{' '}
                    <strong>{user}</strong>.
                  </p>
                </div>
              </div>

              <div className="flex justify-items-stretch mt-4">
                <button
                  ref={focusButtonRef}
                  type="button"
                  className="inline-flex flex-1 justify-center py-2 px-4 w-full text-base font-medium text-gray-700 bg-white hover:bg-gray-50 rounded-full border border-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 shadow-sm sm:w-auto sm:text-sm"
                  onClick={() => {
                    toggle(false);
                    setSuccess(false);
                  }}
                >
                  Close
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}

export function Unverified({ toggle, modalState, user, type }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const focusButtonRef = useRef(null);
  const { t, i18n } = useTranslation('translation');

  const handleVerificationEmailRequest = async (user) => {
    const userData = new Object();
    userData.email = user;
    try {
      setLoading(true);
      await publicFetch.post('users/verify_request/', userData);

      setTimeout(() => {
        setLoading(false);
        setSuccess(true);
      }, 500);
    } catch (error) {
      console.log(error);
      setError('Something went wrong, please try again.');
      setLoading(false);
      setSuccess(null);
    }
  };

  return (
    <Transition appear show={modalState} as={Fragment}>
      <Dialog
        initialFocus={focusButtonRef}
        as="div"
        className="overflow-y-auto fixed inset-0 z-10"
        open={modalState}
        onClose={() => toggle(false)}
      >
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-50" />
        <div className="px-4 min-h-screen text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block overflow-hidden p-6 my-8 w-full max-w-md text-left align-middle bg-white rounded-2xl shadow-xl transition-all">
              {success ? null : (
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  Email Not Verified
                </Dialog.Title>
              )}
              <div className="mt-2">
                {success ? (
                  <div className="flex flex-col justify-center items-center ">
                    <div className="p-4 mb-3 bg-green-100 rounded-full">
                      <Icon name="send" />
                    </div>
                    <p className="text-sm text-center text-gray-500">
                      A verification email has been sent
                      to <strong>{user}</strong>.
                    </p>
                  </div>
                ) : (
                  <p className="text-sm text-gray-500">
                    Your email address{' '}
                    <strong>{user}</strong> has not been
                    verified, please go verify or request a
                    new verification email.
                  </p>
                )}
              </div>

              <div className="flex justify-items-stretch mt-4">
                <button
                  ref={success ? focusButtonRef : null}
                  type="button"
                  className="inline-flex flex-1 justify-center py-2 px-4 w-full text-base font-medium text-gray-700 bg-white hover:bg-gray-50 rounded-full border border-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 shadow-sm sm:w-auto sm:text-sm"
                  onClick={() => {
                    toggle(false);
                    setSuccess(false);
                  }}
                >
                  Close
                </button>
                {success ? null : (
                  <button
                    ref={!success ? focusButtonRef : null}
                    type="button"
                    disabled={!!loading}
                    className="inline-flex flex-1 justify-center py-2 px-4 ml-0 w-full text-base font-medium text-white bg-indigo-700 hover:bg-indigo-800 rounded-full border border-transparent focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 shadow-sm disabled:cursor-not-allowed  sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() =>
                      handleVerificationEmailRequest(user)
                    }
                  >
                    {loading ? <Spinner size={6} /> : 'Resend email'}
                  </button>
                )}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}

export function CommonModal({ toggle, modalState, user, type, title, description, actionText, actionButtonVarient, cancelText, successText, actionAction }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const focusButtonRef = useRef(null);
  const { t, i18n } = useTranslation('translation');

  return (
    <Transition appear show={modalState} as={Fragment}>
      <Dialog
        initialFocus={focusButtonRef}
        as="div"
        className="overflow-y-auto fixed inset-0 z-10"
        open={modalState}
        onClose={() => toggle(false)}
      >
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-50" />
        <div className="px-4 min-h-screen text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block overflow-hidden p-6 my-8 w-full max-w-md text-left align-middle bg-white rounded-2xl shadow-xl transition-all">
              {success ? null : (
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  {title}
                </Dialog.Title>
              )}
              <div className="mt-2">
                {success ? (
                  <div className="flex flex-col justify-center items-center ">
                    <div className="p-4 mb-3 bg-green-100 rounded-full">
                      <Icon
                        name="send"
                        className="text-green-600"
                      />
                    </div>
                    <p className="text-sm text-center text-gray-500">
                      {successText}
                      to <strong>{user}</strong>.
                    </p>
                  </div>
                ) : (
                  <p className="text-sm text-gray-500">
                    {/* Your email address{' '}
                                        <strong>{user}</strong> has not been
                                        verified, please go verify or request a
                                        new verification email. */}
                    {description}
                  </p>
                )}
              </div>

              <div className="flex justify-items-stretch mt-4">
                <button
                  ref={success ? focusButtonRef : null}
                  type="button"
                  className="inline-flex flex-1 justify-center py-2 px-4 w-full text-base font-medium text-gray-700 bg-white hover:bg-gray-50 rounded-full border border-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 shadow-sm sm:w-auto sm:text-sm"
                  onClick={() => {
                    toggle(false);
                    setSuccess(false);
                  }}
                >
                  {cancelText}
                </button>
                {success ? null : (
                  <button
                    ref={!success ? focusButtonRef : null}
                    type="button"
                    disabled={!!loading}
                    className="inline-flex flex-1 justify-center py-2 px-4 ml-0 w-full text-base font-medium text-white bg-red-700 hover:bg-red-800 rounded-full border border-transparent focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 shadow-sm disabled:cursor-not-allowed  sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => {
                      actionAction()
                    }
                    }
                  >
                    {loading ? <Spinner size={6} /> : `${actionText}`}
                  </button>
                )}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}

import React from 'react';
import { useInfiniteQuery } from '@tanstack/react-query';
import { FetchContext } from '../context/FetchContext';

export function useGenres() {
    const fetchContext = React.useContext(FetchContext);
    return useInfiniteQuery(
        ['genres'],
        ({ pageParam = 1 }) =>
            fetchContext.authAxios(`/content/genres/?page=${pageParam}`),
        {
            getPreviousPageParam: (firstPage) => firstPage.data,
            getNextPageParam: (lastPage, page) => {
                if (lastPage.data._next === null) return undefined;
                return lastPage.data._next.charAt(
                    lastPage.data._next.length - 1
                );
            },
            keepPreviousData: true,
        }
    );
}

import PropTypes from 'prop-types';
import React from 'react';
import parseUrl from 'url-parse';
import cn from 'classnames';

import { ButtonRound } from 'components/common/Button';
import { useSwipeable } from 'react-swipeable';
import { useNavigate } from 'react-router-dom';
import { Transition } from '@headlessui/react';
import { IMG_URI } from 'utils/constants';
import { Icon } from './icons/icon.export';

export default function FeaturedCarousel({ items, loading, success }) {
  const navigate = useNavigate();

  if (loading) {
    return (
      <div className="flex flex-col justify-center items-start px-10 mb-10 space-y-2 w-full h-[50vh] max-h-[50vh] bg-gray-900 animate-pulse sm:h-[70vh] sm:max-h-[70vh]">
        <div className="w-1/12 h-5 bg-gray-800/50 rounded-full" />
        <div className="w-7/12 h-10 bg-gray-800/50 rounded-full" />
        <div className="w-3/12 h-10 bg-gray-800/50 rounded-full" />
      </div>
    );
  }

  return (
    <Transition
      appear
      show={success}
      enter="transition-opacity duration-1000 ease-in-out"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-150"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      className="mb-10"
    >
      <FeaturedSwipeableCarousel
        title="Carousel"
        loading={loading}
        success={success}
        autoplay
        delay={10000}
        className="overflow-hidden"
      >
        {items?.map((item, i) => (
          <FeaturedCarouselItem key={i}>
            <div className="overflow-hidden relative w-full h-full max-h-[70vh] bg-gray-950/30 ">
              <Transition.Child
                enter="transition-opacity transition ease-in-out delay-300 duration-300"
                enterFrom="opacity-0 "
                enterTo="opacity-100 "
                as={React.Fragment}
              >
                <div className="flex absolute inset-0 z-10 flex-col justify-center pl-10 space-y-5 max-w-prose whitespace-pre-line   ">
                  {' '}
                  <div className="py-1 px-2 max-w-fit text-xs tracking-widest text-gray-200 uppercase bg-gray-950/90 rounded-full">
                    {item.content_model}
                  </div>
                  <div className="text-2xl font-bold text-gray-100 md:text-5xl">
                    {item.title}
                  </div>
                  <ButtonRound
                    onClick={() => {
                      const { pathname } = parseUrl(
                        item._detail || ''
                      );
                      const path = pathname.replace(
                        '/v2/content',
                        ''
                      );
                      navigate(path);
                    }}
                    className="self-start py-4 px-4"
                    size="rnd"
                    IconStart={<Icon name="play" size={24} weight='fill' />}
                  />
                </div>
              </Transition.Child>

              <div className="absolute inset-y-0 left-0 w-9/12 bg-gradient-to-r from-gray-950  " />
              <div className="absolute inset-x-0 -bottom-px h-1/2 bg-gradient-to-t from-gray-950" />
              <img
                src={item.images.feature}
                alt={item.title}
                className="object-cover object-right-bottom w-full h-full"
                onError={(e) => {
                  e.target.src = IMG_URI,
                    e.target.style.width = '648px',
                    e.target.style.height = '364px'
                }}
              />
            </div>
          </FeaturedCarouselItem>
        ))}
      </FeaturedSwipeableCarousel>
    </Transition>
  );
}

FeaturedCarousel.propTypes = {
  // items: PropTypes.shape({
  //   map: PropTypes.func,
  // }),
  loading: PropTypes.bool,
  success: PropTypes.bool,
};

const FeaturedCarouselItem = ({ children, width }) => (
  <div
    className="inline-flex max-h-[50vh] text-white bg-gray-700 "
    style={{ width }}
  >
    {children}
  </div>
);

FeaturedCarouselItem.propTypes = {
  width: PropTypes.any,
};

const FeaturedSwipeableCarousel = ({
  children,
  delay = 10000,
  className,
  autoplay,
}) => {
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [paused, setPaused] = React.useState(false);

  const numItems = React.Children.count(children);

  const updateIndex = (i) => {
    let newIndex = i;
    if (newIndex < 0) {
      newIndex = numItems - 1;
    } else if (newIndex >= numItems) {
      newIndex = 0;
    }
    setActiveIndex(newIndex);
  };

  React.useEffect(() => {
    if (autoplay) {
      const interval = setInterval(() => {
        if (!paused) {
          updateIndex(activeIndex + 1);
        }
      }, delay);
      return () => {
        if (interval) {
          clearInterval(interval);
        }
      };
    }
  });

  const handlers = useSwipeable({
    onSwipedLeft: () => updateIndex(activeIndex + 1),
    onSwipedRight: () => updateIndex(activeIndex - 1),
    preventScrollOnSwipe: true,
    trackMouse: true,
  });

  return (
    <div
      {...handlers}
      className={cn(className)}
      onMouseEnter={() => setPaused(true)}
      onMouseLeave={() => setPaused(false)}
    >
      <div
        className="whitespace-nowrap transition-transform duration-1000 ease-in-out"
        style={{ transform: `translateX(-${activeIndex * 100}%)` }}
      >
        {React.Children.map(children, (child, index) =>
          React.cloneElement(child, { width: '100%' })
        )}
      </div>
      <div className="float-right mr-4">
        {React.Children.map(children, (child, index) => (
          <button
            type="button"
            className={`${index === activeIndex
                ? 'bg-indigo-500'
                : 'bg-white/50'
              } mr-2 last:mr-0 w-2 h-2 hover:bg-indigo-500 focus:bg-indigo-500 rounded-full`}
            onClick={() => {
              updateIndex(index);
            }}
          >
            {' '}
          </button>
        ))}
      </div>
    </div>
  );
};

FeaturedSwipeableCarousel.propTypes = {
  autoplay: PropTypes.bool,
  autoplayDelay: PropTypes.number,
  // children: PropTypes.arrayOf(PropTypes.object),
  className: PropTypes.string,
  delay: PropTypes.number,
};

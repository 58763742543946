import React from 'react';
import { useQuery, useInfiniteQuery } from '@tanstack/react-query';
import parseUrl from 'url-parse';
import { PAGE_SIZE, STALE_TIME } from 'utils/constants';
import { FetchContext } from 'context/FetchContext';

export function useCollectionByPk(pk) {
    const fetchContext = React.useContext(FetchContext);
    return useQuery(['collection', pk], () =>
        fetchContext
            .authAxios(`/content/collections/${pk}/`)
            .then((res) => res.data)
    );
}

export function useCollectionByPkItems(pk, success) {
    const fetchContext = React.useContext(FetchContext);

    return useInfiniteQuery(
        ['collection', pk, 'items'],
        ({ pageParam = 1, pageSize = PAGE_SIZE }) =>
            fetchContext.authAxios(
                `/content/collections/${pk}/collectionitems/?page=${pageParam}&page_size=${pageSize}`
            ),
        {
            getPreviousPageParam: (firstPage, page) => {
                if (firstPage.data._previous === null) return undefined;
                const { query } = parseUrl(firstPage.data._previous, true);
                return query.page;
            },
            getNextPageParam: (lastPage, pages) => {
                if (lastPage.data._next === null) return undefined;
                const { query } = parseUrl(lastPage.data._next, true);
                return query.page;
            },
            keepPreviousData: true,
            staleTime: STALE_TIME,
            enabled: success,
        }
    );
}

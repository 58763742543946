import React, { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { AuthContext } from 'context/AuthContext';
import { UserContext } from 'context/UserContext';
import { publicFetch } from 'utils/fetch';
import { useNavigate, useLocation } from 'react-router-dom';
import FormError from 'components/FormError';
import FormSuccess from 'components/FormSuccess';
import { Navlink } from 'components/common/Hyperlink';
import Label from 'components/common/Label';
import { SecureTextInput, TextInput } from 'components/TextInput';
import { ButtonRound } from 'components/common/Button';
import Checkbox from 'components/common/Checkbox';
import Card from 'components/common/Card';
import qs from 'qs';
import Footer from 'components/Footer';
import axios from 'axios';
import { APP_NAME } from 'utils/constants';
// import Verify from '../../page/signup/Verify'
import { Unverified } from 'components/Modal';
import logo from '../../images/logo.svg';
import { useDocumentTitle } from "@uidotdev/usehooks";

const siteKey = import.meta.env.VITE_APP_SITE_KEY;

const LoginSchema = Yup.object().shape({
    username: Yup.string()
        .email('Email must be valid')
        .required('Email is required'),
    password: Yup.string().required('Password is required'),
});

const Login = () => {
    const authContext = useContext(AuthContext);
    const userContext = useContext(UserContext)
    const navigate = useNavigate();
    const { state } = useLocation();
    const from = state?.from?.pathname || '/';

    const [loginSuccess, setLoginSuccess] = useState();
    const [loginError, setLoginError] = useState();
    const [loginLoading, setLoginLoading] = useState(false);
    const [redirectOnLogin, setRedirectOnLogin] = useState(false);
    const [redirectOnUnverified, setRedirectOnUnverified] = useState(false);
    const [toggleModal, setToggleModal] = useState(false);
    const [userEmail, setUserEmail] = useState('');

    useDocumentTitle(`Signin | ${APP_NAME}`);

    const submitCredentials = async (credentials) => {
        try {
            setLoginError('');
            setLoginLoading(true);

            const authData = new Object();
            authData.auth = null;
            authData.user = null;

            const tokenData = await publicFetch // First Request - get auth token
                .post('auth/token/', qs.stringify(credentials), {
                    headers: {
                        // Override header content type
                        'content-type': 'application/x-www-form-urlencoded',
                    },
                });
            authData.auth = tokenData.data;
            setUserEmail(credentials.username);

            const userData = await axios // Second Request - user lookup
                .get(`${import.meta.env.VITE_APP_API_URL}/users/lookup/`, {
                    headers: {
                        Authorization: `Bearer ${tokenData.data.access_token}`,
                    },
                })
                .then((response) => {
                    if (response.data.state === '100') {
                        // throw error and go to catch block
                        // Logging on Development
                        if (import.meta.env.NODE_ENV === 'development')
                            console.info('Unverified Account ', response.data);
                        // Delete the tokens
                        authContext.deleteAccessToken();
                        // Throw error message to that sets modal to verify.
                        throw new Error('unverified');
                    } else {
                        // proceed.....
                        // Logging on Development
                        if (import.meta.env.NODE_ENV === 'development')
                            console.info('Verified Account', response.data);

                        authData.user = response.data;
                        authContext.setAuthState(authData);
                        //get the current device
                        axios.get(
                            `${import.meta.env.VITE_APP_API_URL}/users/${response.data.pk}/account/devices/current/`,
                            {
                                headers: {
                                    Authorization: `Bearer ${tokenData.data.access_token}`,
                                },
                            }
                            //set the current device to user state
                            //response.data.ip_country
                        ).then((response) => {

                            const userData = new Object();
                            userData.user = response.data.ip_country;

                            axios.get(
                                `${import.meta.env.VITE_APP_API_URL}/users/${authData.user.pk}/account/profile/`,
                                {
                                    headers: {
                                        Authorization: `Bearer ${tokenData.data.access_token}`,
                                    },
                                }
                            ).then((response) => {
                                userData.tier = response.data.access_tier
                                userContext.setUserData(userData)
                            })
                        });
                    }
                });

            // authData.user = userData.data
            // authContext.setAuthState(authData) //Pass authData object to auth Context
            // await axios
            // .get(`${import.meta.env.VITE_APP_API_URL}/users/${userData.data.pk}/account/devices/current/`, {
            //     headers: {
            //         'Authorization': `Bearer ${tokenData.data.access_token}`
            //     },
            // })

            setLoginSuccess('Signin successful', tokenData.message);
            setLoginError('');

            setTimeout(() => {
                // timeout is here to show the login success message
                setRedirectOnLogin(true);
            }, 500);
            navigate(from, { replace: true });
        } catch (error) {
            if (import.meta.env.NODE_ENV === 'development') {
                console.log(
                    'Error Message ',
                    error.message,
                    'Error Response ',
                    error.response
                );
            }

            setLoginLoading(false);
            const { status } = error.response || error.message;
            if (error.message === 'unverified') {
                setTimeout(() => {
                    // timeout is here to show the modal
                    setToggleModal(true);
                }, 100);
            }
            if (status === 401) {
                // Custom error message for incorrect authentication credentails.
                setLoginError(
                    'Wrong email or password, make sure to use a correct email and password combination.'
                );
            }
            setLoginSuccess(null);
        }
    };

    const { t, i18n } = useTranslation('translation');

    return (
        <section className="flex relative flex-col justify-center items-center p-8 w-full h-screen sm:pt-10 " data-testid='signin'>
            <Unverified
                user={userEmail}
                modalState={toggleModal}
                toggle={setToggleModal}
            />
            <Card>
                <div className="text-gray-400">
                    <div className="m-auto mb-10 w-32">
                        <img src={logo} alt="Logo" />
                    </div>
                    <h2 className="mb-2 text-2xl font-bold leading-9 text-center text-gray-100">
                        {t('login.welcome')}
                    </h2>
                    <p className="text-sm text-center">
                        {t('login.no-account')}{' '}
                        <Navlink
                            to="/signup"
                            text={t('login.sign-up')}
                        />
                    </p>
                </div>
                <Formik
                    enableReinitialize
                    initialValues={{
                        grant_type: 'password',
                        client_id: import.meta.env.VITE_APP_CLIENT_ID,
                        username: '',
                        password: '',
                    }}
                    onSubmit={(values) => submitCredentials(values)}
                    validationSchema={LoginSchema}
                >
                    {() => (
                        <Form className="mt-8">
                            {loginSuccess && (
                                <FormSuccess text={loginSuccess} />
                            )}
                            {loginError && (
                                <FormError text={loginError} />
                            )}

                            <div className="flex flex-col space-y-6">
                                <div className="">
                                    <div className="flex">
                                        <Label
                                            className="mb-2"
                                            text={t('login.e-label')}
                                        />
                                    </div>
                                    <TextInput
                                        ariaLabel="Email address"
                                        name="username"
                                        type="email"
                                        autocomplete="username"
                                        placeholder={t(
                                            'login.e-placeholder'
                                        )}
                                    />
                                </div>

                                <div className="">
                                    <div className="flex justify-between">
                                        <Label
                                            className="mb-2"
                                            text={t('login.p-label')}
                                        />
                                        <Navlink
                                            to="/reset-password"
                                            text={t('login.forgot')}
                                            className="text-xs text-indigo-500"
                                        />
                                    </div>

                                    <SecureTextInput
                                        ariaLabel="Password"
                                        name="password"
                                        autocomplete="current-password"
                                        placeholder={t(
                                            'login.p-placeholder'
                                        )}
                                    />
                                </div>
                            </div>

                            {/* <div  className="mt-6">

                    <Checkbox
                    name="remember"
                    id="remember"

                    value="true"
                    />
                    <Label
                    htmlFor="remember"
                    text={t('login.remember')}
                    className="ml-2"
                    />
                </div> */}

                            <div className="mt-6">
                                <ButtonRound
                                    type="submit"
                                    className="self-start space-x-2 w-full"
                                    size="rnd"
                                    text="Sign in"
                                    loading={loginLoading}
                                    // onClick={() => {
                                    //   if (localStorage.get('tref')) {
                                    //     navigate.location({
                                    //       state: { from: localStorage.getItem('tref') },
                                    //     });
                                    //   }
                                    // }}
                                />
                            </div>
                        </Form>
                    )}
                </Formik>
            </Card>

            <Footer />
        </section>
    );
};

export default Login;

import { createContext, useState } from 'react';

export const UserContext = createContext({} as any)


export const UserProvider = ({ children }: any) => {
	const tier = localStorage.getItem('tier');
	const country = localStorage.getItem('country');

	const [userData, setUserData] = useState({
		tier,
		country,
	});

	function transformTier(tier: string) {
		if (tier === '0_FREE') return 'free';
		if (tier === '1_DONOR') return 'donor';
		if (tier === '2_GRANTED') return 'granted';
		if (tier === '3_ADMIN') return 'admin';
		return 'free';
	}


	const setUser = ({ tier = '0_FREE', country = 'CA' }) => {
		localStorage.setItem('tier', transformTier(tier));
		localStorage.setItem('country', country.toLowerCase());

		setUserData({
			tier: transformTier(tier),
			country: country.toLowerCase(),
		});
	}

	const isFree = () => {
		return tier === '0_FREE';
	}

	const isDonor = () => {
		return tier === '1_DONOR';
	}

	const isGranted = () => {
		return tier === '2_GRANTED';
	}



	return (
		<UserContext.Provider
			value={{
				userData,
				setUserData: (userInfo: any) => setUser(userInfo),
				country,
				tier,
				isFree,
				isDonor,
				isGranted,
				transformTier

			}}>
			{children}
		</UserContext.Provider>
	)
};

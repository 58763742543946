import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { FetchContext } from 'context/FetchContext';
import { STALE_TIME } from 'utils/constants';

export function useLivestreams() {
    const fetchContext = React.useContext(FetchContext);
    return useQuery(['livestreams'], () =>
        fetchContext.authAxios('/content/livestreams/?ordering=-recent'),
        {
            keepPreviousData: true,
            staleTime: STALE_TIME
        }
    );
}

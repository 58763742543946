import React from 'react';
import PropTypes from 'prop-types';
import parseUrl from 'url-parse';
import { useNavigate, useMatch } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { Icon } from './icons/icon.export';
import Progress from './Progress';

import { PlayerContext } from '../context/PlayerContext';

import { IMG_URI } from 'utils/constants';

// Placeholder component
const Placeholder = ({children}) => (
    <div className="_PLACEHOLDER overflow-hidden relative after:absolute after:inset-0 after:hover:bg-gray-800/30 rounded after:transition after:duration-100 after:ease-in">
      <img
        src={IMG_URI}
        alt="Image with Corco+ logo "
        className="w-full h-full"
        />
    </div>
  );

// Thumbnail Types
// Movie, Show, Season, Episode, Topic, Livestream, Watchlist

export default function Thumbnail({
    content_model: contentModel,
    title,
    image,
    overlay_text: overlayText,
    progress,
    onClick,
    style,
    _detail,
    className,
    _play,
    children,
    growOnHover,
    ...props
}) {
    const navigate = useNavigate();
    const { pathname } = parseUrl(_detail || '');
    const path = pathname.replace('/v2/content', '');
    const searchRoute = useMatch('/search');


    const playerContext = React.useContext(PlayerContext);

    function handleThumbnailClick() {
        // Do something if coming from /search
        if (searchRoute){
            navigate(path);
        } else if (contentModel && contentModel === 'episode') {
            playerContext.setVideoSrc(_play);
            playerContext.setTitle(title);
        } else if (contentModel && contentModel === 'saveactivity') {
            const savePathname = parseUrl(props.content_object._detail);
            const savePath = savePathname.pathname.replace('/v2/content', '');
            navigate(savePath);
        } else {
            navigate(path);
        }
    }

    function handleInfoClick() {
        navigate(path);
    }

    return (
        <div className={`flex relative flex-col w-full  ${growOnHover ? 'transition duration-300 hover:scale-105' : ''}`}>
            <div className={`cursor-pointer group overflow-hidden relative rounded ${className ? className : ''}`} >

                 {/* {!image ? (
                    <Placeholder/>
                 ):( */}
                    <>
                        <div
                            tabIndex={0}
                            role="button"
                            onKeyDown={handleThumbnailClick}
                            onClick={handleThumbnailClick}
                            className="overflow-hidden relative after:absolute after:inset-0 after:hover:bg-gray-800/30 rounded after:transition after:duration-100 after:ease-in"
                        >
                            <img
                                src={!image ? IMG_URI : image}
                                alt={title}
                                className="w-full h-full"
                                onError={(e) => e.target.src = IMG_URI}
                            />
                            {/* {children} */}
                        </div>
                        {contentModel && contentModel === 'episode' ? (
                            <div className="group flex absolute right-2 bottom-8 z-10 justify-between items-end">
                                <button
                                    type="button"
                                    tabIndex={0}
                                    onKeyDown={handleInfoClick}
                                    onClick={handleInfoClick}
                                    title="Episode Details"
                                >
                                    <div className="z-10 bg-black/50 hover:bg-black/70 rounded-full  opacity-0 group-hover:opacity-100 transition duration-200 ease-in hover:scale-105 ">
                                        <Icon
                                            name="info"
                                            alt="info"
                                            size={24}
                                            className="text-gray-300 opacity-0 group-hover:opacity-100 transition duration-100 ease-in  "
                                        />
                                    </div>
                                </button>
                            </div>
                        ) : null}

                        {overlayText && (
                            <div className="flex absolute inset-x-0 bottom-0 justify-center items-center py-1 text-xs tracking-wide text-gray-100 uppercase bg-indigo-700/90">
                                {overlayText}
                            </div>
                        )}
                    </>
                {/* )} */}
                {/* : (
                    overlayText && (
                        <div className="flex absolute inset-x-0 bottom-0 justify-center items-center h-1/6 text-gray-100 uppercase bg-indigo-700">
                            {overlayText}
                        </div>
                    )
                )} */}

            </div>
            {children}
            {!!progress && <Progress progress={progress} />}
        </div>
    );
}

Thumbnail.propTypes = {
    _detail: PropTypes.string,
    _play: PropTypes.any,
    className: PropTypes.any,
    content_model: PropTypes.string,
    image: PropTypes.any,
    onClick: PropTypes.any,
    overlay_text: PropTypes.any,
    progress: PropTypes.any,
    style: PropTypes.any,
    title: PropTypes.any,
    children: PropTypes.any,
    growOnHover: PropTypes.bool,
};

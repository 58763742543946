import React from 'react';
import { FetchContext } from 'context/FetchContext';
import { useQuery } from '@tanstack/react-query';
import { PAGE_SIZE_ROW, STALE_TIME } from 'utils/constants';

export function usePlayActivity(pk) {
    const fetchContext = React.useContext(FetchContext);

    return useQuery(
        ['playactivity'],
        ({
            ordering = '-play_end_date',
            pageSize = PAGE_SIZE_ROW,
            startPointMin = 0.01,
            endPointMax = 0.95,
        }) =>
            fetchContext
                .authAxios(
                    `/users/${pk}/activity/playactivity/?page_size=${pageSize}&ordering=${ordering}&content_model=episode&content_model=movie&play_percentage_min=${startPointMin}&play_percentage_max=${endPointMax}`
                )
                .then((res) => res.data),
        {
            keepPreviousData: true,
            staleTime: STALE_TIME,
        }
    );
}

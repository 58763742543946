import PropTypes from 'prop-types';
import React from 'react';
import { useWindowSize } from 'hooks/useWindowSize';
import { Carousel, CarouselItem } from './CollectionCarousel';
import { Spinner } from './common';
import LiveStreamButton from './LiveStreamButton';

export default function LiveStreamsRow({
    livestreams,
    loading,
    isError,
    error,
}) {
    const size = useWindowSize();

    let NUM_SLIDES;
    if (size.width < 640) NUM_SLIDES = 4;
    if (size.width > 768) NUM_SLIDES = 4;
    if (size.width > 1024) NUM_SLIDES = 6;
    if (size.width > 1280) NUM_SLIDES = 8;

    if (loading)
        return (
            <div className="flex justify-center items-center w-full h-screen ">
                <Spinner size={8} />
            </div>
        );

    if (isError)
        return (
            <div className="flex justify-center items-center w-full h-screen text-gray-500 ">
                <p>{`Error: ${error.message}`}</p>
            </div>
        );

    return (
        <div className="mb-10 w-full">
            <h3 className="mb-4 ml-[3vw] font-semibold text-gray-100">
                Livestreams
            </h3>
            <Carousel slideNumber={NUM_SLIDES}>
                {livestreams.data.results.map((livestream, i) => (
                    <CarouselItem
                        key={livestream.pk}
                        index={i}
                        growOnHover
                        slideNumber={NUM_SLIDES}
                    >
                        <LiveStreamButton
                            {...livestream}
                            slideNumber={NUM_SLIDES}
                        >
                            {livestream.title}
                        </LiveStreamButton>
                    </CarouselItem>
                ))}
            </Carousel>

            {/* <div className="flex overflow-x-scroll ">{loading ? renderPlaceholders() : renderLiveStreams()}</div> */}
        </div>
    );
}

LiveStreamsRow.propTypes = {
    error: PropTypes.shape({
        message: PropTypes.any,
    }),
    isError: PropTypes.any,
    livestreams: PropTypes.shape({
        data: PropTypes.shape({
            results: PropTypes.any,
        }),
    }),
    loading: PropTypes.any,
};

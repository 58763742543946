import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { FetchContext } from 'context/FetchContext';
import { STALE_TIME } from 'utils/constants';

export function useFeaturedContent() {
    const fetchContext = React.useContext(FetchContext);
    return useQuery(['featured'], () =>
        fetchContext
            .authAxios(`/content/featured/?is_kids=false`)
            .then((res) => res.data.results),
            {
                keepPreviousData: true,
                staleTime: STALE_TIME
            }
    );
}

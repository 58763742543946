import React, { useState, useEffect, useContext } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { ButtonRound } from 'components/common/Button';

// Custom Hooks
import { useTopicByPk } from 'api/useTopicByPk';

// Icons
import { Spinner } from 'components/common';
import VideoPlayer from 'components/VideoPlayer';
import { APP_NAME } from 'utils/constants';
import DetailBlock from 'components/DetailBlock';
import Episode from 'components/Episode';
import { PlayerContext } from 'context/PlayerContext';
import { useDocumentTitle } from '@uidotdev/usehooks';

function TopicDetail({ props }) {
    const { pk: topic_pk } = useParams();
    const [playUrl, setPlayUrl] = useState('');
    const [playData, setPlayData] = useState({});
    const { data, isFetching, isLoading, isError, error } =
        useTopicByPk(topic_pk);
    const queryClient = useQueryClient();
    const [showVideo, setShowVideo] = useState(false);

      // for new player component
    const playerContext = useContext(PlayerContext);

    useDocumentTitle(`Topic ${data?.title} | ${APP_NAME}`);

    if (isLoading)
        return (
            <div className="flex justify-center items-center w-full h-screen ">
                <Spinner size={8} />
            </div>
        );
    if (isError)
        return (
            <div className="flex justify-center items-center w-full h-screen text-gray-500 ">
                <p>Error: {error.message}</p>
            </div>
        );

    return (
        <>
            <VideoPlayer
                title={playerContext.title}
                playUrl={playerContext.videoSrc}
                onDismiss={() => {
                    setPlayUrl(playerContext.setVideoSrc(null));
                    queryClient.refetchQueries(['playactivity']);
                }}
            />

            <div className="overflow-y-scroll px-[3vw] -mt-20 pt-20 h-full ">
                <div className="flex flex-col w-full">
                    <DetailBlock
                        data={data}
                        playUrl={data._play}
                        setShowVideo={setShowVideo}
                        page="topic"
                    />
                </div>
                <div className="flex flex-col mt-20">
                    {isLoading ? (
                        <div className="flex justify-center items-center w-full h-full ">
                            <Spinner color="#4C51BF" size={6} />
                        </div>
                    ) : isError ? (
                        <p>Error: {error.message}</p>
                    ) : (
                        <div className="overflow-y-auto mt-10 w-full">
                            {data.episodes.results.map((item, index) => (
                                <Episode
                                    key={item.pk}
                                    pk={item.pk}
                                    videoSrc={item._play}
                                    episodeNumber={item.overlay_text}
                                    image={item.images.episode}
                                    show={item.season.show.title}
                                    title={item.title}
                                    description={item.description}
                                    length={item.length}
                                    progress={item.activity.play_activity?.play_end_point_percentage || 0}
                                    saveActivity={item.activity.save_activity}
                                    associatedMeta={item.overlay_text.toUpperCase()}
                                    detailLink={item._detail}
                                    associatedEpisodeLink={item._detail}
                                    associatedSeasonLink={item.season._detail}
                                    associatedShowLink={item.season.show._detail}
                                    associatedTitle={item.season.show.title}
                                    contentType='topic'
                                />

                            ))}
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

export default TopicDetail;

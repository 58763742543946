import React, { useState, useEffect, useContext } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { ButtonRound } from 'components/common/Button';
import { PlayerContext } from 'context/PlayerContext';

// Query Hooks
import { useShowByPk } from 'api/useShowByPk';
import { useSeasonEpisodes } from 'api/useSeasonEpisodes';
// Custom Hooks

// Icons
import { Spinner } from 'components/common';
import VideoPlayer from 'components/VideoPlayer';

// utils
import { APP_NAME } from 'utils/constants';
import DetailBlock from 'components/DetailBlock';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';
import Episode from 'components/Episode';
import { Icon } from 'components/icons/icon.export';
import { useDocumentTitle } from "@uidotdev/usehooks";

export default function ShowDetail({ props }) {
  const { show_pk, season_pk, episode_pk } = useParams();
  const [playUrl, setPlayUrl] = useState('');

  // for new player component
  const playerContext = useContext(PlayerContext);


  const [type, setType] = useState(null);


  const { data, isFetching, isLoading, isError, error } = useShowByPk(show_pk);
  const queryClient = useQueryClient();
  const [showVideo, setShowVideo] = useState(false);
  // const [currentEpisode, setCurrentEpisode] = useState(undefined)

  console.table([['Show', show_pk], ['Season', season_pk], ['Episode', episode_pk]])

  const [currentSeason, setCurrentSeason] = useState(undefined);

  useEffect(() => {
    const currentSeason = data?.seasons.results.find((season) => {
      if (season_pk === undefined) {
        return season;
      }

      return season.pk.toString() === season_pk;
    });
    setCurrentSeason(currentSeason);
  }, [data]);

  const episodeData = useSeasonEpisodes(show_pk, currentSeason, data);

  const [currentEpisode, setCurrentEpisode] = useState(undefined);

  useEffect(() => {
    const currentEpisode = episodeData.data?.pages.map((page) =>
      page.data.results.find((episode) => {
        if (!episode_pk) {
          return episode.is_current;
        }

        return episode.pk.toString() === episode_pk;
      })
    );
    if (currentEpisode !== undefined) {
      setCurrentEpisode(currentEpisode);
    }
  }, [episodeData.data, episode_pk]);

  const [seasonActivity, setSeasonActivity] = useState(null);

  useDocumentTitle(`Watch ${data?.title} | ${APP_NAME}`);


  function isCurrent(data) {
    return data.current === true;
  }

  function handleShowPlayClick() {
    playerContext.setTitle(data.title)
    playerContext.setVideoSrc(currentEpisode[0]._play);
  }

  // console.log('CS', currentSeason, 'CE', currentEpisode)

  const getPlayButtonName = (cSeason, cEpisode) => {
    if (data) {
      if (show_pk && season_pk && episode_pk && cSeason && cEpisode) {
        return `Play S.${cSeason?.season_number}: Ep.${cEpisode[0]?.episode_number}`;
      }
      if (show_pk && season_pk) {
        return `Play S.${cSeason?.season_number}`;
      }
      if (cEpisode?.episode_number && cSeason?.season_number) {
        return `Play S.${cSeason?.season_number}: Ep.${cEpisode[0]?.episode_number}`;
      }
      return 'Play';
    }
  };

  const loadMoreButtonRef = React.useRef();

  useIntersectionObserver({
    target: loadMoreButtonRef,
    onIntersect: episodeData.fetchNextPage,
    enabled: episodeData.hasNextPage,
  });


  if (isLoading || episodeData.isLoading)
    return (
      <div className="flex justify-center items-center w-full h-screen ">
        <Spinner size={8} />
      </div>
    );

  if (isError || episodeData.isError)
    return (
      <div className="flex justify-center items-center w-full h-screen text-gray-500 ">
        <p>{`Error: ${episodeData.isError ? episodeData.error.message : error.message}`}</p>
      </div>
    );

  return (
    <>
      <VideoPlayer
        title={playerContext.title}
        playUrl={playerContext.videoSrc}
        type={type}
        onDismiss={() => {
          setPlayUrl(playerContext.setVideoSrc(null));
          queryClient.refetchQueries(['playactivity']);
          //Refetch to update the playactivity
          queryClient.invalidateQueries(['show', show_pk, 'season', currentSeason?.season_number, 'episodes']);
        }}
      />

      <div className="overflow-y-scroll px-[3vw] -mt-20 pt-20 h-full ">
        <div className="flex flex-col w-full">
          <DetailBlock data={data} playUrl={data._play} setShowVideo={setShowVideo} page="show" />
          <div className="flex space-x-4">
            <div>
              <ButtonRound
                onClick={handleShowPlayClick}
                className="self-start space-x-2 py-3 px-4 text-xl"
                size="rnd"
                IconStart={<Icon name="play" size={24} weight='fill' />}
              >
                {getPlayButtonName(currentSeason, currentEpisode)}{' '}
              </ButtonRound>
            </div>
            {/* <ButtonRound
              onClick={() => clickWatchlistButton()}
              //   data.activity.save_activity === null
              //     ? saveWatchlistMutation.mutate(movie_pk)
              //     : deleteWatchlistMutation.mutate(movie_pk);
              // }}

              className="self-start"
              size="rnd"
              varient="plain"
              IconStart={
                saveWatchlistMutation.isLoading || deleteWatchlistMutation.isLoading ? (
                  <Spinner size={8}  />
                ) : data.activity.save_activity === null ? (
                  <Plus size={24} width={2} />
                ) : (
                  <Check size={24} width={2} />
                )
              }
            /> */}
          </div>
        </div>
        <div className="flex flex-col mt-20">
          <Seasons
            showPk={show_pk}
            seasons={data?.seasons.results}
            seasonNumber={currentSeason?.season_number}
            currentSeason={currentSeason}
            setCurrentSeason={setCurrentSeason}
            setSeasonActivity={setSeasonActivity}
          // playUrl={(content) => {
          // const playUrl = `${content._detail}play/`;
          // }}
          />
        </div>

        {/*

                <Select
                 data={selectData}
                 defaultSelect={defaultSelect}
                 onSelect={(value) => setDefaultSelect(value) }
                 />
            */}
        {/* {/* </div> */}
      </div>
    </>
  );
}

function Seasons({
  showPk,
  seasons,
  seasonNumber,
  currentSeason,
  setCurrentSeason,
  setSeasonActivity,
  onPlay,
}) {
  useEffect(() => {
    const currentSeason = seasons.find((season) => {
      if (season.is_current === true) {
        return season;
      }
    });
    setCurrentSeason(currentSeason);
  }, [seasons]);

  const loadMoreButtonRef = React.useRef();

  useIntersectionObserver({
    target: loadMoreButtonRef,
    onIntersect: seasons.fetchNextPage,
    enabled: seasons.hasNextPage,
  });

  const queryClient = useQueryClient();
  return (
    <>
      <div className="flex flex-col">
        <div className="flex space-x-2">
          {seasons.map((season, i) => (
            <div
              key={i}
              onClick={() => {
                setCurrentSeason(season);
                queryClient.invalidateQueries(['show', showPk, 'season', season.pk, 'episodes']);
              }}
              className={`rounded-full ${season !== currentSeason
                  ? 'bg-gray-800  hover:bg-gray-700 hover:text-gray-100'
                  : 'bg-gray-700 hover:bg-gray-800 text-gray-100 hover:text-gray-400'
                } py-2 px-4  cursor-pointer`}
            >
              Season {season.season_number}
            </div>
          ))}
        </div>
        <Season
          showPk={showPk}
          seasonPk={currentSeason}
          setSeasonActivity={setSeasonActivity}
          onPlay={onPlay}
          currentSeason={currentSeason}
        />
      </div>
    </>
  );
}

function Season({ showPk, seasonPk, currentSeason }) {
  const { data, isLoading, isError, error, fetchNextPage, hasNextPage, isFetchingNextPage } = useSeasonEpisodes(
    showPk,
    currentSeason
  );


  useEffect(() => {
    if (data && currentSeason) {
      console.log('Season Pk', seasonPk)
      console.log('Season Pk', currentSeason.pk)
    }
    console.log('component updated', data)
  }, [data, currentSeason]);

  const loadMoreButtonRef = React.useRef();

  useIntersectionObserver({
    target: loadMoreButtonRef,
    onIntersect: fetchNextPage,
    enabled: hasNextPage,
  });
  const queryClient = useQueryClient();

  if (isLoading)
    return (
      <div className="flex justify-center items-center w-full h-full ">
        <Spinner size={8} />
      </div>
    );

  if (isError)
    return (
      <div className="flex justify-center items-center w-full h-full text-gray-500 ">
        <p>Error: {error.message}</p>
      </div>
    );

  return (
    <>
      <div className="overflow-y-auto mt-10 w-full">
        {data?.pages.map((group, i) => (
          <React.Fragment key={i}>
            {group.data.results.map((item, i) => (
              <Episode
                key={item.pk}
                pk={item.pk}
                episodeNumber={item.episode_number}
                videoSrc={item._play}
                image={item.images.episode}
                title={item.title}
                description={item.description}
                length={item.length}
                progress={item.activity.play_activity?.play_end_point_percentage || 0}
                saveActivity={item.activity.save_activity}
                detailLink={item._detail}
                contentType='show'
                captions={item.has_captions}
              />
            ))}
          </React.Fragment>
        ))}
        <div className="flex justify-center items-center py-10 text-gray-500">
          <button ref={loadMoreButtonRef} onClick={() => fetchNextPage()} disabled={!hasNextPage || isFetchingNextPage}>
            {isFetchingNextPage ? (
              <div className="flex justify-center items-center w-full">
                <Spinner size={8} />
              </div>
            ) : hasNextPage ? (
              'Load more'
            ) : (
              ''
            )}
          </button>
        </div>
      </div>
    </>
  );
}

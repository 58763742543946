import { Icon } from 'components/icons/icon.export';
import React from 'react';
import { Link } from 'react-router-dom';
import { APP_NAME } from 'utils/constants';

export default function FourOFour() {
  return (
    <div id="notfound">
      <div className="flex flex-col justify-center items-center space-y-6 h-screen bg-gray-900 space-between">
        <div className="flex items-baseline space-x-3 text-white space-between ">
          <h1 className="text-9xl font-black text-gray-800 ">404</h1>
          <h2 className="text-4xl italic text-gray-800 ">Page not found</h2>
        </div>

        <Link
          to="/"
          className="group flex relative justify-center py-2 px-4 text-sm font-medium text-white disabled:text-gray-800 bg-indigo-700 active:bg-blue-700 disabled:bg-gray-700 rounded-md focus:border-blue-700 focus:outline-none shadow-lg transition duration-100 ease-in-out bg-gradient focus:shadow-outline-blue"
        >
          {APP_NAME} Home
        </Link>
        <Link
          to={-1} replace
          className="group flex relative justify-center py-2 px-4 text-sm font-medium text-white disabled:text-gray-800  focus:outline-none shadow-lg transition duration-100 ease-in-out bg-gradient focus:shadow-outline-blue"
        >
          <Icon name='arrowLeft' /> Go Back
        </Link>
      </div>
    </div>
  );
}

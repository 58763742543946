import React from "react";
import ReactDOM from "react-dom/client";
import "./i18n";
import App from "./app";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import { FlagsProvider } from "flagged";

import "@fontsource/nunito/latin-300.css";
import "@fontsource/nunito/latin-400.css";
import "@fontsource/nunito/latin-600.css";
import "@fontsource/nunito/latin-700.css";
import "@fontsource/nunito/latin-800.css";

// CSS main sheet
import "./index.css";

posthog.init(import.meta.env.VITE_APP_PUBLIC_POSTHOG_KEY, {
  api_host: import.meta.env.VITE_PUBLIC_POSTHOG_HOST,
});


ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <PostHogProvider client={posthog}>
      {/* <App /> */}
      <FlagsProvider
        features={{
          sideBarSearchFlag: false,
          sideBarWatchlistFlag: true,
        }}
      >
        <App />
      </FlagsProvider>
    </PostHogProvider>
  </React.StrictMode>
);

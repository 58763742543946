import React from 'react';
import { useInfiniteQuery } from '@tanstack/react-query';
import parseUrl from 'url-parse';
import { PAGE_SIZE, STALE_TIME } from 'utils/constants';
import { FetchContext } from '../context/FetchContext';

export function useTopics(queryParam) {
    const fetchContext = React.useContext(FetchContext);
    return useInfiniteQuery(
        [queryParam, 'topics'],
        ({
            pageParam = 1
        }) =>
            fetchContext.authAxios(
                `/content/topics/${
                    queryParam ? `${queryParam}&` : ''
                  }page=${pageParam}&page_size=20`,
            ),
        {
            getPreviousPageParam: (firstPage) => firstPage.results,
            getNextPageParam: (page) => {
              if (page.data._next) {
                const { query } = parseUrl(page.data._next, true);
                return query.page;
              }
            },
            keepPreviousData: true,
        }
    );
}

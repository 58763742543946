// import React from "react";
// import { FetchContext } from "../context/FetchContext";
// import { useInfiniteQuery } from "@tanstack/react-query";
// import parseUrl from "url-parse";
// import { PAGE_SIZE_ROW } from "utils/constants";

// export function useCollectionRowByPk(pk) {
//   const fetchContext = React.useContext(FetchContext);
//   return useInfiniteQuery(
//     ["collectionitem", pk],
//     ({ pageParam = 1, ordering = "position", pageSize = PAGE_SIZE_ROW }) =>
//       fetchContext.authAxios(
//         `/content/collections/${pk}/collectionitems/?page=${pageParam}&ordering=${ordering}&page_size=${pageSize}`
//       ),
//     {
//       getPreviousPageParam: (firstPage, page) => {
//         if (firstPage.data._previous === null) return undefined;
//         const { query } = parseUrl(firstPage.data._previous, true);
//         return query.page;
//       },
//       getNextPageParam: (lastPage, page) => {
//         if (lastPage.data._next === null) return undefined;
//         const { query } = parseUrl(lastPage.data._next, true);
//         return query.page;
//       },
//       keepPreviousData: true,
//     }
//   );
// }
import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { PAGE_SIZE_ROW, STALE_TIME } from 'utils/constants';
import { FetchContext } from 'context/FetchContext';

export function useCollectionRowByPk(pk) {
    const fetchContext = React.useContext(FetchContext);
    return useQuery(
        ['collectionitem', pk],
        ({ pageParam = 1, ordering = 'position', pageSize = PAGE_SIZE_ROW }) =>
            fetchContext
                .authAxios(
                    `/content/collections/${pk}/collectionitems/?page=${pageParam}&ordering=${ordering}&page_size=${pageSize}`
                )
                .then((res) => res.data),
                {
                    keepPreviousData: true,
                    staleTime: STALE_TIME
                }
    );
}

import React from 'react';

import { useQuery } from '@tanstack/react-query';
import { FetchContext } from '../context/FetchContext';
import { UserContext } from '../context/UserContext';



export function useSearchComplete(query, renderSuggestions) {
    const fetchContext = React.useContext(FetchContext);
    const userContext = React.useContext(UserContext);
    //Get the user info from the user context
    const { country: COUNTRY, tier } = userContext.userData;
    // Transform granted and admin to 'donor' since the api only accepts this donor or free
    const ACCESS_TIER = tier === 'granted' | tier === 'admin' ? 'donor' : tier;


    return useQuery({
        queryKey: ['searchComplete', query],
        queryFn: ({ region = COUNTRY, tier = ACCESS_TIER }) => fetchContext
            .searchAxios(`/complete/?query=${query}&region=${region}&tier=${tier}`)
                .then((res) => res.data.data),

            enabled: renderSuggestions(query),

    });
}

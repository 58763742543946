import React from 'react';
import { FetchContext } from 'context/FetchContext';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export function useSaveWatchlist(pk) {
    const fetchContext = React.useContext(FetchContext);
    const queryClient = useQueryClient();

    return useMutation(
        (url) =>
            fetchContext.authAxios(`${url}save/`)
                .catch((err) => console.error(err)),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(pk);
                queryClient.invalidateQueries('saveactivity');
                queryClient.invalidateQueries('watchlist');
            },
        }
    );
}

export function useDeleteWatchlist(pk) {
    const fetchContext = React.useContext(FetchContext);
    const queryClient = useQueryClient();

    return useMutation(
        (url) =>
            fetchContext.authAxios({
                method: 'DELETE',
                url: `${url}activity/saveactivity/`,
            }),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(pk);
                queryClient.invalidateQueries('saveactivity');
                queryClient.invalidateQueries('watchlist');

            },
        }
    );
}

import React, { useContext, useEffect, useState, useRef } from 'react';
import ReactModal from 'react-modal';
import parseUrl from 'url-parse';
import { FetchContext } from '../context/FetchContext';
import { PlayerContext } from '../context/PlayerContext';
import { BitmovinPlayer } from './player/BitmovinPlayer';
import { Icon } from './icons/icon.export';


type VideoPlayerProps = {
    livestream: any;
    playUrl: string;
    onDismiss: () => void;
    className?: string;
    title: string;
    isLive?: boolean;
};

type PlayActivity = {
    play_point_seconds: number;
    play_end_point_seconds: number | null;
    play_session_count: number;
}


export default function VideoPlayer({
    livestream,
    playUrl,
    onDismiss,
    className,
    title,
    isLive,
    ...props
}:VideoPlayerProps) {

    const playerContext = useContext(PlayerContext);
    const [videoUrl, setVideoUrl] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [playActivityUrl, setPlayActivityUrl] = useState('');
    const [playActivity, setPlayActivity] = useState<PlayActivity>({} as PlayActivity);
    const [currentSeconds, setCurrentSeconds] = useState(0);

    const fetchContext = useContext(FetchContext);

    const playerContainerRef = useRef<any>(null);

    const childRef = useRef<any>(null);



    /*
     * Called after component is mounted, in useEffect to
     * get and set the video and activity urls.
     */
    const playVideo = async (url: string) => {
        const { pathname: a } = parseUrl(url);
        const playUrlPath = a.replace('/v2/', '/');

        // Get the data from the parsed playUrlPath - that is using the hook - useUrlParser
        const result: {data:{_play_activity: string, link: string}} = await fetchContext.authAxios(playUrlPath);


        // Get the play activity url from the parsed playUrlPath - that is using the hook - useUrlParser
        const { pathname: b } = parseUrl(result.data._play_activity);
        const playActivityPath = b.replace('/v2/', '/');



        // Get the data from the parsed playActivityPath - via hook - useUrlParser

        const playActivityResult: {data: PlayActivity} = await fetchContext.authAxios(playActivityPath)
        console.log(playActivityResult.data)

        // Set the video and activity url
        setPlayActivityUrl(playActivityPath);
        setPlayActivity(playActivityResult.data);
        setVideoUrl(result.data.link);
        if (
            playActivityResult.data.play_end_point_seconds &&
            playActivityResult.data.play_end_point_seconds > 10
        ) {
            console.log('Seek To', playActivity, `, current play point in seconds: ${Number(playActivity.play_end_point_seconds) - 5}`);
            setCurrentSeconds(Number(playActivityResult.data.play_end_point_seconds) - 5);    
        };
        setShowModal(true);
    };

    useEffect(() => {
        if (playUrl) {
            playVideo(playUrl)
        }
        return () => {
            playerContext.setVideoSrc(null);
        };
    }, [playUrl]);

    useEffect(() => {
        onReady();
    }, [playActivity]);


    /*
     * Called when media is loaded and ready to play.
     * If playing is set to true, media will play immediately
     */
    const onReady = async () => {

        if (
            playActivity.play_end_point_seconds &&
            playActivity.play_end_point_seconds > 10
        ) {
            console.log('Seek To', playActivity, `, current play point in seconds: ${playActivity.play_end_point_seconds - 5}`);
            setCurrentSeconds(playActivity.play_end_point_seconds - 5);
        };
    };

    const putSession = async (value: number) => {
        await fetchContext.authAxios({
            method: 'PUT',
            url: playActivityUrl,
            data: {
                play_point_seconds: Math.floor(value) || 0,
            },
        });
    };

    const progressSession = async (value: number) => {
        await fetchContext.authAxios({
            method: 'PATCH',
            url: playActivityUrl,
            data: {
                play_point_seconds: Math.floor(value),
            },
        });
    }

    // On Hide - Closes the player and gets fires off the onDismiss() functions passed from the parent.
    const onClose = async () => {
        onDismiss();
        setShowModal(false);
        playerContext.destroyPlayer();
    };

    const onSeeked = async (playSeconds: any) => {
        console.log('On seeked', playSeconds);
        putSession(playSeconds as number);
    };

    /*
    Do nothing
    if player is seeking
    if no session count present
    */
    const trackProgress = async (playSeconds: any) => {

        console.log('Tracking Progress', playSeconds)
        if (playActivity &&
            playActivity.play_session_count &&
            playActivity.play_session_count < 1) return;
            
        progressSession(playSeconds as number);
    };


    return (
        <ReactModal
            closeTimeoutMS={500}
            isOpen={showModal}
            onRequestClose={onClose}
            portalClassName={'___portal '}
            overlayClassName="___overlay fixed inset-0 bg-black z-50"
            className="fixed inset-0 bg-black ___class focus-visible:outline-none focus:outline-none"
            ariaHideApp={false}
        >
            <div className="absolute inset-0 ___container pt-aspect ">
                <div
                    ref={playerContainerRef}
                    className="relative w-full min-h-screen max-h-screen _PlayerWrapper flex items-center justify-center"
                >
                    <button className="absolute top-0 right-0 text-white p-2" onClick={onClose}><Icon name="close" size={40} weight="fill"/></button>
                    <BitmovinPlayer
                        url={videoUrl}
                        title={title}
                        currentPlayPoint={currentSeconds}
                        destoryPlayer={showModal}
                        onSeeked={onSeeked}
                        trackProgress={trackProgress}
                    />

                 </div>
             </div>
         </ReactModal>
    );
}
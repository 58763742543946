import PropTypes from 'prop-types';
import React from 'react';
import { Icon } from './icons/icon.export';
import { useContext } from 'react';
import { PlayerContext } from 'context/PlayerContext';
import { IMG_URI } from 'utils/constants';

// TODO - scaling is a little janky on resize.

function debounce(fn, ms) {
    let timer;
    return (_) => {
        clearTimeout(timer);
        timer = setTimeout((_) => {
            timer = null;
            fn.apply(this, arguments);
        }, ms);
    };
}



export default function LiveStreamButton({
    onClick,
    _detail,
    _play,
    title,
    style,
    children,
    thumbnail,
    images,
    slideNumber,
    content_model: contentModel,
    ...props
}) {
    const [playUrl, setPlayUrl] = React.useState(null);
    const resizeTarget = React.useRef();
    const [dimensions, setDimensions] = React.useState({
        height: undefined,
        width: undefined,
    });

    const [initSize, setInitSize] = React.useState(false);

    const playerContext = useContext(PlayerContext);

    React.useEffect(() => {
        const target = resizeTarget.current;
        const debouncedHandleResize = debounce(() => {
            setDimensions({
                height: target.offsetWidth,
                width: target.offsetWidth,
            });
        }, 500);

        if (!initSize) {
            setInitSize(true)
            debouncedHandleResize.call()
        }

        window.addEventListener('resize', debouncedHandleResize);

        return () => {
            window.removeEventListener('resize', debouncedHandleResize);
        };
    }, [dimensions]);

    function handleThumbnailClick() {
        playerContext.setIsLive(true);
        playerContext.setVideoSrc(_play);
        playerContext.setTitle(title);

    }

    return (
        <div className="flex flex-col w-full" ref={resizeTarget}>
            <div className="overflow-hidden relative h-auto rounded cursor-pointer">
                {images && images.thumbnail && (
                    <div
                        tabIndex={0}
                        role="button"
                        onKeyDown={handleThumbnailClick}
                        onClick={handleThumbnailClick}
                        className="_LivestreamThumbnail"
                    >
                        <div
                            className={`rounded-full relative group flex items-center justify-center overflow-hidden bg-cover bg-center bg-no-repeat after:absolute after:inset-0 after:hover:bg-gray-800/80  after:transition after:duration-100 after:ease-in `}
                            // style={{ backgroundImage: `url(${images.thumbnail})` }}
                        >
                            <img
                                src={images.thumbnail}
                                alt={title}
                                className="object-cover w-full transition-all duration-500 ease-out"
                                style={{ height: dimensions.width }}
                                onError={(e) => e.target.src = IMG_URI}
                            />
                            <Icon
                                name="play"
                                weight="fill"
                                alt="play"
                                className="  absolute z-10 w-[32px] h-[32px] text-white/80 hover:text-white hover:shadow-white/80 opacity-0 group-hover:opacity-100 transition-transform duration-200 ease-in-out hover:scale-150"
                            />
                        </div>
                        {children && (
                            <h4 className="pt-6 text-xs tracking-wider text-center text-gray-100 uppercase md:text-sm ">
                                {children}
                            </h4>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
}

LiveStreamButton.propTypes = {
    _detail: PropTypes.any,
    _play: PropTypes.any,
    children: PropTypes.any,
    images: PropTypes.shape({
        thumbnail: PropTypes.any,
    }),
    onClick: PropTypes.any,
    style: PropTypes.any,
    thumbnail: PropTypes.any,
    title: PropTypes.any,
    slideNumber: PropTypes.number,
};

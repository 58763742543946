import React, { Children } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Spinner from './Spinner';

const mapBaseSize = {
    sm: 'px-2 py-2 text-sm',
    base: 'px-4 py-2 text-base rounded-md',
    rnd: 'px-2 py-2 text-base rounded-full',
    lg: 'px-4 py-2 text-lg',
    xl: 'px-6 py-4 text-lg',
};

function ButtonBase(props) {
    const {
        className,
        size = 'base',
        as = 'button',
        children,
        IconStart,
        IconEnd,
        text,
        loading,
        ...restProps
    } = props;

    const Element = as;

    return (
        <Element
            {...restProps}
            className={cn(
                `group relative flex items-center justify-center font-medium transition duration-100 ease-in-out focus:outline-none focus:ring-1 focus:ring-offset-1`,
                mapBaseSize[size],
                className
            )}
        >
            <span>{IconStart}</span>
            {loading ? (
                <span className="flex items-center">
                    <Spinner size={6} />
                </span>
            ) : (


                     <span> {text} </span>



            )}
            {children}
            <span>{IconEnd}</span>
        </Element>
    );
}

export function ButtonSolid(props) {
    const { varient = 'primary',  size, className, ...restProps } = props;

    const mapSolidVariant = {
        primary: `text-white bg-indigo-700 hover:bg-indigo-600 active:bg-indigo-700 focus:bg-indigo-800 focus:ring-1 ring-indigo-700 ring-opacity-7 disabled:bg-gray-200 disabled:text-gray-500`,
        danger: `text-white bg-red-700 `,
        plain: 'bg-gray-900/60 focus:shadow-outline text-white border border-gray-700',
    };
    return (
        <ButtonBase
            {...restProps}
            size={size}
            className={cn(
                `shadow-md disabled:shadow-md hover:shadow-lg`,
                mapSolidVariant[varient],
                className
            )}
        />
    );
}

export function ButtonRound(props) {
    const { varient = 'primary', size= 'rnd', className, ...restProps } = props;

    const mapSolidVariant = {
        primary: `text-white bg-indigo-700 hover:bg-indigo-600 active:bg-indigo-700 focus:bg-indigo-800 disabled:cursor-not-allowed`,
        danger: `text-white bg-red-700 `,
        plain: 'bg-gray-900/60 focus:shadow-outline text-white border border-gray-700 hover:bg-gray-700',
    };
    return (
        <ButtonBase
            {...restProps}
            size={size}
            className={cn(
                `shadow-md disabled:shadow-md hover:shadow-lg`,
                mapSolidVariant[varient],
                className
            )}
        />
    );
}

import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { FetchContext } from 'context/FetchContext';
import { STALE_TIME } from 'utils/constants';

export function useLivestreamByPk(pk) {
    const fetchContext = React.useContext(FetchContext);

    return useQuery(['livestream', pk], () =>
        fetchContext
            .authAxios(`/content/livestreams/${pk}/`)
            .then((res) => res.data),
            {
                keepPreviousData: true,
                staleTime: STALE_TIME
            }
    );
}

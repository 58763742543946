import { Suspense, useEffect, useContext } from "react";
import {
  createBrowserRouter,
  Outlet,
  useLocation,
  Routes,
  Route,
  Navigate,
  redirect,
} from "react-router-dom";

// contexts
import { AuthContext } from "context/AuthContext";
// pages
import Home from "page/home/Home";
import { FourOFour } from "page/404";
import Login from "page/login/Login";
import { ResetPassword, ResetSuccess, ResetConfirm } from "page/resetPassword";
import { Signup, VerifyRequest, VerifySuccess } from "page/signup";
import { MasterLayout, CommonLayout, PublicLayout } from "components/layouts";
import TrailerDetail from "page/Trailer";
import Spinner from "components/common/Spinner.tsx";
import ErrorPage from "error-page";

// pages lazy
import Discover from "page/discover/Discover";
import Movies from "page/movie/Movies";
import MovieDetail from "page/movie/MovieDetail";
import Shows from "page/show/Shows";
import ShowDetail from "page/show/ShowDetail";
import Topic from "page/topic/topic";
import TopicDetail from "page/topic/TopicDetail";
import Kid from "page/kid/Kid";
import CollectionDetail from "page/CollectionDetail";
import LivestreamDetail from "page/LivestreamDetail";
import Account from "page/setting/Account";
import General from "page/setting/General";
import SearchMovies from "page/search/Search";
import WatchList from "page/watchlist/WatchList";
import Verify from "page/signup/Verify";
// const TrailerDetail = lazy(() => import('page/Trailer'));

import posthog from "posthog-js";

export const LoadingFallback = () => (
  <MasterLayout>
    <div className="flex h-screen w-full flex-col items-center justify-center text-gray-500">
      <Spinner size={8} />
    </div>
  </MasterLayout>
);

// Redirection when trying to access /login etc when already authenticated
const PublicRoutes = () => {
  const auth = useContext(AuthContext);
  const location = useLocation();
  return auth.isAuthenticated() ? (
    <Navigate to="/discover" state={{ from: location }} replace />
  ) : (
    <Outlet />
  );
};
const PreviewRoutes = () => {
  const auth = useContext(AuthContext);
  const location = useLocation();
  return auth.isAuthenticated() ? (
    <Navigate to="/trailer/:id" state={{ from: location }} replace />
  ) : (
    <Outlet />
  );
  // Authenticated -> /trailer/:id - Don't show Signin - Show back button to (Go to Show Button)
  // Not Authenticated -> /trailer/:id - Signin & Signup Button
};

// Redirecting to Signup or Initial Route on Login
const PrivateRoutes = () => {
  const auth = useContext(AuthContext);
  const location = useLocation();
  return auth.isAuthenticated() ? (
    <Outlet />
  ) : (
    <Navigate to="/signin" state={{ from: location }} replace />
  );
};

// const auth = useAuthContext()
// console.log(auth.isAuthenticated)

export const router = createBrowserRouter([
  {
    element: <CommonLayout />,
    errorElement: <ErrorPage />,
    loader() {
      return { user: auth.isAuthenticated };
    },
    children: [
      {
        path: "/",
        element: <PublicRoutes />,
        errorElement: <ErrorPage />,
        children: [
          { path: "/", element: <Navigate to="/signin" /> },
          { path: "/signin", element: <Login /> },
          { path: "/login", element: <Navigate to="/signin" /> },
          { path: "/signup", element: <Signup /> },
          { path: "/verify", element: <Verify /> },
          { path: "/verify/:uid/:token", element: <VerifySuccess /> },
          { path: "/browse/:uid/:token", element: <VerifySuccess /> },
          { path: "verify-request", element: <VerifyRequest /> },
          { path: "reset-password", element: <ResetPassword /> },
          {
            path: "reset-password-confirm/:uid/:token",
            element: <ResetConfirm />,
          },
          { path: "reset-password-success", element: <ResetSuccess /> },
        ],
      },
    ],
  },
  {
    path: "/",
    element: <MasterLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <PrivateRoutes />,
        errorElement: <ErrorPage />,
        children: [
          { path: "discover", element: <Discover /> },
          { path: "movies", element: <Movies /> },
          { path: "/movie/:pk", element: <MovieDetail /> },
          { path: "/movies/:pk", element: <MovieDetail /> },
          { path: "/shows", element: <Shows /> },
          { path: "/show/:show_pk", element: <ShowDetail /> },
          { path: "/shows/:show_pk", element: <ShowDetail /> },
          {
            path: "/shows/:show_pk/seasons/:season_pk",
            element: <ShowDetail />,
          },
          {
            path: "/shows/:show_pk/seasons/:season_pk/episodes/:episode_pk",
            element: <ShowDetail />,
          },
          { path: "/kids", element: <Kid /> },
          { path: "/topics", element: <Topic /> },
          { path: "/topic/:pk", element: <TopicDetail /> },
          { path: "/topics/:pk", element: <TopicDetail /> },
          { path: "/livestream/:pk", element: <LivestreamDetail /> },
          { path: "/livestreams/:pk", element: <LivestreamDetail /> },
          { path: "/collection/:pk", element: <CollectionDetail /> },
          { path: "/collections/:pk", element: <CollectionDetail /> },
          { path: "/settings/account", element: <Account /> },
          { path: "/settings/general", element: <General /> },
          { path: "/search", element: <SearchMovies /> },
          { path: "/watchlist", element: <WatchList /> },
          { path: "/test", element: <Home /> },
        ],
      },
    ],
  },
  {
    path: "/",
    element: <PublicLayout />,
    errorElement: <ErrorPage />,
    children: [{ path: "trailer/:pk", element: <TrailerDetail /> }],
  },
  { path: "*", element: <FourOFour /> },
  {
    path: "/logout",
    async action() {
      await auth.logout();
      return redirect("/");
    },
  },
]);

export function Root() {
  const location = useLocation();
  useEffect(() => {
    posthog.capture("$pageview");
  }, [location]);

  return (
    <Suspense fallback={<LoadingFallback />}>
      <Routes>
        {/* AUTHENTICATED ROUTES START */}
        <Route element={<MasterLayout />} errorElement={<ErrorPage />}>
          <Route element={<PrivateRoutes />}>
            <Route index path="/discover" element={<Discover />} />
            <Route exact path="/movies" element={<Movies />} />
            {/* Movie Details */}
            <Route path="/movie" element={<MovieDetail />} />
            <Route exact path="/movie/:pk" element={<MovieDetail />} />
            <Route exact path="/movies/:pk" element={<MovieDetail />} />
            {/* Movie Details */}
            <Route exact path="/shows" element={<Shows />} />
            {/* Shows */}
            <Route exact path="/show/:show_pk" element={<ShowDetail />} />
            <Route exact path="/shows/:show_pk" element={<ShowDetail />} />
            <Route
              exact
              path="/shows/:show_pk/seasons/:season_pk"
              element={<ShowDetail />}
            />
            <Route
              exact
              path="/shows/:show_pk/seasons/:season_pk/episodes/:episode_pk"
              element={<ShowDetail />}
            />
            {/* Shows */}
            <Route exact path="/kids" element={<Kid />} />
            <Route exact path="/topics" element={<Topic />} />
            <Route exact path="/topic/:pk" element={<TopicDetail />} />
            <Route exact path="/topics/:pk" element={<TopicDetail />} />
            {/* Livestreams */}
            <Route
              exact
              path="/livestream/:pk"
              element={<LivestreamDetail />}
            />
            <Route
              exact
              path="/livestreams/:pk"
              element={<LivestreamDetail />}
            />
            {/* Livestreams */}
            <Route
              exact
              path="/collection/:pk"
              element={<CollectionDetail />}
            />
            <Route
              exact
              path="/collections/:pk"
              element={<CollectionDetail />}
            />
            <Route exact path="/settings/account" element={<Account />} />
            <Route exact path="/settings/general" element={<General />} />
            <Route exact path="/search" element={<SearchMovies />} />
            <Route exact path="/watchlist" element={<WatchList />} />
            <Route
              exact
              path="/collection/:pk"
              element={<CollectionDetail />}
            />
          </Route>
        </Route>
        {/* UNAUTHENTICATED ROUTES START */}
        <Route path="/" element={<CommonLayout />}>
          <Route element={<PublicRoutes />}>
            <Route path="/" element={<Navigate to="/signin" />} />
            <Route path="login" element={<Navigate to="/signin" />} />
            <Route index path="signin" element={<Login />} />
            <Route path="signup" element={<Signup />} />
            <Route path="verify" element={<Verify />} />
            <Route path="verify/:uid/:token" element={<VerifySuccess />} />
            <Route path="browse/:uid/:token" element={<VerifySuccess />} />
            <Route path="verify-request" element={<VerifyRequest />} />
            <Route path="reset-password" element={<ResetPassword />} />
            <Route
              path="reset-password-confirm/:uid/:token"
              element={<ResetConfirm />}
            />
            <Route path="reset-password-success" element={<ResetSuccess />} />
          </Route>
        </Route>
        <Route path="/" element={<PublicLayout />}>
          {/* <Route element={<PreviewRoutes />}> */}
          <Route path="trailer/:pk" element={<TrailerDetail />} />
          {/* </Route> */}
        </Route>
        <Route path="*" element={<FourOFour />} />
      </Routes>
    </Suspense>
  );
}

// React Protected Routes and Role Based Routing
// https://youtu.be/oUZjO00NkhY

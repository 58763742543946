import React, { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { AuthContext } from 'context/AuthContext';
import { publicFetch } from 'utils/fetch';
import FormError from 'components/FormError';
import FormSuccess from 'components/FormSuccess';
import Footer from 'components/Footer';
import { Navlink } from 'components/common/Hyperlink';
import Label from 'components/common/Label';
import { SecureTextInput, TextInput } from 'components/TextInput';
import Checkbox from 'components/common/Checkbox';
import { ButtonRound } from 'components/common/Button';
import Card from 'components/common/Card';
import { APP_NAME } from 'utils/constants';
import { Icon } from 'components/icons/icon.export';
import Verify from './Verify';
import logo from '../../images/logo.svg';
import { useDocumentTitle } from '@uidotdev/usehooks'


const siteKey = import.meta.env.VITE_APP_SITE_KEY;

const SignupSchema = Yup.object().shape({
  first_name: Yup.string().required('First name is required'),
  last_name: Yup.string().required('Last name is required'),
  email: Yup.string().email('Email must be a valid email address').required('Email is required'),
  password: Yup.string()
    .min(8, 'Password must be at least 8 characters')
    .required('Password is required')
    // Testing if contains only numbers
    .matches(/(?!^\d+$)^.+$/, 'Password cannot be entirely numeric'),
  newsLetterSignup: Yup.boolean(),
});

const Signup = () => {
  const authContext = useContext(AuthContext);
  const [signupSuccess, setSignupSuccess] = useState(false);
  const [signupError, setSignupError] = useState(false);
  const [loginLoading, setLoginLoading] = useState(false);
  const [currentUserEmail, setCurrentUserEmail] = useState('');
  const [redirectOnSuccess, setRedirectOnSuccess] = useState(false);

  useDocumentTitle(`Signup - ${APP_NAME}`);

  const submitCredentials = async (credentials) => {
    try {
      setLoginLoading(true);
      const signupUser = await publicFetch.post(`users/signup/`, credentials);
      setCurrentUserEmail(signupUser.data.email);
      if (signupUser.status === 201) {
        setSignupSuccess('Account has been created');
      }
      setTimeout(() => {
        setRedirectOnSuccess(true);
      }, 700);
    } catch (error) {
      setLoginLoading(false);
      const { status, data } = error.response;
      if (status === 400 && data.non_field_errors) {
        setSignupError(data.non_field_errors);

        // setTimeout(() => { // timeout is here to show the login success message
        // 	 setRedirectOnUnverified(true)
        // }, 500);
      }

      // TODO: set errors for wrong passwords.

      // {"password":"This password is entirely numeric."]}
      // {"password":"This password is too short."]}
      // {"email":["Active unverified user. (resend?)."]}

      // setSignupError(

      // 	data.email,
      // 	data.password

      // );
    }
  };

  const [revealDetail, setRevealDetail] = React.useState(false);

  const { t, i18n } = useTranslation('translation');
  return (
    <>
      <section className="flex relative flex-col justify-center items-center p-8 w-full h-screen sm:pt-10" data-testid='signup'>
        {redirectOnSuccess ? (
          <Verify user={currentUserEmail} />
        ) : (
          <Card>
            <div className="text-gray-400">
              <div className="m-auto mb-10 w-32">
                <img src={logo} alt="Logo" />
              </div>
              <h2 className="mb-2 text-2xl font-bold leading-9 text-center text-gray-100">
                {t('signup.welcome')}
              </h2>
              <p className="text-sm text-center">
                {t('signup.have-account')}{' '}
                <Navlink to="/signin" text={t('signup.sign-in')} />
              </p>
            </div>
            <Formik
              initialValues={{
                first_name: '',
                last_name: '',
                email: '',
                password: '',
                newsLetterSignup: false,
              }}
              onSubmit={(values) => submitCredentials(values)}
              validationSchema={SignupSchema}
            >
              {({ isSubmitting, dirty, isValid }) => (
                <Form className="mt-8">
                  {signupSuccess && <FormSuccess text={signupSuccess} />}
                  {signupError && <FormError text={signupError} />}
                  <input type="hidden" name="remember" value="true" />
                  <div className="flex flex-col space-y-6">
                    <div className="">
                      <div className="mb-1">
                        <Label text={t('signup.name-label')} />
                      </div>
                      <div className="flex">
                        <div className="flex-col flex-1 mr-3">
                          <TextInput
                            ariaLabel={t('signup.f-name-placeholder')}
                            name="first_name"
                            type="text"
                            autocomplete="given-name"
                            placeholder={t('signup.f-name-placeholder')}
                          />
                        </div>
                        <div className="flex-col flex-1">
                          <TextInput
                            ariaLabel={t('signup.l-name-placeholder')}
                            name="last_name"
                            type="text"
                            autocomplete="family-name"
                            placeholder={t('signup.l-name-placeholder')}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="-">
                      <div className="mb-1">
                        <Label text={t('signup.e-label')} />
                      </div>
                      <TextInput
                        ariaLabel={t('signup.e-placeholder')}
                        name="email"
                        type="email"
                        autocomplete="username"
                        placeholder={t('signup.e-placeholder')}
                      />
                    </div>

                    <div>
                      <div className="flex items-center mb-1">
                        <Label text={t('signup.p-label')} />
                        <div
                          className="flex justify-center items-center ml-1 w-4 h-4 text-xs font-bold text-gray-500 rounded-full cursor-pointer"
                          onClick={() => setRevealDetail(!revealDetail)}
                        >
                          <Icon name="info" size={12} title="Password Requirements" />{' '}
                        </div>
                      </div>
                      <SecureTextInput
                        ariaLabel="Your memorable password"
                        name="password"
                        type="password"
                        autocomplete="new-password"
                        placeholder="Your memorable password"
                        details={revealDetail}
                      />
                    </div>
                  </div>

                  {/* <div className="flex">
                    <Checkbox name="newsLetterSignup" id="newsLetterSignup" />
                    <Label
                      htmlFor="newsLetterSignup"
                      className="ml-2"
                      text={t("forms.newsletter.main")}
                    >
                      <div className="font-light">{t("forms.newsletter.secondary")}</div>
                    </Label>
                  </div> */}

                  <div className="mt-6">
                    <ButtonRound
                      type="submit"
                      className="self-start space-x-2 w-full"
                      size="rnd"
                      text={t('button.sign-up')}
                      loading={loginLoading}
                      disabled={isSubmitting || !dirty || !isValid}
                    />
                  </div>
                </Form>
              )}
            </Formik>
          </Card>
        )}
        <Footer />
      </section>
    </>
  );
};

export default Signup;

import { useContext, useState }  from 'react';
import { BottomBanner } from 'components/notifications/BannerNotification';
import { Outlet } from 'react-router-dom';
import { Sidebar } from '../Sidebar';
import Header from '../Header';
import Content from '../Content';
import { useInterval } from 'hooks/useInterval';
import { POLLING_INTERVAL } from 'utils/constants';

import { FetchContext } from 'context/FetchContext';
import { AuthContext } from 'context/AuthContext';
import { UserContext } from 'context/UserContext';
import { useMediaQuery } from '@uidotdev/usehooks';
import { Icon } from 'components/icons/icon.export';


export default function MasterLayout() {
    const auth = useContext(AuthContext);
    const fetch = useContext(FetchContext);
    const user = useContext(UserContext)
    const { user_info } = auth.authState;
    const person = user_info ? JSON.parse(user_info) : {};
    const isMobile = useMediaQuery("only screen and (max-width : 768px)")
    const [mobileOpen, setMobileOpen] = useState(true);

    //Polling for new device, location and tier change
    useInterval(async () => {
        const userData = new Object();
        userData.country = null;
        userData.tier = null;

        const { data: country } = await fetch.authAxios(`users/${person.pk}/account/devices/current/`);

        if ( user.country !== country.ip_country.toLowerCase() ) {
            userData.country = country.ip_country;
            console.log('Setting Country to ' + country.ip_country);
        } else {
            console.log('Country is the same');
        }

        const { data: tier } = await fetch.authAxios(`users/${person.pk}/account/profile/`);

        if( user.tier !== user.transformTier(tier.access_tier)) {
            userData.tier = tier.access_tier;
            // user.setUserData({...user.userData, tier: tier.access_tier})
            console.log('Setting Tier to ' + tier.access_tier);
        } else {
            console.log('Tier is the same');

        }
    }, POLLING_INTERVAL);

    const toggleMobileMenu = () => {
        setMobileOpen(!mobileOpen)
    }

    return (
        <div>
            <div className="flex flex-col h-screen ">
                <div className="flex overflow-y-scroll flex-1">
                    <Sidebar isMobile={isMobile} />
                    <main className="overflow-y-scroll relative flex-1">
                    <Header />
                    <Content>
                        <Outlet />
                    </Content>
                    </main>
                </div>
                <BottomBanner />
            </div>
        </div>
    );
}


import React, { useState, useRef,  useEffect, useContext } from 'react';
import {Player, PlayerEvent} from 'bitmovin-player';
import { UIFactory } from 'bitmovin-player/bitmovinplayer-ui';
import 'bitmovin-player/bitmovinplayer-ui.css';




export function BitmovinPlayer({url, title, currentPlayPoint, onSeeked, trackProgress, destoryPlayer}) {

	const [player, setPlayer] = useState(null);
	const bitmovinPlayerDiv = useRef(null);

	const playerConfig = {
		key: import.meta.env.VITE_APP_BITMOVIN_PLAYER_KEY,
		ui: false,
		playback: {
			autoplay: true,
			muted: false
		},
		style: {
			height: '100vh',
			ui_css: './bitMovin.css'
		}
	}

	const playerSource = {
    hls: url,
		title: title
	}

	const initialized = useRef(false)

	useEffect(() => {
		console.log('Initialized', initialized.current)

		const bitmovinPlayerContainer = document.getElementsByClassName('bitmovinplayer-container');
		function updateRatio() {
			const width = 15.99 * (window.innerHeight / 9);
			if (bitmovinPlayerContainer.item(0) != null) {
				bitmovinPlayerContainer.item(0).setAttribute('style', `width: ${width}px !important;`);
			}
    }
    window.addEventListener('resize', updateRatio);
    updateRatio();

		let playerInstance = null;

		let intervalID;
		function handlePlaying() {
			if (!intervalID) {
				intervalID = setInterval(() => {
					trackProgress( playerInstance.getCurrentTime())
				}, 10000)
			}
		}

		function handleClearInterval() {
			console.log('cleared interval!!!');
			clearInterval(intervalID);
			intervalID = null;
		}

		function handleSeekPoints() {
			onSeeked(playerInstance.getCurrentTime());
			console.log(`Seeked to ${playerInstance.getCurrentTime()}`);
		}

		function setupPlayer() {
			// init player while it's required
			playerInstance = new Player(bitmovinPlayerDiv.current, playerConfig);
			playerInstance.on(PlayerEvent.Playing, handlePlaying);
			playerInstance.on(PlayerEvent.Seeked, handleSeekPoints);
			playerInstance.on(PlayerEvent.Paused, handleClearInterval);
			playerInstance.on(PlayerEvent.Destroy, handleClearInterval);


			UIFactory.buildDefaultUI(playerInstance);
			playerInstance.load(playerSource).then(() => {
				setPlayer(playerInstance)
				// Seek to last play end seconds
				playerInstance.seek(currentPlayPoint);

				const waterMarkButton = document.getElementsByClassName('bmpui-ui-watermark');
				waterMarkButton.item(0).style['background-image'] = 'url(../../images/logo.svg) !important'
				waterMarkButton.item(0).setAttribute('style', 'background-image: url(../../src/images/_logoIcon.svg) !important; margin-right: 5vw;');

				const settingBtn	= `url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' style='enable-background:new 0 0 32 32' xml:space='preserve'%3E%3Cpath d='m25.6 13.8-1.4-.3c-.2-.5-.4-1-.6-1.4l.9-1.3c.4-.5.3-1.3-.1-1.7L23 7.7c-.3-.3-.6-.4-1-.4-.3 0-.6.1-.8.3l-1.2.9c-.5-.3-1-.5-1.5-.6l-.3-1.4c0-.6-.6-1.1-1.3-1.1H15c-.7 0-1.2.5-1.3 1.1L13.5 8c-.5.2-1 .4-1.4.6l-1.3-.9c-.2-.2-.5-.3-.8-.3-.4 0-.7.1-1 .4L7.8 9.1c-.5.5-.5 1.2-.1 1.7l.9 1.3c-.3.5-.5 1-.6 1.4l-1.4.3c-.7 0-1.2.6-1.2 1.3V17c0 .7.5 1.2 1.1 1.3l1.5.2c.2.5.4 1 .6 1.4l-.9 1.3c-.4.5-.3 1.3.1 1.7l1.4 1.4c.3.3.6.4 1 .4.3 0 .6-.1.8-.3l1.3-.9c.5.2 1 .4 1.4.6l.3 1.4c.1.7.7 1.1 1.3 1.1H17c.7 0 1.2-.5 1.3-1.1l.3-1.4c.5-.2 1-.4 1.4-.6l1.3.9c.2.2.5.3.8.3.4 0 .7-.1 1-.4l1.4-1.4c.5-.5.5-1.2.1-1.7L23.5 20c.3-.5.5-1 .6-1.4l1.4-.3c.7-.1 1.1-.7 1.1-1.3v-2c0-.5-.5-1.1-1-1.2zm-1 3.1c0 .1 0 .1 0 0l-1.8.4c-.2 0-.4.2-.4.4-.2.6-.4 1.2-.7 1.6-.1.2-.1.4 0 .6l1 1.4v.1l-1.2 1.2h-.2l-1.4-1c-.2-.1-.4-.1-.6 0-.5.3-1.1.6-1.6.7-.2.1-.4.2-.4.4l-.3 1.8c0 .1-.1.1-.1.1h-1.7c-.1 0-.1 0-.1-.1l-.3-1.7c0-.2-.2-.4-.4-.4-.6-.2-1.2-.4-1.6-.7-.1 0-.2-.1-.3-.1-.1 0-.2 0-.3.1l-1.4 1h-.2l-1.2-1.2v-.1l1-1.4c.1-.2.1-.4 0-.6-.4-.4-.7-1-.8-1.6-.1-.2-.2-.4-.4-.4L7.5 17c-.1 0-.1-.1-.1-.1v-1.7c0-.1 0-.1.1-.1l1.7-.2c.2 0 .4-.2.5-.4.2-.6.4-1.2.7-1.6.1-.2.1-.4 0-.6l-1-1.4v-.1l1.2-1.2h.2l1.4 1c.2.1.4.1.6 0 .5-.3 1.1-.6 1.6-.7.2-.1.4-.2.4-.4l.2-2c0-.1.1-.1.1-.1h1.7c.1 0 .1 0 .1.1l.2 1.7c0 .2.2.4.4.5.6.2 1.2.4 1.7.7.2.1.4.1.6 0l1.4-1h.2l1.2 1.2v.1l-1 1.4c-.1.2-.1.4 0 .6.3.5.6 1.1.7 1.6.1.2.2.4.4.4l1.6.3c.1 0 .1.1.1.1v1.8h.2zM16 11.5c-2.5 0-4.5 2-4.5 4.5s2 4.5 4.5 4.5 4.5-2 4.5-4.5-2-4.5-4.5-4.5zm0 7.3c-1.5 0-2.8-1.3-2.8-2.8s1.3-2.8 2.8-2.8 2.8 1.3 2.8 2.8-1.3 2.8-2.8 2.8z' style='fill:%23fff'/%3E%3C/svg%3E") !important`
				const fullScrBtn = `url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' style='enable-background:new 0 0 32 32' xml:space='preserve'%3E%3Cstyle%3E.st0{fill:%23fff}%3C/style%3E%3Cpath class='st0' d='M4.7 7.7h4.7V5.4H4.7c-1.3 0-2.4 1.1-2.4 2.4v4.7h2.4V7.7z' id='Rectangle_6'/%3E%3Cpath class='st0' d='M4.7 19.5H2.4v4.7c0 1.3 1.1 2.4 2.4 2.4h4.7v-2.4H4.7v-4.7z' id='Rectangle_6_copy'/%3E%3Cpath class='st0' d='M27.3 5.4h-4.7v2.4h4.7v4.7h2.4V7.7c-.1-1.3-1.1-2.3-2.4-2.3z' id='Rectangle_6_copy_2'/%3E%3Cpath class='st0' d='M27.3 24.3h-4.7v2.4h4.7c1.3 0 2.4-1.1 2.4-2.4v-4.7h-2.4v4.7z' id='Rectangle_6_copy_3'/%3E%3C/svg%3E") !important;`;
				const elements = document.querySelectorAll("button, [type='button'], [type='reset'], [type='submit']");
				for (let i = 0; i < elements.length; i++) {
					const element = elements[i];
					if (element.classList.contains('bmpui-ui-settingstogglebutton')) {
						element.setAttribute('style', `background-image: ${settingBtn}`);
					} else if (element.classList.contains('bmpui-ui-fullscreentogglebutton')) {
						element.setAttribute('style', `background-image: ${fullScrBtn}`);
					}
				}

				const btnElements = document.querySelectorAll("button, [role='button']");
				for (let i = 0; i < btnElements.length; i++) {
					const btn = btnElements[i];
					if (btn.classList.contains('top-0')) {
						btn.setAttribute('style', `z-index: 1`);
					}
				}
			}, () => {
				console.log('Error while loading source');
			});
		}

		if (!initialized.current) {
			setupPlayer();
			initialized.current = true
		}

		return () => {
			function destroyPlayer() {
				if(player) {
					player.destroy();
					setPlayer(null);
					initialized.current = false
				}
			}
			destroyPlayer();
			window.removeEventListener('resize', updateRatio);
		}
	}, [destoryPlayer])

	return (
			<div id='bitmovin-player' ref={bitmovinPlayerDiv} />
	)
};
import React from 'react';

import { Spinner } from 'components/common';
import useIntersectionObserver from 'hooks/useIntersectionObserver';
import CollectionRow from './CollectionRow';
import { ButtonRound } from './common/Button';

export default function Collections({
    collections,
    loading,
    hasNextPage,
    fetchNextPage,
    fetchingNextPage,
    onClick
}) {
    // Intersection Observer
    const loadMoreButtonRef = React.useRef();

    useIntersectionObserver({
        target: loadMoreButtonRef,
        onIntersect: fetchNextPage,
        enabled: hasNextPage,
    });

    return collections && collections.pages && collections.pages.length > 0 ? (
        <div>
            {collections.pages.map((page, i) => (
                <React.Fragment key={i}>
                    {page.data.results.map((collection, i) => (
                        <React.Fragment key={i}>
                            <CollectionRow
                                key={collection.pk}
                                title={collection.title}
                                pk={collection.pk}
                                onClick={onClick}
                            />
                        </React.Fragment>
                    ))}
                </React.Fragment>
            ))}
            <div className="flex justify-center items-center py-10 text-gray-500">
                <button
                    ref={loadMoreButtonRef}
                    onClick={() => fetchNextPage()}
                    disabled={!hasNextPage || fetchingNextPage}
                >
                    {fetchingNextPage ? (
                        <div className="flex justify-center items-center w-full">
                            <Spinner size={8} />
                        </div>
                    ) : hasNextPage ? (
                        'Load more'
                    ) : (
                        ''
                    )}
                </button>
            </div>
            <div>{loading && !fetchingNextPage ? 'Fetching...' : null}</div>
        </div>
    ) : null;
}

import React, { useState, useEffect, useContext } from "react";

import { useTranslation } from "react-i18next";

import { Spinner } from "components/common";

import { Form, Formik, Field, useFormik } from "formik";
import * as Yup from "yup";

import { AuthContext } from "context/AuthContext";
import { FetchContext } from "context/FetchContext";
import { useQuery, useQueryClient } from "@tanstack/react-query";

import Label from "components/common/Label";
import { TextInput, SelectInput, CheckInput } from "components/TextInput";
import { Checkbox, Radio } from "components/common";

import { ButtonRound } from "components/common/Button";
import FormError from "components/FormError";
import FormSuccess from "components/FormSuccess";
import qs from "qs";
import { APP_NAME } from "utils/constants";
import { useDocumentTitle } from '@uidotdev/usehooks'


export default function General(props) {
  const [userPk, setUserPk] = useState(""); //Change This to get the user pk via context
  const [submitLoading, setSubmitLoading] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [submitError, setSubmitError] = useState(false);

  const authContext = useContext(AuthContext);
  const fetchContext = useContext(FetchContext);
  const { user_info } = authContext.authState;
  const user = user_info ? JSON.parse(user_info) : {};
  const queryClient = useQueryClient();

  function useAccountPreferences(pk) {
    const fetchContext = useContext(FetchContext);
    return useQuery(["account-preferences", pk], () =>
      fetchContext
        .authAxios(`/users/${pk}/account/preferences/`)
        .then((res) => res.data)
    );
  }

  const { data, error, isLoading, isError } = useAccountPreferences(user.pk); // get account data with Query

  useDocumentTitle(`General - ${APP_NAME}`);

  const stringToBool = (value) => {
    if (value && typeof value === "string") {
      if (value.toLowerCase() === "true") return true;
      if (value.toLowerCase() === "false") return false;
    }
    return value;
  };

  function mapObjToBool(obj = {}) {
    for (const key in obj) {
      const val = obj[key];
      if (val === "true") {
        obj[key] = true;
      } else if (val === "false") {
        obj[key] = false;
      } else if (val === "en") {
        obj[key] = "EN";
      } else if (val === "fr") {
        obj[key] = "FR";
      }
    }
  }

  const submitNewPreferences = async (preferences) => {
    try {
      setSubmitLoading(true);
      mapObjToBool(preferences);
      const userPrefs = await fetchContext.authAxios.put(
        `users/${user.pk}/account/preferences/`,
        preferences
      );

      if (userPrefs.status === 200) {
        setSubmitSuccess("Preferences have been updated");
        queryClient.invalidateQueries("account-preferences");
      }
      setSubmitError(false);
      setSubmitLoading(false);
    } catch (error) {
      setSubmitLoading(false);
      const { status } = error.response;
      if (status === 400) {
        setSubmitError(
          "There was an issue with updating the changes, please try again"
        );
      }
      setSubmitSuccess(false);
    }
  };

  const { t, i18n } = useTranslation();

  if (isLoading)
    return (
      <div className="w-full h-screen flex items-center justify-center ">
        <Spinner size={8} />
      </div>
    );

  if(isError)
    return (
      <div className="w-full h-screen text-gray-500 flex items-center justify-center ">
        <p>Error: {error.message}</p>
      </div>
    );

  return (
    <div className="_general overflow-y-scroll px-[3vw] pt-20 h-full ">
      <div className="_general-header text-2xl font-bold text-gray-100 mb-5">
        <h1>{t("setting-general.general")}</h1>
      </div>

      <div className="text-gray-300 w-full max-w-sm">
        <div className="text-gray-300 flex">
          <Formik
            enableReinitialize={true}
            initialValues={{
              language: "en",
              // language: data.language.toLowerCase(),
              // do_not_send: data.do_not_send,
              send_newsletters: data.send_newsletters,
              send_updates: data.send_updates,
              send_offers: data.send_offers,
              // enable_autoplay: data.enable_autoplay.toString(),
            }}
            onSubmit={submitNewPreferences}
          >
            <Form>
              <div className="flex flex-col space-y-6">
                <div className="">
                  <div className="_item-account flex flex-col mt-4 text-lg font-semibold border-b border-gray-500 border-opacity-25 mb-4">
                    <div className="mb-1">
                      Language Preferences{" "}
                      <span className="text-xs font-thin bg-green-600 py-0.5 px-1 rounded">
                        Coming Soon
                      </span>
                    </div>
                    <div className="text-xs font-normal text-gray-400">
                      {/* Change your language preferences */}
                    </div>
                  </div>

                  <div className="opacity-50">
                    <Field
                      type="radio"
                      name="language"
                      id="lang-en"
                      value="en"
                      disabled
                    />
                    <Label
                      htmlFor="lang-en"
                      text="English"
                      className="ml-2"
                    />
                  </div>
                  <div className="opacity-50">
                    <Field
                      type="radio"
                      name="language"
                      id="lang-fr"
                      value="fr"
                      disabled
                    />
                    <Label
                      htmlFor="lang-fr"
                      text="Français"
                      className="ml-2"
                    />
                  </div>
                </div>

                <div className="">
                  <div className="_item-account flex mt-4 text-lg font-semibold border-b border-gray-500 border-opacity-25 mb-4">
                    <span className="mb-1">Email Notifications</span>
                  </div>

                  <div className="">
                    <Checkbox name="send_updates" id="send_updates" />
                    <Label
                      htmlFor="send_updates"
                      text={t("setting-general.update")}
                      className="ml-2"
                    />
                  </div>
                  <div className="">
                    <Checkbox
                      name="send_newsletters"
                      id="send_newsletters"
                    />
                    <Label
                      htmlFor="send_newsletters"
                      text={t("setting-general.newsletter")}
                      className="ml-2"
                    />
                  </div>
                  <div className="">
                    <Checkbox name="send_offers" id="send_offers" />
                    <Label
                      htmlFor="send_offers"
                      text={t("setting-general.offer")}
                      className="ml-2"
                    />
                  </div>
                  {/* <div className="">
                  <Checkbox name="do_not_send" id="do_not_send" />
                  <Label htmlFor="do_not_send" text="Do Not Send" className="ml-2" />
                </div> */}
                </div>

                <div className="">
                  <div className="_item-account flex flex-col mt-4 text-lg font-semibold border-b border-gray-500 border-opacity-25 mb-4">
                    <div className="mb-1">
                      Autoplay{" "}
                      <span className="text-xs font-thin bg-green-600 py-0.5 px-1 rounded">
                        Coming Soon
                      </span>
                    </div>
                    <div className="text-xs font-normal text-gray-400">
                      {t("setting-general.playback-text")}
                    </div>
                  </div>

                  <div className="opacity-50">
                    <Radio
                      name="enable_autoplay"
                      id="autoplay-on"
                      value="true"
                      disabled
                    />
                    <Label
                      htmlFor="autoplay-on"
                      text={t("setting-general.on")}
                      className="ml-2"
                    />
                  </div>
                  <div className="opacity-50">
                    <Radio
                      name="enable_autoplay"
                      id="autoplay-off"
                      value="false"
                      disabled
                    />
                    <Label
                      htmlFor="autoplay-off"
                      text={t("setting-general.off")}
                      className="ml-2"
                    />
                  </div>
                </div>

                <ButtonRound
                  type="submit"
                  text="Update Settings"
                  className="self-start space-x-2"
                  size="rnd"
                  loading={submitLoading}
                />
              </div>
            </Form>
          </Formik>
        </div>
      </div>
    </div>
  );
}

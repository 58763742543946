import React from 'react';
import { Spinner } from 'components/common';
import { useSaveActivity } from 'api/useSaveActivity';
import { ButtonRound } from 'components/common/Button';
import { APP_NAME } from 'utils/constants';
// utils
import { ContentGrid } from 'components/layouts';
import Thumbnail from 'components/Thumbnail';
import useIntersectionObserver from 'hooks/useIntersectionObserver';
import { AuthContext } from 'context/AuthContext';
import { useDeleteWatchlist } from 'api/mutations/watchlist';
import { Icon } from 'components/icons/icon.export';
import { useDocumentTitle } from '@uidotdev/usehooks';


function WatchList() {
  const authContext = React.useContext(AuthContext);
  const { user_info } = authContext.authState;
  const user = user_info ? JSON.parse(user_info) : {};
  const { data, error, isLoading, isError, fetchNextPage, isFetchingNextPage, hasNextPage } = useSaveActivity(user.pk);
  const { mutate } = useDeleteWatchlist();
  const loadMoreButtonRef = React.useRef();

  useIntersectionObserver({
    target: loadMoreButtonRef,
    onIntersect: fetchNextPage,
    enabled: hasNextPage,
  });

  useDocumentTitle(`Your Watchlist | ${APP_NAME}`);

  if (data && data.count < 1) {
    return (
      <div className="flex flex-col justify-center items-center w-full h-screen text-gray-400">
        <Icon name='plus' size={100} weight="fill" className='text-gray-800' />
        <h4 className="text-3xl font-bold text-white">Your watchlist is empty </h4>
        <p> Add content to your watchlist and it will appear here.</p>
      </div>
    )
  }

  if (isLoading)
    return (
      <div className="flex justify-center items-center w-full h-screen ">
        <Spinner size={8} />
      </div>
    );
  if (isError)
    return (
      <div className="flex justify-center items-center w-full h-screen text-gray-500 ">
        <p>Error: {error.message}</p>
      </div>
    );
  return (
    <>
      <ContentGrid pageType="Watchlist">
        <div className="grid grid-cols-3 gap-4 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-6 xl:grid-cols-8">
          {data.results.map((item) => (
            <Thumbnail key={item.pk} {...item} image={item.content_object.images.thumbnail}>
              <div className="group flex absolute right-2 bottom-2 z-10 justify-between items-center">
                <ButtonRound
                  size="rnd"
                  varient="plain"
                  IconStart={<Icon name="close" size={24} weight='bold' />}
                  onClick={() => mutate(item.content_object._detail)}
                  title="Remove"
                />
              </div>
            </Thumbnail>
          ))}
        </div>
      </ContentGrid>
    </>
  );
}

export default WatchList;

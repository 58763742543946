import React, { useEffect } from 'react';
import { useQuery, useInfiniteQuery } from '@tanstack/react-query';
import { FetchContext } from 'context/FetchContext';
import { UserContext } from 'context/UserContext';
import { PAGE_SIZE } from 'utils/constants';


const ALL_TYPES = 'movie,show,collection,episode,topic'
const BASE_TYPES = 'movie,show,season,episode,livestream,topic,genre,collection'

export function useSearch(query) {
    const fetchContext = React.useContext(FetchContext);
    const userContext = React.useContext(UserContext);
    //Get the user info from the user context
    const { country: COUNTRY, tier } = userContext.userData;
    // Transform granted and admin to 'donor' since the api only accepts this donor or free
    const ACCESS_TIER = tier === 'granted' | tier === 'admin' ? 'donor' : tier;

    return useQuery({
        queryKey: ['searchResults', query],
        queryFn: ({ region = COUNTRY, tier = ACCESS_TIER, size = PAGE_SIZE,  index = ALL_TYPES }) => fetchContext.searchAxios(
                    `/search/?query=${query}&region=${region}&tier=${tier}&index=${index}&size=${size}`,
                ).then((res) => res.data.data),
            // The query will not execute until the query exists
                enabled: !!query,

  });
}

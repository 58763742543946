import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { publicFetch } from "utils/fetch";
import logo from "../../images/logo.svg";
import FormError from "components/FormError";
import FormSuccess from "components/FormSuccess";
import { Navlink } from "components/common/Hyperlink";
import Label from "components/common/Label";
import { TextInput } from "components/TextInput";
import { ButtonRound } from "components/common/Button";
import Card from "components/common/Card";
import qs from "qs";
import { APP_NAME } from "utils/constants";
import { Success } from "components/Modal";
import { useDocumentTitle } from '@uidotdev/usehooks'


const RequestVerificationEmailSchema = Yup.object().shape({
  email: Yup.string()
    .email("Email must be a valid email address")
    .required("Email is required"),
});

// Get the query Params
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const RequestVerificationEmail = () => {
  // Get the query params
  let query = useQuery();
  let params = useParams();
  const [submitSuccess, setSubmitSuccess] = useState();
  const [submitError, setSubmitError] = useState();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [userQueryParams, setUserQueryParams] = useState();
  const [toggleModal, setToggleModal] = useState(false);
  const [userEmail, setUserEmail] = useState();

  useDocumentTitle(`Request Verification Email | ${APP_NAME}`)
  useEffect(() => {
    if (handleQueryParams("email")) {
      setUserQueryParams(handleQueryParams("email"));
    }
  }, []);

  // handles the inital value of the form field - based on the query params.
  const handleQueryParams = (u) => {
    const validQueryParam = query.get(u);
    if (validQueryParam) {
      return validQueryParam;
    } else {
      return "";
    }
  };

  // Submit Credentials
  const submitCredentials = async (user) => {
    const userData = new Object();
    userData.email = user;

    try {
      setSubmitLoading(true);
      const { data } = await publicFetch.post("users/verify_request/", user);

      setSubmitSuccess(data.message);
      setSubmitError("");
      setUserEmail(user.email);
      setTimeout(() => {
        setToggleModal(true);
        // setRedirectOnSubmit(true);
      }, 100);
      setSubmitLoading(false);
    } catch (error) {
      setSubmitLoading(false);
      // const { data } = error.response;
      setSubmitError(error.response.data.non_field_errors);
      // if (error.status === 400 ) {
      // 	setSubmitError(error.response.data.email)
      // } else {
      // 	setSubmitError('testing')
      // }
      console.log(error.response.data); //'Enter a valid email address.'
      setSubmitSuccess(null);
    }
  };

  const { t, i18n } = useTranslation("translation");
  return (
    <>
      {/* {redirectOnSubmit && <Redirect to="/verify-success"  />} */}
      <Success
        user={userEmail}
        modalState={toggleModal}
        toggle={setToggleModal}
        text="Test Test Test"
      />
      <section className="w-full h-screen relative flex flex-col items-center justify-center p-8 sm:pt-10 bg-no-repeat bg-cover bg-center overlay-gradient" data-testid='verify-request'>
        <Card>
          <div className="text-gray-400">
            <div className="w-32 m-auto mb-10">
              <img src={logo} alt="Logo" />
            </div>
            <h2 className="mb-2 text-center text-2xl leading-9 font-bold text-gray-100">
              Request New Verification Email
            </h2>
            <p className="text-center">
              Verification emails are only valid for several days. Need a
              new one? Submit your email below to receive a new one.
            </p>
            <p className="text-sm text-center mt-2">
              {t("auth.reset-password.request.account.message")}{" "}
              <Navlink
                to="/signin"
                text={t("auth.reset-password.request.account.link")}
                className="text-indigo-400"
              />
            </p>
          </div>
          <Formik
            enableReinitialize
            initialValues={{
              email: userQueryParams,
            }}
            onSubmit={(values) => submitCredentials(values)}
            validationSchema={RequestVerificationEmailSchema}
          >
            {({ isSubmitting, isValid }) => (
              <Form className="mt-8">
                {submitSuccess && <FormSuccess text={submitSuccess} />}
                {submitError && <FormError text={submitError} />}

                <div className="mb-2">
                  <div className="mb-1 flex flex-col">
                    <div className="mb-1">
                      <Label text="Enter your email" />
                    </div>
                    <TextInput
                      ariaLabel={t("signup.e-placeholder")}
                      name="email"
                      type="email"
                      autocomplete="username"
                      placeholder={t("signup.e-placeholder")}
                    />
                  </div>
                </div>

                <div className="mt-6">
                  {/* <Recaptcha
                    ref={ref => this.recaptcha = ref}
                    sitekey={siteKey}
                    onResolved={this.verifyRecaptcha} /> */}
                  <ButtonRound
                    type="submit"
                    text="Request new verification email"
                    loading={submitLoading}
                    className="w-full"
                    disabled={isSubmitting || !isValid}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </Card>
      </section>
    </>
  );
};

export default RequestVerificationEmail;

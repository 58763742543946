import { useContext } from 'react';
import { AuthContext } from 'context/AuthContext';

export default function Content({ children }) {
    const authContext = useContext(AuthContext);
    const { user_info } = authContext.authState;
    const user = user_info ? JSON.parse(user_info) : {};

    return (
        <div
            className={`Content ${user && user.state === '110' ? 'pb-32' : ''}`}
        >
            {children}
        </div>
    );
}

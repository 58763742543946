import React from 'react';
import { useInfiniteQuery } from '@tanstack/react-query';
import parseUrl from 'url-parse';
import { PAGE_SIZE_ROW, STALE_TIME } from 'utils/constants';
import { FetchContext } from 'context/FetchContext';

export function useSeasonEpisodes(showPk, currentSeason, data) {
    const fetchContext = React.useContext(FetchContext);
    console.log('Episode Pk ', currentSeason, 'Show Pk ', showPk)

    return useInfiniteQuery(
        ['show', showPk, 'season', currentSeason?.pk, 'episodes'],
        ({ pageParam = 1, pageSize = PAGE_SIZE_ROW }) =>
            fetchContext.authAxios(
                `/content/shows/${showPk}/seasons/${currentSeason?.pk}/episodes/?page=${pageParam}&page_size=${pageSize}`
            ),
        {
            enabled: data !== undefined && currentSeason !== undefined,

            getNextPageParam: (lastPage, page) => {
                if (lastPage.data._next === null) return undefined;

                const { query } = parseUrl(lastPage.data._next, true);
                return query.page;
            },
            keepPreviousData: true,
            staleTime: STALE_TIME

        }
    );

    // return useInfiniteQuery(
    //   ["show", showPk, "season", seasonPk],
    //   ({ pageParam = 1, pageSize = PAGE_SIZE_ROW}) =>
    //     fetchContext.authAxios(`/content/shows/${showPk}/seasons/${seasonPk}/episodes/?page=${pageParam}&page_size=${pageSize}`),
    //   {
    //     enabled: !!seasonPk,

    //     getNextPageParam: (lastPage, page) => {
    //       if (lastPage.data._next === null) return undefined;

    //       const { query } = parseUrl(lastPage.data._next, true)
    //       return query.page

    //     },
    //     keepPreviousData: true,
    //   }
    // );
}

import { useState } from 'react';
import SidebarNav from './layouts/SidebarNav';
import { APP_NAME } from 'utils/constants';
import { Icon } from './icons/icon.export';


interface SidebarProps {
    isMobile: boolean;
}

export function Sidebar({isMobile}: SidebarProps) {
    const sidebarCollapsed: boolean | null = JSON.parse(localStorage.getItem('sidebar-is-collapsed') ?? 'false');
    const [isCollapsed, setIsCollapsed] = useState(sidebarCollapsed);

    const handleToggler = () => {
        if (isCollapsed) {
            setIsCollapsed(false);
            localStorage.setItem('sidebar-is-collapsed', JSON.stringify(false));
            return;
        }
        setIsCollapsed(true);
        localStorage.setItem('sidebar-is-collapsed', JSON.stringify(true));
    };

    return (
        <nav className={`
            fixed bottom-0 inset-x-0 z-50 bg-gray-900 border-r border-gray-800 flex
            md:static md:flex md:flex-col md:overflow-x-hidden transition-[width]  duration-500
            ${ isCollapsed ? 'md:w-16' : 'md:w-48' }
            ${ isMobile ? 'py-1' : 'py-4'} px-2
            `}
        >
            <SidebarNav collapsed={isCollapsed} toggleCollapse={handleToggler} isMobile={isMobile} />
        </nav>
    );
}

interface SidebarMobileProps {
    mobileOpen: boolean;
    toggleMobileMenu: () => void;
}

export const SidebarMobile: React.FC<SidebarMobileProps> = ( {mobileOpen, toggleMobileMenu } ) => {

    return (
        <div className={`${mobileOpen ? 'static': 'hidden'}`}>
            <div className="fixed inset-0 z-50">
                <div className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-gray-800 px-6 py-6">
                    <div className="flex items-center justify-between">
                        <a href="#" className="-m-1.5 p-1.5">
                            <span className="sr-only">{APP_NAME}</span>
                            <img
                                className="h-14 w-auto"
                                src="https://static.corcoplus.com/assets/emails/logo_v2_68-white.png"
                                alt="Corco+ logo"
                            />
                        </a>
                        <button
                            type="button"
                            className="-m-2.5 rounded-md p-2.5 text-gray-300"
                            onClick={toggleMobileMenu}
                        >
                            <span className="sr-only">Close menu</span>
                            <Icon name="close" weight="bold" className="h-6 w-6" aria-hidden="true" />
                        </button>

                    </div>
                </div>
            </div>
        </div>
    )
}
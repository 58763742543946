import React from 'react';
// Reusable utility functions

// Convert a string to title case.
export function toTitleCase(str) {
    return str
        .toLowerCase()
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
}

import React from 'react';
import cn from 'classnames';

const Card = ({ background, className, children }) => (
    <div className={cn(`w-full z-0 m-auto`, background, className)}>
        <div className="flex justify-center items-center py-12 w-full">
            <div className="w-full max-w-md">
                {children}
            </div>
        </div>
    </div>
);

export default Card;

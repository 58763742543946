import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { AuthContext } from 'context/AuthContext';
import { Spinner } from './common';

// Detail page types
// Trailer, Movie, Show, Collection, Livestream, Topic

export default function DetailMeta({ data, isLoading, isError, error, page }) {
    const authContext = useContext(AuthContext);
    const { user_info: userInfo } = authContext.authState;
    const user = JSON.parse(userInfo);

    if (isLoading)
        return (
            <div className="flex justify-center items-center ">
                <Spinner size={8} />
            </div>
        );
    if (isError)
        return (
            <div className="flex justify-center items-center text-gray-500 ">
                <p>Error: {error.message}</p>
            </div>
        );

    // Trailer Page
    if (page === 'trailer')
        return (
            <div className="text-xs leading-[18px] text-gray-400">
                {data.content_object.content_model === 'season' ? (
                    <span className="inline-block px-[4px] pt-0.5 bg-gray-800 rounded-sm">
                        {data.content_object.show.maturity_rating}
                    </span>
                ) : (
                    <span className="inline-block px-[4px] pt-0.5 bg-gray-800 rounded-sm">
                        {data.content_object.maturity_rating}
                    </span>
                )}
                <span className="mx-[4px]">|</span>

                {data.content_object.content_model === 'season' ? (
                    <span className="inline-block">{`Season ${data.content_object.season_number}`}</span>
                ) : (
                    <span className="inline-block">
                        {data.content_object.length}
                    </span>
                )}

                <span className="mx-[4px]">|</span>

                <span className="inline-block">{data.genre.title}</span>
            </div>
        );

    // Topic Page
    if (page === 'topic')
        return (
            <div className="text-xs leading-[18px] text-gray-400">
                <span className="inline-block">
                    {data.episodes.count}
                    {data.episodes.count > 1 ? ' Videos' : ' Video'}
                </span>
            </div>
        );
    // Collection Page
    if (page === 'collection')
        return (
            <div className="text-xs leading-[18px] text-gray-400">
                <span className="inline-block">
                    {data.collection_items.count}
                    {data.collection_items.count > 1 ? ' Items' : ' Item'}
                </span>
            </div>
        );

    return (
        <div className="text-base leading-[18px] text-gray-400">
            {data.content_model !== 'trailer' ? (
                <span className="space-x-2">
                    <span className="inline-block px-[4px] pt-0.5 bg-gray-800 rounded-sm">
                    {data.maturity_rating}
                    </span>
                    {data.has_captions &&
                    <span className="inline-block px-[4px] pt-0.5 bg-gray-800 rounded-sm">CC</span>
                    }
                </span>

            ) : (
                // TODO: Set the Genre on Trailer Page etc.
                <span className="inline-block px-[4px] pt-0.5 bg-gray-800 rounded-sm">
                    TODO
                </span>
            )}

            <span className="mx-[4px]">|</span>

            {data.content_model === 'show' ? (
                // ? <span className='inline-block'>{`Season ${data.content_object.season_number}`}</span>
                // : <span className='inline-block'>{data.content_object.length}</span>
                <span className="inline-block">
                    {data.seasons.count}{' '}
                    {data.seasons.count > 1 ? ' Seasons ' : ' Season'}{' '}
                </span>
            ) : (
                <span className="inline-block">{data.length}</span>
            )}

            <span className="mx-[4px]">|</span>
            {data.content_model === 'movie' || data.content_model === 'show' ? (
                <span className="inline-block">{data.genre.title}</span>
            ) : null}
        </div>
    );
}

DetailMeta.propTypes = {
    data: PropTypes.shape({
        content_model: PropTypes.string,
        content_object: PropTypes.shape({
            content_model: PropTypes.string,
            length: PropTypes.any,
            maturity_rating: PropTypes.any,
            season_number: PropTypes.any,
            show: PropTypes.shape({
                maturity_rating: PropTypes.any,
            }),
        }),
        genre: PropTypes.shape({
            title: PropTypes.any,
        }),
        length: PropTypes.any,
        maturity_rating: PropTypes.any,
        seasons: PropTypes.shape({
            count: PropTypes.number,
        }),
    }),
    error: PropTypes.shape({
        message: PropTypes.any,
    }),
    isError: PropTypes.any,
    isLoading: PropTypes.any,
    page: PropTypes.string,
};

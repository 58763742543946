import axios from 'axios';

/* Endpoints that don't require authentication */
export const publicFetch = axios.create({
    baseURL: import.meta.env.VITE_APP_API_URL,
});

export const privateFetch = axios.create({
    baseURL: import.meta.env.VITE_APP_API_URL,
});

import React from 'react';
import { Link } from 'react-router-dom';

const classNames =
    'font-base text-indigo-500 hover:text-indigo-300 focus:outline-none focus:underline transition ease-in-out duration-150';

const Navlink = ({ text, target = '_self', to, ...restProps }) => (
    <Link to={to} target={target} className={classNames} {...restProps}>
        {text}
    </Link>
);

const Hyperlink = ({ text, target, to }) => (
    <a href={to} target={target} className={classNames}>
        {text}
    </a>
);

export { Navlink, Hyperlink };

import React, { useContext } from 'react';
import { Spinner } from 'components/common';
import { APP_NAME } from 'utils/constants';
import ThumbnailTopic from 'components/ThumbnailTopic';
import { ContentGrid } from 'components/layouts';
import useIntersectionObserver from 'hooks/useIntersectionObserver';
import { useTopics } from 'api/useTopics';
import { useLocation } from 'react-router-dom';
import { useDocumentTitle } from '@uidotdev/usehooks';

export default function Topic() {
  const location = useLocation();
  const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage, isError, error, refetch } = useTopics(
    location.search ? location.search : '?ordering=recent'
  );


    useDocumentTitle(`All Topics | ${APP_NAME}`);

  React.useEffect(() => {
    refetch();
  }, [location?.search]);

  const loadMoreButtonRef = React.useRef();

  useIntersectionObserver({
    target: loadMoreButtonRef,
    onIntersect: fetchNextPage,
    enabled: hasNextPage,
  });

  if (isLoading)
    return (
      <div className="flex justify-center items-center w-full h-screen">
        <Spinner size={8} />
      </div>
    );
  if (isError)
    return (
      <div className="flex justify-center items-center w-full h-screen text-gray-500">
        <p>Error: {error.message}</p>
      </div>
    );

  return (
    <>
      <ContentGrid pageType="Topics">
        <div className="grid grid-cols-3 gap-4 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-6 xl:grid-cols-8">
          {data.pages.map((group, i) => (
            <React.Fragment key={i}>
              {group.data.results.map((item) => (
                <ThumbnailTopic
                  key={item.pk}
                  {...item}
                  image={item.images.thumbnail}
                  count={item.episodes.count}
                  className="relative h-full text-white "
                >
                  <h2 className="flex absolute inset-0 justify-center items-center">{item.title}</h2>
                </ThumbnailTopic>
              ))}
            </React.Fragment>
          ))}
        </div>
      </ContentGrid>
      <div className="flex justify-center items-center py-10 text-gray-500">
        <button
          type="button"
          ref={loadMoreButtonRef}
          onKeyDown={() => fetchNextPage()}
          onClick={() => fetchNextPage()}
          disabled={!hasNextPage || isFetchingNextPage}
        >
          {isFetchingNextPage ? (
            <div className="flex justify-center items-center w-full">
              <Spinner size={8} />
            </div>
          ) : hasNextPage ? (
            'Load more'
          ) : (
            ''
          )}
        </button>
      </div>
    </>
  );
}

import React, {useContext} from 'react';
import { useParams } from 'react-router-dom';
// Query Hooks
import {
    useCollectionByPk,
    useCollectionByPkItems,
} from 'api/useCollectionByPk';
// Icons
import { Spinner } from 'components/common';
// util
import { APP_NAME } from 'utils/constants';
import { ContentGrid } from 'components/layouts';
import Thumbnail from 'components/Thumbnail';
import VideoPlayer from 'components/VideoPlayer';
import { useQueryClient } from '@tanstack/react-query';
import useIntersectionObserver from '../hooks/useIntersectionObserver';
import { useDocumentTitle } from "@uidotdev/usehooks";
// context
import { PlayerContext } from 'context/PlayerContext';


function CollectionDetail({ props }) {
    const [playUrl, setPlayUrl] = React.useState(null);
    const [type, setType] = React.useState(null);
    const [title, setTitle] = React.useState('');

    const playerContext = useContext(PlayerContext);

    const queryClient = useQueryClient();
    const params = useParams();
    const collection = useCollectionByPk(params.pk);
    const {
        data,
        isIdle,
        isLoading,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        isError,
        error,
    } = useCollectionByPkItems(params.pk, collection.isSuccess);
    const [showVideo, setShowVideo] = React.useState(false);

    useDocumentTitle(collection.data && `${collection.data.title} Collection | ${APP_NAME}`)

    const loadMoreButtonRef = React.useRef();

    useIntersectionObserver({
        target: loadMoreButtonRef,
        onIntersect: fetchNextPage,
        enabled: hasNextPage,
    });

    if (isLoading || collection.isLoading)
        return (
            <div className="flex justify-center items-center w-full h-screen ">
                <Spinner size={8} />
            </div>
        );
    if (isError || collection.isError)
        return (
            <div className="flex justify-center items-center w-full h-screen text-gray-500 ">
                <p>Error: {collection.error.message || error.message}</p>
            </div>
        );

    return (
        <>
            <VideoPlayer
                title={playerContext.title}
                playUrl={playerContext.videoSrc}
                type={type}
                onDismiss={() => {
                    setPlayUrl(playerContext.setVideoSrc(null));
                    queryClient.refetchQueries(['playactivity']);
                }}
            />
            <ContentGrid
                pageType="Collection"
                pageTitle={collection.data.title}
                pageDesc={collection.data.description}
            >
                {isIdle ? (
                    <Spinner size={8} />
                ) : (
                    <div className="grid grid-cols-3 gap-4 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-6 xl:grid-cols-8">
                        {data.pages?.map((group, i) => (
                            <React.Fragment key={i}>
                                {group.data.results.map((item) => (
                                    <Thumbnail
                                        key={item.pk}
                                        {...item.content_object}
                                        image={
                                            item.content_object.images.thumbnail
                                        }
                                        growOnHover
                                    />
                                ))}
                            </React.Fragment>
                        ))}
                    </div>
                )}
            </ContentGrid>
            <div className="flex justify-center items-center py-10 text-gray-500">
                <button
                    ref={loadMoreButtonRef}
                    type="button"
                    onKeyDown={() => fetchNextPage()}
                    disabled={!hasNextPage || isFetchingNextPage}
                    onClick={() => fetchNextPage()}
                >
                    {isFetchingNextPage ? (
                        <div className="flex justify-center items-center w-full">
                            <Spinner size={8} />
                        </div>
                    ) : hasNextPage ? (
                        'Load more'
                    ) : (
                        ''
                    )}
                </button>
            </div>
        </>
    );
}

export default CollectionDetail;

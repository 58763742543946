import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import {
  DevBanner,
  StatusBanner,
} from "./components/notifications/BannerNotification";
import { FetchProvider } from "./context/FetchContext";
import { AuthProvider } from "./context/AuthContext";
import { UserProvider } from "./context/UserContext";
import { PlayerProvider } from "context/PlayerContext";
import { BrowserRouter } from "react-router-dom";
import { router, LoadingFallback, Root } from "./routes/routes";
// Import Sentry
import * as Sentry from "@sentry/react";


// Initialize Sentry
Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  release: `mcvod-web@${APP_VERSION}`,
  environment: import.meta.env.MODE,
  integrations: [
    // See docs for support of different versions of variation of react router
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
    Sentry.replayIntegration(),
    Sentry.browserTracingIntegration(),
  ],

  tracesSampleRate: 1.0, // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
  tracePropagationTargets: ["localhost", "app.corcoplus.com"],
  replaysSessionSampleRate: 0.1, //Replay sample rate, 10% of all sessions.
  replaysOnErrorSampleRate: 1.0, //Replay on error sample rate, 100% for sessions with an error.
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export default function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <FetchProvider>
          <UserProvider>
            <QueryClientProvider client={queryClient}>
              <PlayerProvider>
                <StatusBanner active={false} />
                <Root />
                {/* <RouterProvider router={router} fallbackElement={<LoadingFallback />} /> */}
              </PlayerProvider>
              <DevBanner env={import.meta.env.DEV} />
              <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
          </UserProvider>
        </FetchProvider>
      </AuthProvider>
    </BrowserRouter>
  );
}

import parseUrl from 'url-parse';
import { useNavigate } from 'react-router-dom';
import { IMG_URI } from 'utils/constants';


export default function ThumbnailTopic({
    content_model: contentModel,
    title,
    image,
    overlay_text: overlayText,
    progress,
    onClick,
    style,
    count,
    _detail,
    className,
    children,
    ...props
}) {
    const navigate = useNavigate();
    const { pathname } = parseUrl(_detail || '');
    const path = pathname.replace('/v2/content', '');


    function handleThumbnailClick() {
        navigate(path);
    }

    return (
        <div className="flex flex-col w-full">
            <div
                className={`cursor-pointer group overflow-hidden relative rounded ${className}`}
            >
                {image ? (
                    <>
                        <div
                            tabIndex={0}
                            role="button"
                            onKeyDown={handleThumbnailClick}
                            onClick={handleThumbnailClick}
                            className="overflow-hidden relative after:absolute after:inset-0 after:hover:bg-gray-800/30 rounded after:transition after:duration-100 after:ease-in"
                        >
                            <img
                                src={image}
                                alt={title}
                                className="w-full h-full"
                                onError={(e) => e.target.src = IMG_URI}
                            />
                            {children}
                        </div>
                        <div className="flex absolute inset-x-0 bottom-0 justify-center items-center py-1 text-xs tracking-wide text-gray-100 uppercase bg-indigo-700/90">
                            {count} Videos
                        </div>
                    </>
                ) : (
                    overlayText && (
                        <div className="flex absolute inset-x-0 bottom-0 justify-center items-center h-1/6 text-gray-100 uppercase bg-indigo-700">
                            {count} Videos
                        </div>
                    )
                )}
            </div>
        </div>
    );
}

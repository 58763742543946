import React from "react";
import { SideBarBanner } from "components/notifications/BannerNotification";
import { useTranslation } from "react-i18next";
import { Link, useMatch } from "react-router-dom";
import { Icon } from "components/icons/icon.export";
import { AuthContext } from "context/AuthContext";
import { APP_NAME } from "utils/constants";
import logo from "images/logo.svg";
import logoIcon from "images/logoIcon.svg";
import { useFeature } from "flagged";


const CustomLink = ({ isExternal, to, newTab, ...props }) => (
  isExternal ? (
    <a href={to} target="_blank" rel="noopener noreferrer" {...props} />
  ) : (
    <Link to={to} {...props} />
  ));

const MenuIcon = ({ icon, className }) => (
  <span className={className}>
    <Icon name={icon} size={24} color="currentColor" />
  </span>
);

const MenuItem = ({ item, collapsed, toggleCollapse, isMobile }) => {
  const checkActiveRouter = (name) => name === window.location.pathname;
  const { t, i18n } = useTranslation("translation");

  return (
    <CustomLink
      to={item.location}
      onClick={toggleCollapse}
      isExternal={item.isExternal}
      className={`
      ${collapsed ? "md:flex" : "md:flex-row"}
      ${checkActiveRouter(item.location)
          ? " text-gray-100 rounded bg-gray-800/80"
          : " text-gray-400 rounded hover:bg-gray-800/80 hover:text-gray-100"
        }
      ${isMobile ? "py-1 text-xs px-1" : "py-2 px-2"}
      flex flex-col relative flex-initial md:whitespace-nowrap items-center group md:py-4 mb-1
      `}
    >
      <MenuIcon
        icon={item.icon}
        direction={item.direction}
        className={`mb-1 md:mb-0 ${collapsed ? "md:mr-0" : "md:mr-2"} h-6 w-6

        ${checkActiveRouter(item.location)
            ? "text-gray-100 "
            : "text-gray-400 group-hover:text-gray-100"
          }
        `}
      />

      <div
        className={`transition-[opacity] delay-200 duration-200 ${collapsed ? "hidden opacity-0" : "initial opacity-100"
          } `}
      >
        <span className="text-xs text-center md:text-sm md:text-left">
          {item.title}
        </span>
      </div>
    </CustomLink>
  );
};

const MenuContent = ({ menuItems, user, collapsed, isMobile }) => (
  <>
    {menuItems.map((item, i) => (
      <div
        className="my-0 flex-1 md:flex-initial"
        key={i}
      >
        <MenuItem item={item} collapsed={collapsed} isMobile={isMobile} />
      </div>
    ))}
  </>
);

const BottomMenuContent = ({ menuItems, user, collapsed, toggleCollapse, isMobile }) => (
  <div className="mt-auto">
    {menuItems.map((item, i) => (
      <div
        className={`${collapsed ? " " : ""} my-1 flex-1 md:flex-initial`}
        key={i}
      >
        <MenuItem
          item={item}
          collapsed={collapsed}
          toggleCollapse={toggleCollapse}
          isMobile={isMobile}
        />
      </div>
    ))}
  </div>
);

export default function SidebarNav({ collapsed, toggleCollapse, isMobile }) {
  const auth = React.useContext(AuthContext);
  const { t, i18n } = useTranslation();
  const sidebarSearchFlag = useFeature('sideBarSearchFlag')
  const sideBarWatchlistFlag = useFeature('sideBarWatchlistFlag')


  const globalNav = [
    {
      title: t("menu.discover"),
      icon: "discover",
      location: "/discover",
    },

    {
      title: t("menu.movie"),
      icon: "movie",
      location: "/movies",
    },

    {
      title: t("menu.tvshow"),
      icon: "series",
      location: "/shows",
    },
    {
      title: t("menu.kid"),
      icon: "kid",
      location: "/kids",
    },
    {
      title: t("menu.topic"),
      icon: "topic",
      location: "/topics",
    },
  ];




  if (sidebarSearchFlag) {
    globalNav.push(
      {
        title: t("menu.search"),
        icon: "search",
        location: "/search",
      },
    )
  }
  if (sideBarWatchlistFlag) {
    globalNav.push(
      {
        title: t('menu.settings.watchlist'),
        icon: "plus",
        location: "/watchlist",
      }
    )
  }



  const bottomNav = [
    {
      title: "Help & Support",
      icon: "info",
      location: "https://help.corco.com/",
      isExternal: true,
    },

    {
      title: "Collapse",
      icon: `${!collapsed ? "arrowLineLeft" : "arrowLineRight"}`,
    },
  ];

  const settingsNav = [
    {
      title: "Back to Discover",
      icon: "discover",
      location: "/discover",
    },
    {
      title: "Account",
      icon: "settings",
      location: "/settings/account",
    },

    {
      title: "General",
      icon: "user",
      location: "/settings/general",
    },
  ];

  // Check the route to determine the menu array to pass to MenuContent
  const account = useMatch("/settings/account");
  const general = useMatch("/settings/general");

  return (
    <>
      <Link to="/discover" className="hidden px-2 mb-6 md:block md:h-20 ">
        <div
          className={`${collapsed ? "justify-center" : "justify-start"
            } w-50 h-auto flex items-center text-white font-bold text-4xl tracking-wide just `}
        >
          <img alt={APP_NAME} src={collapsed ? logoIcon : logo} />
        </div>
      </Link>
      {auth.isAuthenticated() ? (
        <MenuContent
          menuItems={account || general ? settingsNav : globalNav}
          collapsed={collapsed}
          isMobile={isMobile}
        />
      ) : null}
      {!collapsed ? (
        <SideBarBanner />
      ) : null
      }
      {!isMobile ? (
        <BottomMenuContent
          menuItems={bottomNav}
          collapsed={collapsed}
          toggleCollapse={toggleCollapse}
          isMobile={isMobile}
        />
      ) : null
      }
    </>
  );
}

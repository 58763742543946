import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { STALE_TIME } from 'utils/constants';
import { FetchContext } from 'context/FetchContext';

export function useMovieByPk(pk) {
    const fetchContext = React.useContext(FetchContext);
    return useQuery(
        ['movie', pk],
        () =>
            fetchContext
                .authAxios(`/content/movies/${pk}/`)
                .then((res) => res.data),
        {
            staleTime: STALE_TIME
        }
    );
}

import FilterDropdown from 'components/common/FilterDropdown';


type ContentGridProps = {
    children: any;
    pageTitle?: string;
    pageType: "Kids" | "Movies" | "Shows" | "Topics" | "Search" | "Watchlist" | "Collection";
    pageDesc?: string;
}

export default function ContentGrid(props: ContentGridProps) {
    const { children, pageTitle, pageType, pageDesc } = props;


    const allowedPageTypes = ["Kids", "Movies", "Shows", "Topics"];

    return (
        <div className="flex overflow-y-auto flex-col  items-center px-[3vw] -mt-20 pt-20 w-full">
            <div className="self-start mb-6 w-[90%] max-w-[800px] md:w-[35%] md:min-w-[500px]">
                <div className="flex top-0 mb-4 tracking-tight text-gray-200">
                    {!pageTitle ?
                    <h1 className="pl-1.5 text-4xl">{pageType}</h1>
                    : <h1 className="pl-1.5 text-4xl">{pageTitle}</h1>
                    }
                    {allowedPageTypes.includes(pageType)
                    ? <FilterDropdown className="px-5" />
                    : null
                    }
                </div>
                <div className="text-base font-normal text-gray-400">
                    <p>{pageDesc}</p>
                </div>
            </div>
            <div className="flex overflow-y-auto p-1.5 flex-wrap self-start w-full">
                {children}
            </div>
        </div>
    );
}

import React from 'react';
import cn from 'classnames';
import { Field } from 'formik';

export default function Checkbox(props) {
    const { name = '', disabled = false, className, ...restProps } = props;

    return (
        <Field
            {...restProps}
            name={name}
            type="checkbox"
            disabled={disabled}
            className={cn(`rounded`, className)}
        />
    );
}


import React from 'react';
import parseUrl from 'url-parse';
import { Link } from 'react-router-dom';
import { Icon } from './icons/icon.export';
import { toTitleCase } from '../utils/utils';
import { PlayerContext } from 'context/PlayerContext';
import { IMG_URI } from '../utils/constants';

type ThumbnailWatchedProps = {
    content_model: string;
    title: string;
    image: string;
    overlay_text: string;
    progress: number;
    onClick: (e: React.MouseEvent<HTMLElement>) => void;
    style: React.CSSProperties;
    className: string;
    _play: string;
    _detail: string;
};

export default function ThumbnailWatched({
    content_model: contentModel,
    title,
    image,
    overlay_text: overlayText,
    progress,
    onClick,
    style,
    className,
    _play,
    _detail,
    ...props
}:ThumbnailWatchedProps) {


    const { pathname } = parseUrl(_detail || '');
    const path = pathname.replace('/v2/content', '');

    const playerContext = React.useContext(PlayerContext);

    function handlePlayClick() {
        playerContext.setVideoSrc(_play);
        playerContext.setIsLive(false)
    }

    return (
        <div className="flex flex-col w-full ">
            <div
                className={`cursor-pointer group overflow-hidden relative rounded ${className}`}
            >
                {image ? (
                    <>
                        <div
                            tabIndex={0}
                            role="button"
                            onKeyDown={handlePlayClick}
                            onClick={handlePlayClick}
                            className="overflow-hidden relative after:absolute after:inset-0 after:hover:bg-gray-800/30 rounded-t after:transition after:duration-100 after:ease-in"
                        >
                            <img
                                src={image}
                                alt={title}
                                className="w-full h-full"
                                onError={(e) => (e.target as HTMLImageElement).src = IMG_URI}
                            />
                        </div>

                        <div className="flex overflow-hidden flex-col text-sm text-gray-300 bg-gray-800 rounded-b">
                            <div className="flex flex-col px-4 mt-2 md:flex-row md:justify-between md:items-center ">
                                <div
                                    className="group flex overflow-x-clip mr-2 space-x-2 "

                                >
                                    <p>{title}</p>
                                </div>
                                <div className="flex justify-between items-center space-x-2 text-gray-400 ">
                                    {overlayText ? (
                                        <div className="py-0.5 px-2 text-xs bg-gray-700 rounded-full">
                                            {overlayText.toUpperCase()}
                                        </div>
                                    ) : null}
                                    <div className="flex items-center space-x-2">
                                        <button
                                            type="button"
                                            tabIndex={0}
                                            onKeyDown={handlePlayClick}
                                            onClick={handlePlayClick}
                                            title={`Play ${contentModel}`}
                                            className="hover:text-gray-200 transition duration-100 ease-in"
                                        >
                                            <Icon name="playCircle" alt="play" size={20} weight="fill"/>
                                        </button>
                                        <Link
                                            to={path}
                                            title={`${toTitleCase(
                                                contentModel
                                            )} details`}
                                            className="hover:text-gray-200 transition duration-100 ease-in"
                                        >
                                            <Icon name="info" alt="info" size={20} weight="fill"/>
                                        </Link>
                                    </div>
                                </div>
                            </div>

                            {progress !== undefined ? (
                                <div className="mt-2 w-full  h-1 bg-indigo-700/10">
                                    <div
                                        className="h-full  bg-indigo-700 "
                                        style={{
                                            width: `calc(${progress}% * 100)`,
                                        }}
                                    />
                                </div>
                            ) : null}
                        </div>
                    </>
                ) : null}
            </div>
        </div>
    );
};
